import { useAddMemberToCampaignMutation } from '@app/services';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { UpdateFormData } from '@containers';
import { isAddress } from '@ethersproject/address';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { NotchedButton } from '../NotchedButton';

export interface AddReceiverFormData extends Partial<UpdateFormData> {
  walletAddress: string;
}

const schema = yup.object({
  fullName: yup.lazy(value =>
    typeof value === 'string' && value.length > 0
      ? yup.string().max(60, 'Tên tối đa 60 kí tự')
      : yup.string().nullable(),
  ),
  email: yup.lazy(value =>
    typeof value === 'string' && value.length > 0
      ? yup.string().email('Email không hợp lệ')
      : yup.string().nullable(),
  ),
  walletAddress: yup
    .string()
    .test(
      'Valid wallet address',
      'Địa chỉ ví không hợp lệ',
      addr => !!addr && isAddress(addr),
    ),
});

export const AddReceiverModal = (props: {
  campaignId: string;
  isAdmin?: boolean;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AddReceiverFormData>({
    resolver: yupResolver(schema),
  });

  const [addMemberToCampaign, { isLoading }] = useAddMemberToCampaignMutation();

  const toast = useToast({
    duration: 3000,
    position: 'bottom',
    isClosable: true,
  });

  const onSubmit = async (data: AddReceiverFormData) => {
    const member = Object.keys(data).reduce((prev, cur) => {
      if (data[cur]) {
        prev[cur] = data[cur];
      }
      return prev;
    }, {} as AddReceiverFormData);

    try {
      const result = await addMemberToCampaign({
        member,
        campaignId: props.campaignId,
      });

      if ('error' in result) {
        const { error } = result;
        console.log(error);
        return toast({
          title: error.name,
          description: error.message,
          status: 'error',
        });
      }

      toast({
        title: 'Thành công',
        description: 'Thêm người nhận thành công',
        status: 'success',
      });
      props.onClose();
    } catch (error) {
      console.log(error);
      toast({
        title: 'Oops',
        description: 'Có lỗi xảy ra khi thêm người nhận mới',
        status: 'error',
      });
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent padding="40px">
        <ModalHeader paddingX="0">Thêm vào danh sách người nhận</ModalHeader>
        <Text>
          Người này sẽ được đưa vào danh sách Duyệt và sẽ cần kết nối vào trang
          để nhận lì xì
        </Text>
        <FormControl
          id="walletAddress"
          isInvalid={!!errors.walletAddress}
          isRequired={true}
        >
          <FormLabel
            htmlFor="walletAddress"
            fontSize="20px"
            fontWeight="600"
            marginTop="32px"
          >
            Địa chỉ ví
          </FormLabel>
          <Input bg="white" color="darkBg" {...register('walletAddress')} />
          {!!errors.walletAddress && (
            <FormErrorMessage>{errors.walletAddress.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl id="fullName" isInvalid={!!errors.fullName}>
          <FormLabel
            htmlFor="fullName"
            fontSize="20px"
            fontWeight="600"
            marginTop="32px"
          >
            Tên người nhận
          </FormLabel>
          <Input bg="white" color="darkBg" {...register('fullName')} />
          {!!errors.fullName && (
            <FormErrorMessage>{errors.fullName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl id="email" isInvalid={!!errors.email}>
          <FormLabel
            htmlFor="email"
            fontSize="20px"
            fontWeight="600"
            marginTop="32px"
          >
            Email người nhận
          </FormLabel>
          <Input bg="white" color="darkBg" {...register('email')} />
          {!!errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
        <ModalFooter>
          <NotchedButton
            bgColor="primary"
            color="white"
            width="100%"
            marginTop="48px"
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Thêm người này
          </NotchedButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
