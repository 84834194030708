import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Campaign, campaignApi } from '@services';
import { RootState } from '@store';

const campaignsAdapter = createEntityAdapter<Campaign>({
  selectId: campaign => campaign._id,
});

const slice = createSlice({
  name: 'campaigns',
  initialState: campaignsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      campaignApi.endpoints.getCampaigns.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.getCampaigns) {
          return;
        }
        campaignsAdapter.setAll(state, payload.getCampaigns.campaigns);
      },
    );
    builder.addMatcher(
      campaignApi.endpoints.getCampaign.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.getCampaign) {
          return;
        }

        campaignsAdapter.setOne(state, payload.getCampaign);
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.requestJoinCampaign.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.joinCampaign) {
          return;
        }
        const campaign = state.entities[payload.joinCampaign.campaignId];
        if (campaign) {
          const members = campaign.members || [];
          members.push(payload.joinCampaign);

          campaignsAdapter.upsertOne(state, {
            ...campaign,
            members,
          });
        }
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.approveMember.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.approvedMember) {
          return;
        }
        const campaign = state.entities[payload.approvedMember.campaignId];
        if (campaign) {
          const members = campaign.members || [];
          const newMembers = members.filter(
            mb => mb.userId !== payload.approvedMember.userId,
          );
          newMembers.unshift(payload.approvedMember);
          campaignsAdapter.updateOne(state, {
            changes: { members: newMembers },
            id: payload.approvedMember.campaignId,
          });
        }
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.setApprovedMember.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.setApprovedMember) {
          return;
        }
        const campaign = state.entities[payload.setApprovedMember.campaignId];
        if (campaign) {
          const members = campaign.members || [];
          const newMembers = members.filter(
            mb => mb.userId !== payload.setApprovedMember.userId,
          );
          newMembers.unshift(payload.setApprovedMember);
          campaignsAdapter.updateOne(state, {
            changes: { members: newMembers },
            id: payload.setApprovedMember.campaignId,
          });
        }
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.createCampaign.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.createCampaign) {
          return;
        }

        campaignsAdapter.setOne(state, payload.createCampaign);
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.updateMemberClaimed.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.updateMemberClaimed) {
          return;
        }
        const campaign = state.entities[payload.updateMemberClaimed.campaignId];
        if (campaign) {
          const members = campaign.members || [];
          const newMembers = members.filter(
            mb => mb.userId !== payload.updateMemberClaimed.userId,
          );
          newMembers.push(payload.updateMemberClaimed);
          campaignsAdapter.updateOne(state, {
            changes: { members: newMembers },
            id: payload.updateMemberClaimed.campaignId,
          });
        }
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.updateShareLink.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.updateShareLink) {
          return;
        }
        campaignsAdapter.updateOne(state, {
          changes: {
            shareId: payload.updateShareLink.shareId,
            shareType: payload.updateShareLink.shareType,
          },
          id: payload.updateShareLink._id,
        });
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.updateRequireUserInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.updateRequireUserInfo) {
          return;
        }
        campaignsAdapter.updateOne(state, {
          changes: {
            isRequireUserInfo: payload.updateRequireUserInfo.isRequireUserInfo,
          },
          id: payload.updateRequireUserInfo._id,
        });
      },
    );

    builder.addMatcher(
      campaignApi.endpoints.addMemberToCampaign.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.addMemberToCampaign) {
          return state;
        }

        const { campaignId, addedMember } = payload.addMemberToCampaign;
        const campaign = campaignsAdapter
          .getSelectors()
          .selectById(state, campaignId);

        if (!campaign) {
          return state;
        }

        const members = campaign.members || [];
        const memIdx = members.findIndex(mb => mb._id === addedMember._id);
        if (memIdx !== -1) {
          return state;
        }

        campaignsAdapter.updateOne(state, {
          changes: {
            members: [addedMember, ...members],
          },
          id: campaignId,
        });
      },
    );
  },
});

export default slice.reducer;

const globalizedSelectors = campaignsAdapter.getSelectors<RootState>(
  state => state.campaigns,
);

// This selector already knows how to find the books entity state
export const getAllCampaigns = (state: RootState) =>
  globalizedSelectors.selectAll(state);

export const getCampaignById = (
  state: RootState,
  campaignId: string | undefined,
): Campaign | undefined => {
  if (!campaignId) {
    return;
  }
  return globalizedSelectors.selectById(state, campaignId);
};

export const getCampaignEntities = (state: RootState) =>
  globalizedSelectors.selectEntities(state);

export const getCampaignIds = (state: RootState) =>
  globalizedSelectors.selectIds(state);
