import { selectCurrentUser } from '@features/auth/authSlice';
import {
  Blog,
  CreateEnvelope,
  DetailCampaign,
  EmailVerify,
  Home,
  NotFound,
  Profile as ProfilePage,
  ShareCampaign,
} from '@pages';
import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes: React.FC<Record<string, never>> = () => {
  const user = useAppSelector(selectCurrentUser);

  const commonRoutes: RouteObject[] = [
    { index: true, element: <Home /> },
    { path: '/bao/:campaignId', element: <DetailCampaign /> },
    { path: '/share/:shareId', element: <ShareCampaign /> },
    { path: '/profile', element: <ProfilePage /> },
    { path: '/tao-lixi', element: <CreateEnvelope /> },
    { path: '/nft-la-gi', element: <Blog /> },
    { path: '/verify-email', element: <EmailVerify /> },
  ];

  const routes = user ? protectedRoutes : publicRoutes;

  const element = useRoutes([
    ...commonRoutes,
    ...routes,
    { path: '*', element: <NotFound /> },
  ]);

  return <>{element}</>;
};

export default AppRoutes;
