import starLeft from '@assets/starLeft.png';
import startRight from '@assets/starRight.png';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { NotchedButton } from '@components';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Countdown } from './Countdown';
import { TicketsCard } from './TicketsCard';

export const Jackpot = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        pr={{ base: '0.5rem', sm: '1rem' }}
        pl={{ base: '0.5rem', sm: '1rem' }}
      >
        <Heading
          marginTop="120px"
          color="white"
          fontSize="64px"
          fontFamily="headingFont"
        >
          Xổ số Jackpot
        </Heading>
        <Text
          marginTop="8px"
          marginBottom="40px"
          fontSize="16px"
          color="white"
          opacity="0.6"
          maxWidth="30rem"
        >
          LixiNFT dùng để tham gia xổ số Jackpot. Sở hữu càng nhiều LixiNFT xác
          suất chiến thắng càng cao.
        </Text>
        <TicketsCard />
        <Box
          w="100%"
          maxW="768px"
          borderRadius="4px"
          bg="primary"
          mx="auto"
          marginBottom="134px"
          position="relative"
          pr={{ base: '0.5rem', sm: '1rem' }}
          pl={{ base: '0.5rem', sm: '1rem' }}
        >
          <Image
            display={{ base: 'none', lg: 'block' }}
            position="absolute"
            left="0"
            top="250px"
            src={starLeft}
          />
          <Image
            display={{ base: 'none', lg: 'block' }}
            position="absolute"
            right="0"
            top="250px"
            src={startRight}
          />
          <Text
            color="yellow"
            fontWeight="500"
            fontSize="14px"
            marginBottom="10px"
            marginTop="40px"
          >
            Tổng giá trị giải thưởng
          </Text>
          <Flex flexDirection="column" justifyContent="center">
            <Flex
              justifyContent="center"
              alignItems="center"
              width={{ base: '80%', lg: '440px' }}
              marginX="auto"
              paddingBottom="32px"
              marginBottom="32px"
              borderBottomWidth={{ base: 0, lg: '1px' }}
              borderBottomColor="rgba(255,255,255,0.2)"
            >
              <Text
                fontFamily="headingFont"
                fontSize={{ base: '50px', sm: '72px' }}
                color="white"
                marginRight="5px"
              >
                3.72 BNB
              </Text>
              <Image
                w={10}
                h={10}
                src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
              />
            </Flex>
          </Flex>
          <Text
            color="yellow"
            fontWeight="500"
            fontSize="14px"
            marginBottom="10px"
          >
            Thời Gian Quay Jackpot
          </Text>
          <Countdown />
          <Flex justifyContent="center" marginTop="28px">
            <NotchedButton
              bg="#132838"
              color="white"
              width="178px"
              marginBottom="40px"
              onClick={() => {
                navigate('/the-le');
              }}
            >
              <Link to="/the-le">Thể lệ</Link>
            </NotchedButton>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
