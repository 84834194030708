/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSetApprovedMemberMutation } from '@app/services';
import { useToast } from '@chakra-ui/react';
import { LIXI_BAG } from '@constants';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import {
  Event,
  getCampaignEntities,
  selectEvent,
  setEvent,
  setIdle,
} from '@features';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect } from 'react';
import lixiBagAbi from '../../abi/LixiBag.json';

export function useSetWhitelisterListener() {
  const { library } = useWeb3React<Web3Provider>();
  const [setApprovedMember] = useSetApprovedMemberMutation();

  const dispatch = useAppDispatch();
  const event = useAppSelector(selectEvent);
  const campaignEntities = useAppSelector(state => getCampaignEntities(state));

  const toast = useToast({
    duration: 3000,
    position: 'bottom',
    isClosable: true,
  });

  const handleSetWhiteLister = useCallback(
    async (groupId: BigNumber, user: string, isWhitelisted: boolean) => {
      console.log("Event 'SetWhitelister' with payload");
      console.log({ groupId, user, isWhitelisted });

      const { eventName, payload } = event;

      if (!payload) {
        return;
      }

      if (eventName !== Event.SETWHITELISTER_WAITING) {
        return;
      }

      const { campaignId, userId } = payload;

      const campaign = campaignEntities[campaignId];
      if (!campaign || !campaign.members) {
        return;
      }

      const member = campaign.members.find(mb => mb.userId === userId);
      if (!member) {
        return;
      }

      dispatch(
        setEvent({ eventName: Event.SETWHITELISTER_FIRED, payload: null }),
      );

      try {
        const result = await setApprovedMember({
          groupId: groupId.toNumber(),
          userAddress: user,
          isWhitelisted,
        });

        console.log(result);

        if ((result as any).error) {
          return toast({
            title: 'Thất bại',
            description: (result as any).error.message,
            status: 'error',
          });
        }

        toast({
          title: 'Duyệt thành công',
          description: 'Bạn đã duyệt thành công',
          status: 'success',
        });
      } catch (error) {
        console.log(error);
        toast({
          title: 'Thất bại',
          description: 'Có lỗi xảy ra khi duyệt',
          status: 'error',
        });
      } finally {
        dispatch(setIdle());
      }
    },
    [library, event, campaignEntities],
  );

  useEffect(() => {
    let contract: Contract | null;

    if (library) {
      contract = new Contract(LIXI_BAG, lixiBagAbi, library.getSigner(0));

      contract.on('SetWhitelister', handleSetWhiteLister);
      contract.on('SetWhitelisterByAdmin', handleSetWhiteLister);
    }

    return () => {
      if (!contract) return;

      contract.removeAllListeners('SetWhitelister');
      contract.removeAllListeners('SetWhitelisterByAdmin');
    };
  }, [library, event, campaignEntities]);
}
