/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  AvatarBadge,
  FormControl,
  FormLabel,
  IconButton,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { CropperModal } from '@components';
import { blobToBase64, resizeImage } from '@utils';
import 'cropperjs/dist/cropper.css';
import React, { useRef, useState } from 'react';
import { BiUpload } from 'react-icons/bi';

export interface ProfileAvatarFieldProps {
  avatarUrl?: string;
  setAvatarUrl: (url: string) => void;
  setFile: (blob: File | null) => void;
}

export const ProfileAvatarField: React.FC<ProfileAvatarFieldProps> = ({
  avatarUrl,
  setAvatarUrl,
  setFile,
}) => {
  const avatarSize = useBreakpointValue({ base: 'lg', lg: 'xl' });
  const { isOpen, onClose, onOpen } = useDisclosure();

  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [internalFile, setInternalFile] = useState<File | null>(null);

  // useEffect(() => {
  //   if (image.length) onOpen();
  // }, [image]);

  // useEffect(() => {
  //   (async () => {
  //     if (!internalFile) return;

  //     setFile(internalFile);
  //     setAvatarUrl((await blobToBase64(internalFile)) as string);
  //   })();
  // }, [internalFile]);

  const handleUploadClick = () => {
    if (!uploadInputRef || !uploadInputRef['current']) return;

    uploadInputRef['current'].click();
  };

  const onChange = async (ev?: any) => {
    if (!ev) return;

    let files: FileList | null = null;
    if (ev['dataTransfer']) {
      files = ev['dataTransfer'].files;
    } else if (ev.target) {
      files = (ev as any).target.files;
    }

    if (!files || !files.length) return;

    const uploadedFile = new File([files[0]], files[0].name, {
      type: files[0].type,
    });

    ev['target'].value = '';
    setInternalFile(uploadedFile);
    onOpen();
  };

  const handleSetCroppedFile = async (file: File | null) => {
    onClose();

    if (!file) {
      return setInternalFile(file);
    }

    const resizedLogo = await resizeImage(file, 600);
    setFile(resizedLogo);
    const uri = await blobToBase64(resizedLogo as Blob);
    setAvatarUrl(uri as string);
  };

  return (
    <>
      <FormControl id="avatar">
        <FormLabel>Ảnh đại diện</FormLabel>
        <Avatar size={avatarSize} src={avatarUrl}>
          <Tooltip
            label="Thay đổi ảnh đại diện"
            aria-label="upload image"
            placement="right"
            hasArrow
          >
            <AvatarBadge
              onClick={handleUploadClick}
              as={IconButton}
              size="xs"
              aria-label="upload image"
              icon={<BiUpload />}
            />
          </Tooltip>
        </Avatar>
      </FormControl>
      <input
        type="file"
        ref={uploadInputRef}
        multiple
        onChange={onChange}
        style={{ display: 'none' }}
      />

      {/* Cropper Modal */}
      <CropperModal
        header="Cắt ảnh đại diện"
        file={internalFile}
        setFile={handleSetCroppedFile}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
    </>
  );
};

export default ProfileAvatarField;
