import {
  authReducer,
  campaignMemberReducer,
  campaignReducer,
  creatingStageReducer,
  envelopeFormReducer,
  eventReducer,
  joinedCampaignReducer,
  ticketReducer,
  walletReducer,
  globalCampaignReducer,
} from '@features';
import { configureStore } from '@reduxjs/toolkit';
import { authApi, campaignApi, uploadApi } from './services';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    auth: authReducer,
    campaignMembers: campaignMemberReducer,
    campaigns: campaignReducer,
    creatingStage: creatingStageReducer,
    envelopeForm: envelopeFormReducer,
    event: eventReducer,
    joinedCampaigns: joinedCampaignReducer,
    ticket: ticketReducer,
    wallet: walletReducer,
    globalCampaigns: globalCampaignReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['envelopeForm/setFile'],
      },
    })
      .concat(authApi.middleware)
      .concat(campaignApi.middleware)
      .concat(uploadApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
