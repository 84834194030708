import { IS_STAGING, LIXI_BAG } from '@constants';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcSigner } from '@ethersproject/providers';
import LixiBagAbi from '../../abi/LixiBag.json';
import { BscscanProvider } from '@ethers-ancillary/bsc';

export function getLixiBagContract(library?: JsonRpcSigner, isWrite = false) {
  if (isWrite && !library) {
    throw new Error('Write contract must provide signer');
  }

  if (isWrite) {
    return new Contract(LIXI_BAG, LixiBagAbi, library);
  }

  const bscProvider = new BscscanProvider(IS_STAGING === 'true' ? 97 : 56);
  return new Contract(LIXI_BAG, LixiBagAbi, bscProvider);
}
