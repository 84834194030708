import { BigNumber } from '@ethersproject/bignumber';
import { LIXI_NFT } from '@constants';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import LixiNFTAbi from '../../abi/LixiNFT.json';

export const tokenOfOwnerByIndex = async (
  owner: string,
  index: number,
  library: Web3Provider,
): Promise<number> => {
  const contract = new Contract(LIXI_NFT, LixiNFTAbi, library);

  const tokenId = (await contract.tokenOfOwnerByIndex(
    owner,
    index,
  )) as BigNumber;

  return tokenId.toNumber();
};
