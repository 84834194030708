import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment-timezone';
import React from 'react';

function calculateTimeLeft() {
  const tZone = 'Asia/Ho_Chi_Minh';
  const timeTillDate = '2022-02-22T13:00:00Z';
  const then = moment.tz(timeTillDate, tZone);
  const now = moment().tz(tZone);

  const duration = moment.duration(then.diff(now));

  const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export const Countdown = () => {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const id = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  const timerComponents = Object.keys(timeLeft).map(interval => {
    return (
      <Box key={interval} p={{ base: '0.5rem', sm: '1rem' }}>
        <Box
          fontFamily="headingFont"
          fontSize={{ base: '30px', sm: '40px', md: '80px' }}
          fontWeight="700"
          width={{ base: 'auto', md: '100px' }}
          height={{ base: 'auto', md: '100px' }}
          textAlign="center"
        >
          {timeLeft[interval] >= 0 ? timeLeft[interval] : 0}
        </Box>
        <Box
          fontFamily="rootFont"
          fontWeight="700"
          fontSize={{ base: '12px', sm: '16px' }}
        >
          {interval.toLocaleUpperCase()}
        </Box>
      </Box>
    );
  });

  return (
    <Flex
      color="white"
      justifyContent={{ base: 'space-around', md: 'center' }}
      alignItems="center"
      height={{ base: 'auto', sm: '169px' }}
      flexDirection={'row'}
      wrap={{ base: 'wrap', md: 'nowrap' }}
      p={{ base: '-0.5rem', sm: '-1rem' }}
    >
      {timerComponents}
    </Flex>
  );
};
