/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Footer, Header, Main } from '@components';
import { IS_STAGING } from '@constants';
import { Web3Provider } from '@ethersproject/providers';
import { selectCurrentUser } from '@features/auth/authSlice';
import { useAppDispatch, useAppSelector, useAuth } from '@hooks';
import { authApi } from '@services';
import {
  botSendEvent,
  botSendEventWithChannel,
  BotSupportType,
  gtagSendEvent,
  GtagSupportAction,
  GtagSupportCategory,
} from '@utils';
import { useWeb3React } from '@web3-react/core';
import {
  changeNetwork,
  useEagerConnect,
  useInactiveListener,
} from '@web3react';
import React, { useEffect, useState } from 'react';
import { FaDiscord, FaFacebook, FaTelegram, FaTwitter } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { HeaderContainer } from '../HeaderContainer';

export const MainLayoutContainer: React.FC = ({ children }) => {
  const { active, setError, account } = useWeb3React<Web3Provider>();
  useEagerConnect();
  useInactiveListener(!active);

  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<any>();

  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });

  const handleChangeNetwork = async (chainId: number) => {
    try {
      await changeNetwork({ chainId, setError }, account);
      onClose();
      toast({
        title: 'Success',
        description: 'Bạn đã chuyển mạng thành công',
        status: 'success',
      });
    } catch {
      toast({
        title: 'Error',
        description: 'Oops, có gì đó hoạt động chưa đúng rồi',
        status: 'error',
      });
    }
  };

  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const { signIn } = useAuth();
  const updateUser = authApi.endpoints.updateUser.initiate;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [unsubscribe, setUnsubscribe] = useState<() => void>(() => {});
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as { from: string };
  const [updatedFields, setUpdatedFields] = useState<{
    fullName?: string;
    email?: string;
  }>();
  const [updatedToBot, setUpdatedToBot] = useState(false);
  useEffect(() => {
    // MARK:- logic when param user changed
    if (updatedFields && !updatedToBot) {
      if (updatedFields.fullName && updatedFields.email) {
        // MARK:- update wallet address to Bot:
        botSendEventWithChannel(BotSupportType.UPDATE_USER_WALLET, {
          walletAddress: user?.walletAddress,
        });
        // MARK:- update user info into Database
        const result = dispatch(updateUser({ ...updatedFields }));
        setUnsubscribe(result.unsubscribe);
        setUpdatedToBot(true);
      }
    }
    if (!updatedFields && !updatedToBot && user) {
      botSendEvent(BotSupportType.UPDATE_USER_INFO, {
        fullName: user.fullName,
        email: user.email,
        walletAddress: user.walletAddress,
      });
    }
    return unsubscribe;
  }, [user]);

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data.type === 'data') {
        // MARK:- update user info sent from Bot
        if (event.data.data.action === 'user_info') {
          setUpdatedFields({
            fullName: event.data.data.user.fullName,
            email: event.data.data.user.email,
          });
        }
        // MARK:- Handle Bot send event open metamask
        if (event.data.data === 'open_metamask') {
          signIn();
        }
      }
      if (event.data.name === 'webchatOpened') {
        // MARK:- Send Event to GA: user open Chatbox
        gtagSendEvent({
          category: GtagSupportCategory.ANA,
          action: GtagSupportAction.ANA_BTN_CLICK,
        });
      }
      if (event.data.authorId && event.data.payload.type === 'text') {
        // MARK:- Send Event to GA: user chat with Ana
        gtagSendEvent({
          category: GtagSupportCategory.ANA,
          action: GtagSupportAction.ANA_CHAT,
        });
      }
    });
  }, []);

  return (
    <Box maxW="100vw" pos="relative" minH="100vh">
      <Header bgColor="darkBg">
        <HeaderContainer handleWarningChain={() => setIsOpen(true)} />
      </Header>
      <Main w="100vw">{children}</Main>
      <Footer>
        <Flex
          alignItems="center"
          justifyContent="center"
          marginBottom="20px"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Link href="https://www.facebook.com/Lixi-NFT-101593871819990">
            <Button
              width="120px"
              colorScheme="facebook"
              leftIcon={<FaFacebook />}
            >
              Facebook
            </Button>
          </Link>
          <Link
            href="https://twitter.com/lixinft"
            marginX={{ base: '0', md: '10px' }}
            marginY={{ base: '10px', md: '0' }}
          >
            <Button
              width="120px"
              colorScheme="twitter"
              leftIcon={<FaTwitter />}
            >
              Twitter
            </Button>
          </Link>
          <Link
            href="https://t.me/lixinft"
            marginX={{ base: '0', md: '10px' }}
            marginY={{ base: '10px', md: '0' }}
          >
            <Button
              width="120px"
              colorScheme="telegram"
              leftIcon={<FaTelegram />}
            >
              Telegram
            </Button>
          </Link>
          <Link href="https://discord.gg/88QVF3p23W">
            <Button width="120px" leftIcon={<FaDiscord />}>
              Discord
            </Button>
          </Link>
        </Flex>
        <Text align="center" fontSize="sm">
          © 2022 lixinft.me . All rights reserved.
        </Text>
      </Footer>

      {/* Warning for change network */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Kết nối mạng không hỗ trợ
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {
                'Bạn đang kết nối vào mạng không được hỗ trợ, vui lòng chọn mạng được hỗ trợ bên dưới'
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme={IS_STAGING === 'true' ? 'orange' : 'red'}
                onClick={() =>
                  handleChangeNetwork(IS_STAGING === 'true' ? 97 : 56)
                }
              >
                Binance {IS_STAGING === 'true' ? 'Testnet' : 'Mainnet'}
              </Button>
              <Button ml={3} onClick={() => setIsOpen(false)}>
                Đóng
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default MainLayoutContainer;
