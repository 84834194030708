import { getLixiBagContract } from '@web3react';
import { JsonRpcSigner } from '@ethersproject/providers';

export async function claimLocalJackpot(
  ticketId: number,
  library: JsonRpcSigner,
) {
  const contract = getLixiBagContract(library, true);
  const tx = await contract.claimLocalJackpot(ticketId);
  return tx;
}
