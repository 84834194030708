/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGetGlobalCampaignQuery } from '@app/services';
import {
  Center,
  Circle,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Lottery, NotchedButton, PrizeCard, Wrapper } from '@components';
import { BSC_SCAN, GLOBAL_JACKPOT_CAMPAIGN_ID } from '@constants';
import { Web3Provider } from '@ethersproject/providers';
import { getGlobalCampaignById } from '@features';
import { useAppSelector, useAuth } from '@hooks';
import {
  botSendEventWithChannel,
  botShow,
  BotSupportType,
  claimGlobalJackpot,
} from '@utils';
import { useWeb3React } from '@web3-react/core';
import { formatAddress } from '@web3react';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';
import barLine from '../assets/barLine.png';
import coinBottomLeft from '../assets/coin_bl.png';
import coinBottomRight from '../assets/coin_br.png';
import coinTopLeft from '../assets/coin_tl.png';
import coinTopRight from '../assets/coin_tr.png';
import lixiBack from '../assets/lixi-back.png';
import lixiFront from '../assets/lixi-front.png';
import successIcon from '../assets/successIcon.svg';
import { FAQ } from '../components/LandingPage/FAQ';
import { Jackpot } from '../components/LandingPage/Jackpot';
import { JackpotExplain } from '../components/LandingPage/JackpotExplain';
import { LivestreamSection } from '../components/LandingPage/LivestreamSection';
import { Steps } from '../components/LandingPage/Steps';

export const HeroSection: React.FC = () => {
  const navigate = useNavigate();
  const { hasLogin, signIn } = useAuth();

  useEffect(() => {
    // MARK:- show Bot when Screen is on
    botShow();
    window.addEventListener('message', event => {
      if (event.data.name === 'webchatReady') {
        botSendEventWithChannel(BotSupportType.PROACTIVE_TRIGGER, {
          text: '/beginner',
        });
      }
    });
  }, []);

  const handleCreateEvelopeClick = () => {
    if (hasLogin) {
      navigate('/tao-lixi');
    } else {
      signIn();
    }
  };

  return (
    <Wrapper height="100%" padding={{ base: 0, md: '0 5rem' }}>
      <Flex
        align="center"
        justify={{ base: 'center', sm: 'space-around', xl: 'space-between' }}
        direction={{ base: 'column-reverse', sm: 'row' }}
        wrap="nowrap"
        flexDirection={{
          base: 'column',
          sm: 'row',
        }}
        py="20"
        px="0"
        ml="auto"
        mr="auto"
        width="100%"
        maxWidth="128rem"
      >
        <VStack
          align={{ base: 'center', sm: 'flex-start' }}
          width={{ base: '100%', sm: '50%' }}
          p={5}
          spacing="5"
          order={{ base: '2', sm: '1' }}
          textAlign={{ base: 'center', sm: 'left' }}
        >
          <Text
            fontSize={{ base: 60, md: 80, lg: 120 }}
            fontFamily="headingFont"
            fontWeight="bold"
            color="white"
            lineHeight="1"
          >
            Lì Xì bằng NFT
          </Text>
          <Image src={barLine} />
          <Text
            color="secondary"
            fontSize={{ base: 18, lg: 25 }}
            fontWeight={600}
            maxW={{ base: 'none', sm: '30rem', lg: '48rem' }}
          >
            Lì xì cho bạn bè, người thân, đồng nghiệp của bạn bằng NFT và tiền
            số cryptocurrency thay vì lì xì truyền thống? Tạo lì xì ngay!
          </Text>
          <Flex
            py={{ base: '2rem', lg: '0.75rem' }}
            flexDirection={{ base: 'column', lg: 'row' }}
          >
            <NotchedButton
              onClick={handleCreateEvelopeClick}
              color="white"
              bgColor="primary"
              mb={{ base: '20px', md: '0' }}
            >
              Tạo lì xì
            </NotchedButton>
            <NotchedButton
              color="primary"
              bgColor="transparent"
              ml={5}
              borderColor="primary"
              onClick={() => {
                navigate('/the-le');
              }}
            >
              Tìm hiểu thêm
            </NotchedButton>
          </Flex>
        </VStack>
        <Flex
          justify={'center'}
          px={5}
          width={{ base: '100%', sm: '50%' }}
          height="0"
          order={{ base: '-1', sm: '2' }}
          pb={{ base: '100%', sm: '50%' }}
          position="relative"
        >
          <Flex
            position="absolute"
            width="100%"
            heigt="100%"
            justifyContent="center"
            alignItems="center"
            left="0"
            right="0"
            top="0"
            bottom="0"
          >
            <Image
              src={lixiFront}
              position="absolute"
              width="50%"
              zIndex="3"
              left="2rem"
            />
            <Image
              src={lixiBack}
              position="absolute"
              width="40%"
              zIndex="2"
              right="20%"
              top="30%"
            />
            <Image
              src={coinBottomLeft}
              width="20%"
              display={{ base: 'none', md: 'block' }}
              position="absolute"
              zIndex="5"
              bottom="10px"
              left="10px"
            />
            <Image
              src={coinBottomRight}
              width="10%"
              display={{ base: 'none', md: 'block' }}
              position="absolute"
              zIndex="5"
              bottom="15%"
              right="25%"
            />
            <Image
              src={coinTopLeft}
              width="10%"
              display={{ base: 'none', md: 'block' }}
              position="absolute"
              zIndex="5"
              top="15%"
              left="6%"
            />
            <Image
              src={coinTopRight}
              width="10%"
              display={{ base: 'none', md: 'block' }}
              position="absolute"
              zIndex="5"
              top="25%"
              right="25%"
            />
            <Circle
              width={{
                base: '10rem',
                sm: '12.5rem',
                md: '16em',
                lg: '20rem',
                xl: '32rem',
              }}
              height={{
                base: '10rem',
                sm: '12.5rem',
                md: '16rem',
                lg: '20rem',
                xl: '32rem',
              }}
              background="linear-gradient(180deg, #FFF8C5 0%, #FFE840 100%)"
              position="absolute"
              top="10%"
              zIndex="1"
            />
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export const Home: React.FC = () => {
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'bottom',
  });
  const globalCampaignId = GLOBAL_JACKPOT_CAMPAIGN_ID;
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [isWheelSuccess, setIsWheelSuccess] = useState(false);
  const [wheelWinner, setWheelWinner] = useState('');

  const { library } = useWeb3React<Web3Provider>();

  const campaign = useAppSelector(state =>
    getGlobalCampaignById(state, globalCampaignId),
  );
  const { refetch } = useGetGlobalCampaignQuery(globalCampaignId);

  const winnerAddress =
    campaign && campaign.winnerAddress ? campaign.winnerAddress : '';

  const congratulationsWheel = () => {
    return (
      <Modal
        isOpen={isWheelSuccess}
        onClose={() => {
          setIsWheelSuccess(false);
        }}
      >
        <ModalOverlay />
        <ModalContent py={10}>
          <ModalBody>
            <Confetti height={windowHeight} width={windowWidth} />
            <Flex flexDirection="column" gap={10}>
              <Center>
                <Image src={successIcon} w={20} h={20} />
              </Center>
              <Text>
                Xin chúc mừng địa chỉ ví {formatAddress(winnerAddress)} đã chiến
                thắng
              </Text>
              <NotchedButton
                bgColor="primary"
                color="white"
                onClick={() => {
                  setIsWheelSuccess(false);
                }}
              >
                Đóng
              </NotchedButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const handleClaimGlobalJackpot = async () => {
    try {
      const txHash = await claimGlobalJackpot(library?.getSigner(0));
      toast({
        title: 'Thông báo',
        description: (
          <Flex flexDirection="column" alignItems="center">
            <Text>Bạn đã nhận thành công</Text>

            <Link href={`${BSC_SCAN}/tx/${txHash.hash}`} isExternal>
              <Text color="primary">Xem chi tiết</Text>
            </Link>
          </Flex>
        ),
        status: 'success',
      });
    } catch (err) {
      console.log(err);
      toast({
        title: 'Thông báo lỗi',
        description:
          'Bạn không phải là người thắng cuộc hoặc bạn chưa kết nối ví',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (globalCampaignId) {
      refetch();
    }
  }, [globalCampaignId]);

  useEffect(() => {
    if (wheelWinner) {
      setIsWheelSuccess(true);
    }
  }, [wheelWinner]);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      margin="auto"
      paddingTop={{ base: '4.5rem', md: '8rem' }}
    >
      {isWheelSuccess ? congratulationsWheel() : null}
      <HeroSection />
      <PrizeCard />
      <Steps />
      <JackpotExplain />
      <Jackpot />
      <LivestreamSection />
      <Lottery
        campaign={campaign}
        isGlobalCampaign={true}
        setWheelWinner={setWheelWinner}
        claimJackpot={handleClaimGlobalJackpot}
      />
      <FAQ />
    </Flex>
  );
};

export default Home;
