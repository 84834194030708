import logo from '@assets/logo.svg';
import { HStack, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const LeftHeader = () => {
  return (
    <Link to="/">
      <HStack>
        <Image src={logo} width="30px" marginX={{ base: 'auto', md: '0' }} />
        <Text
          display={{ base: 'none', md: 'block' }}
          color="white"
          fontSize={20}
          fontWeight={600}
        >
          LixiNFT
        </Text>
      </HStack>
    </Link>
  );
};

export default LeftHeader;
