import { User } from '@app/services/auth';
import { Box, HStack, Flex } from '@chakra-ui/react';
import { AccountInfo, WalletInfo } from '@components';
import { WalletState } from '@features/wallet/walletSlice';
import React from 'react';

export interface RightHeaderProps {
  user: User | null;
  wallet: WalletState | null;
}

export const RightHeader: React.FC<RightHeaderProps> = ({ user, wallet }) => {
  return (
    <HStack spacing="3" alignItems="center" justifyContent={{ base: 'end' }}>
      <Flex
        marginY={{ base: '10px', md: '0' }}
        display={{ base: 'none', md: 'flex' }}
      >
        {user && <AccountInfo user={user} />}
      </Flex>
      <Box>{wallet && <WalletInfo wallet={wallet} />}</Box>
    </HStack>
  );
};

export default RightHeader;
