import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

export const Step = (props: { item: string }) => {
  return (
    <Text
      float="left"
      fontFamily="rootFont"
      fontSize="16px"
      fontWeight="700"
      color="darkBg"
    >
      Bước {props.item}:&nbsp;
    </Text>
  );
};

export const Title = (props: { title: string }) => {
  return (
    <Heading
      fontFamily="rootFont"
      fontSize="24px"
      fontWeight="700"
      color="darkBg"
      textAlign="center"
    >
      {props.title}
    </Heading>
  );
};

export const SecondTitle = (props: { content: string }) => {
  return (
    <Text fontFamily="rootFont" fontSize="16px" fontWeight="700" color="darkBg">
      {props.content}
    </Text>
  );
};
