/* eslint-disable @typescript-eslint/no-explicit-any */
import { IS_STAGING } from '@constants';

export enum BotSupportType {
  MESSAGE = 'message',
  UPDATE_USER_INFO = 'update_user_info',
  UPDATE_USER_WALLET = 'update_user_wallet',
  UPDATE_USER_STATUS = 'update_user_status',
  UPDATE_CONTEXT = 'current_context',
  WEB_ERROR_DETECTION = 'web_error',
  PROACTIVE_TRIGGER = 'proactive-trigger',
}

export function botShow() {
  if (IS_STAGING === 'false') {
    // MARK:- do not pop up Bot window when in development mode
    try {
      (window as any).botpressWebChat.sendEvent({ type: 'show' });
    } catch (err) {
      console.log(err);
    }
  }
}

export function botSendMessage(message: string) {
  try {
    (window as any).botpressWebChat.sendEvent({
      type: BotSupportType.MESSAGE,
      channel: 'web',
      text: message,
    });
  } catch (err) {
    console.log(err);
  }
}

export function botSendEvent(type: string, payload: any) {
  try {
    (window as any).botpressWebChat.sendEvent({
      type: type,
      payload: payload,
    });
  } catch (err) {
    console.log(err);
  }
}

export function botSendEventWithChannel(type: string, payload: any) {
  try {
    (window as any).botpressWebChat.sendEvent({
      type: type,
      channel: 'web',
      payload: payload,
    });
  } catch (err) {
    console.log(err);
  }
}
