import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const Wrapper: React.FunctionComponent<BoxProps> = props => {
  return (
    <Box use="div" display="flex" {...props}>
      {props.children}
    </Box>
  );
};

export default Wrapper;
