import { CheckCircleIcon, CopyIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputProps,
  Tooltip,
  useClipboard,
  useTheme,
} from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
import React from 'react';

export interface WalletAddressFieldProps {
  value: string;
  inputForwardProps: InputProps;
}

export const WalletAddressField: React.FC<WalletAddressFieldProps> = ({
  value,
  inputForwardProps = {},
}) => {
  const theme = useTheme();
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <FormControl id="walletAddress">
      <FormLabel>Địa chỉ ví</FormLabel>
      <HStack spacing="3">
        <Input
          value={value}
          readOnly
          cursor="no-drop"
          {...inputForwardProps}
          opacity="0.7"
        />
        <Tooltip
          aria-label="Copy icon button"
          hasArrow
          label={hasCopied ? 'Copied' : 'Copy'}
          placement="top"
        >
          <IconButton
            bg="primary"
            aria-label="Copy address"
            fontSize="20px"
            onClick={onCopy}
            icon={hasCopied ? <CheckCircleIcon /> : <CopyIcon />}
            disabled={hasCopied}
            pointerEvents={hasCopied ? 'none' : 'auto'}
            cursor={hasCopied ? 'auto' : 'copy'}
            _hover={{ bg: darken(theme.colors['primary'], 15) }}
            _active={{ bg: darken(theme.colors['primary'], 20) }}
          />
        </Tooltip>
      </HStack>
    </FormControl>
  );
};

export default WalletAddressField;
