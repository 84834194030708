import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { CardRule } from './CardRule';

const rulesList = [
  {
    index: 1,
    title: 'Về LixiNFT.ME',
    description:
      'LixiNFT.me là một nền tảng giúp mã hóa NFT cho những bao lì xì của bạn. Ở trên LixiNFT.me, bạn có thể dành tặng cho người thân, bạn bè của mình những “bao lì xì số” vô cùng ý nghĩa thay vì những bao lì xì truyền thống thông thường. Hãy biến dịp Tết của bạn trở nên đặc biệt hơn với những bao lì xì số khi mà Blockchain, NFT hiện tại đang là một xu hướng vô cùng thịnh hành! Tại sao không?!',
  },
  {
    index: 2,
    title: 'Ai có thể tham gia LixiNFT.me?',
    description:
      'Đối tượng của LixiNFT.me có thể là bất kì ai, không phân biệt độ tuổi, giới tính, nghề nghiệp,... Chỉ cần bạn thích là có thể tham gia một cách đơn giản!',
  },
  {
    index: 3,
    title: 'Tại sao bạn nên tham gia LixiNFT.me?',
    description:
      'Tết nguyên đán được coi là một trong những dịp lễ lớn nhất trong năm đối với người Việt Nam. Tuy nhiên, Tết cũng tiềm ẩn nguy cơ lây lan dịch rất lớn do bản chất những thói quen, tập tục của người Việt như đi chúc Tết, lì xì đầu năm,... LixiNFT được thành lập ra để lan tỏa một thông điệp tốt trong dịp Tết đó là lì xì “không tiếp xúc” bằng NFT. Vừa đóng góp vào trong công cuộc phòng chống dịch bệnh, mà lại vẫn lan tỏa được những giá trị truyền thống của Tết. Bên cạnh đó, lì xì bằng NFT sẽ biến dịp Tết của mỗi người trở nên thú vị hơn rất nhiều khi những yếu tố như công nghệ, blockchain, tiền ảo hiện tại cũng đang là những cơn sốt mạnh mẽ trên thị trường. Vậy bạn đã sẵn sàng để LixiNFT hay chưa?',
  },
];

export const Rules = () => {
  return (
    <Box>
      <Heading
        color="white"
        textAlign="center"
        marginBottom={{ base: '24px', md: '42px' }}
        fontFamily="headingFont"
        fontSize={{ base: '48px', md: '84px' }}
        fontWeight="700"
      >
        Thể Lệ
      </Heading>
      <Box
        background="white"
        width={{ base: '90%', sm: '80%', md: '70%' }}
        marginX="auto"
        borderRadius="12px"
      >
        {rulesList.map(item => (
          <CardRule
            key={item.index}
            index={item.index}
            title={item.title}
            description={item.description}
          />
        ))}
      </Box>
    </Box>
  );
};
