import { useRef, useState } from 'react';
import copyToClipboard from 'copy-to-clipboard';

export const useCopyableRef = <T extends HTMLElement = HTMLElement>(
  deplay = 4000,
) => {
  const ref = useRef<T>(null);

  const [isCopied, setIsCopied] = useState(false);
  const copy = () => {
    if (isCopied) {
      return;
    }

    if (!ref.current) {
      throw new Error('Ref is null');
    }
    copyToClipboard(ref.current.textContent || '');

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), deplay);
  };

  return { ref, isCopied, copy };
};
