import { Center, Heading, VStack, useTheme } from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
import { NotchedButton } from '@components';
import { Link } from 'react-router-dom';
import React from 'react';

export const NotFound = () => {
  const theme = useTheme();

  return (
    <Center width="full" height="78vh">
      <VStack w={{ base: '50%', md: '33.33%' }} spacing="10">
        <Heading
          fontFamily="rootFont"
          color="white"
          textTransform="capitalize"
          fontSize="4xl"
          textAlign="center"
        >
          Trang bạn tìm không tồn tại
        </Heading>
        <NotchedButton
          bg="primary"
          color={'white'}
          w="full"
          _hover={{
            bg: darken(theme.colors['primary'], 15),
          }}
          _active={{
            bg: darken(theme.colors['primary'], 20),
          }}
        >
          <Link to="/">Về trang chủ</Link>
        </NotchedButton>
      </VStack>
    </Center>
  );
};

export default NotFound;
