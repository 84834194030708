import React from 'react';
import { Step, Title } from './InstructionTemplate';
import { Box, Image, Text } from '@chakra-ui/react';
import getEnv from '@assets/getEnv.png';

export const GetInstruction = () => {
  return (
    <Box
      bg="white"
      width={{ base: '90%', sm: '80%', md: '70%' }}
      marginX="auto"
      marginTop="40px"
      borderRadius="12px"
      padding={{ base: '24px', md: '40px' }}
    >
      <Title title="Nhận bao lì xì" />
      <Box marginTop="32px">
        <Box marginBottom="40px">
          <Step item="1" />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Để có thể nhận được lì xì, bạn cần kết nối ví Metamask vào hệ thống
            của LixiNFT
          </Text>
        </Box>
        <Box>
          <Step item="2" />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Để có thể nhận được lì xì NFT, bạn cần hoàn thành 2 điều kiện sau:
            <b>
              Đăng ký vào danh sách nhận lì xì của chủ bao lì xì & Được chủ bao
              lì xì duyệt nhận lì xì
            </b>
            . Sau khi đã thỏa mãn cả 2 điều kiện trên, hệ thống sẽ gửi thông báo
            cho bạn biết rằng bạn đã nhận được lì xì và bạn có thể đăng nhập vào
            để claim lì xì của mình.
          </Text>
          <Image marginY="40px" src={getEnv} marginX="auto" />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            Ghi chú: Hãy để ý về ngày hết hạn của lì xì, sau thời gian đó bạn sẽ
            không thể nhận lì xì và tiền trong bao lì xì sẽ tự động được chuyển
            vào quỹ quay thưởng xổ số.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
