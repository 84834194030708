import { IS_STAGING } from '@constants';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcSigner } from '@ethersproject/providers';
import ERC20Abi from '../../abi/ERC20.json';
import { BscscanProvider } from '@ethers-ancillary/bsc';

export function getERC20Contract(
  address: string,
  library?: JsonRpcSigner,
  isWrite = false,
) {
  if (isWrite && !library) {
    throw new Error('Write contract must provide signer');
  }

  if (isWrite) {
    return new Contract(address, ERC20Abi, library);
  }

  const bscProvider = new BscscanProvider(IS_STAGING === 'true' ? 97 : 56);
  return new Contract(address, ERC20Abi, bscProvider);
}
