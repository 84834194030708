import { RootState } from '@store';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type EnvelopeFormState = {
  name: string;
  description: string;
  hashTag: string;
  numberItem: number;
  endTime: string;
  currency: string;
  amountOnItem: number;
  logoUri: string;
  logoName: string;
  otherTokenAddress: string;
};

type FieldArgs = {
  name: any;
  value: any;
};

const initialState: EnvelopeFormState = {
  name: '',
  description: '',
  hashTag: '',
  numberItem: 1,
  endTime: '',
  currency: '',
  amountOnItem: 0,
  logoUri: '',
  logoName: '',
  otherTokenAddress: '',
};

const slice = createSlice({
  name: 'envelopeForm',
  initialState,
  reducers: {
    setField: (state, { payload }: PayloadAction<FieldArgs>) => {
      state[payload.name] = payload.value;
    },

    setFields: (
      state,
      { payload }: PayloadAction<Partial<EnvelopeFormState>>,
    ) => ({
      ...state,
      ...payload,
    }),

    reset: () => initialState,
  },
});

export default slice.reducer;

export const { setField, setFields, reset: resetEnvelopeForm } = slice.actions;

export const selectEnvelopeFormLogoUri = () => (state: RootState) =>
  state.envelopeForm.logoUri;

export const selectEnvelopeFormLogoName = () => (state: RootState) =>
  state.envelopeForm.logoName;

export const selectEnvelopeFormFields = () => (state: RootState) =>
  state.envelopeForm;

export const selectEnvelopeFormField =
  (name: keyof EnvelopeFormState) => (state: RootState) =>
    state.envelopeForm[name];
