import { Center, Flex, useTheme, WrapItem } from '@chakra-ui/react';
import React from 'react';

export const WrapTag: React.FunctionComponent = props => {
  const theme = useTheme();
  return (
    <WrapItem>
      <Center>
        <Flex
          px={2}
          py={1}
          alignItems="center"
          gap={2}
          border={`1px solid ${theme.colors.border}`}
        >
          {props.children}
        </Flex>
      </Center>
    </WrapItem>
  );
};

export default WrapTag;
