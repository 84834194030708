import {
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FilterProps, Row, useAsyncDebounce } from 'react-table';

export interface GlobalFilterProps {
  preGlobalFilteredRows: Row<object>[];
  globalFilter: string;
  setGlobalFilter: (val: string) => void;
}

export const GlobalFilter: React.FC<GlobalFilterProps> = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Input
      value={value || ''}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Tìm trong ${count} bản ghi...`}
      w="full"
      size="lg"
      variant="flushed"
    />
  );
};

export const DefaultColumnFilter: React.FC<FilterProps<object>> = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Tìm trong ${preFilteredRows.length} bản ghi...`}
      size="xs"
    />
  );
};

export const SelectColumnFilter: React.FC<FilterProps<object>> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set<string>();
    preFilteredRows.forEach(row => {
      const val: string = row.values[id];
      options.add(val);
    });
    return [...Array.from(options.values())];
  }, [id, preFilteredRows]);

  return (
    <Select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      size="xs"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};

export const SelectEmailFilter: React.FC<FilterProps<object>> = ({
  column: { filterValue, setFilter },
}) => {
  return (
    <Select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      size="xs"
    >
      <option value="">All</option>
      <option value="true">Verified</option>
      <option value="false">Not verified</option>
    </Select>
  );
};

export const NumberRangeColumnFilter: React.FC<FilterProps<object>> = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = React.useMemo<[number, number]>(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  const initialValue = React.useMemo<[number, number]>(() => [min, max], []);

  return (
    <VStack minW="7rem">
      <NumberInput
        min={min}
        value={filterValue[0] ?? ''}
        onChange={valStr => setFilter((old = initialValue) => [valStr, old[1]])}
        size="xs"
        step={0.00001}
        precision={5}
      >
        <NumberInputField placeholder={`Min (${min})`} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Text>đến </Text>
      <NumberInput
        value={filterValue[1] ?? ''}
        onChange={valStr => {
          setFilter((old = initialValue) => [old[0], valStr]);
        }}
        size="xs"
        step={0.00001}
        precision={5}
      >
        <NumberInputField placeholder={`Max (${max})`} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </VStack>
  );
};
