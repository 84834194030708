import { BigNumber } from '@ethersproject/bignumber';
import { LIXI_NFT } from '@constants';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import LixiNFTAbi from '../../abi/LixiNFT.json';

export const balanceOf = async (
  address: string,
  library: Web3Provider,
): Promise<number> => {
  const contract = new Contract(LIXI_NFT, LixiNFTAbi, library);

  const balance = (await contract.balanceOf(address)) as BigNumber;

  return balance.toNumber();
};
