import TOKEN_LOGO from '@assets/token-coloured.svg';
import { HStack, Image, Text } from '@chakra-ui/react';
import { ADDRESS_ZERO } from '@utils';
import { MetamaskToken } from '@web3react';
import { round } from 'lodash';
import React from 'react';

export interface FormatTokenProps {
  iconW?: number;
  iconH?: number;
  color?: string;
  fontSize?: number;
  fontWeight?: number | 'string';
  amount: number;
  token: Omit<MetamaskToken, 'decimals'> | undefined;
  currency?: string;
}
export const FormatToken: React.FunctionComponent<FormatTokenProps> = ({
  amount,
  token,
  iconH = 8,
  iconW = 8,
  color = 'white',
  fontSize = 28,
  fontWeight = 700,
  currency,
}) => {
  return (
    <HStack>
      {currency !== ADDRESS_ZERO ? (
        <Image src={token ? token.logo : TOKEN_LOGO} w={iconW} h={iconH} />
      ) : null}
      <Text fontSize={fontSize} fontWeight={fontWeight} color={color}>
        {amount > 0
          ? `${round(amount * 0.8, 7)} ${
              token ? token.symbol.toUpperCase() : ''
            } (+0.01 BNB)`
          : '0.01 BNB'}
      </Text>
    </HStack>
  );
};

export default FormatToken;
