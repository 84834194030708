import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum CreatingStage {
  CHECKING = 'checking',
  CREATING_ON_BE = 'creating on server',
  CREATING_ON_SC = 'creating on sc',
  ERROR = 'error',
  IDLE = 'idle',
  SUCCESS = 'success',
  UPLOADING_LOGO = 'uploading logo',
  WAITING_APPROVAL = 'waiting approval',
  WAITING_USER = 'waiting user',
}

export const getCreatingStageText = (stage: CreatingStage) => {
  switch (stage) {
    case CreatingStage.CHECKING:
      return 'Đang kiểm tra...';
    case CreatingStage.CREATING_ON_BE:
      return 'Kết nối với máy chủ...';
    case CreatingStage.CREATING_ON_SC:
      return 'Kết nối với hợp đồng thông minh...';
    case CreatingStage.ERROR:
      return 'Lỗi';
    case CreatingStage.IDLE:
      return 'Thanh toán';
    case CreatingStage.SUCCESS:
      return 'Thành công';
    case CreatingStage.UPLOADING_LOGO:
      return 'Đang upload logo...';
    case CreatingStage.WAITING_APPROVAL:
      return 'Chấp thuận token...';
    case CreatingStage.WAITING_USER:
      return 'Đang đợi người dùng phản hồi...';
    default:
      return '';
  }
};

export type CreatingStageState = {
  stage: CreatingStage;
};

const slice = createSlice({
  name: 'creatingStage',
  initialState: { stage: CreatingStage.IDLE } as CreatingStageState,
  reducers: {
    setCreatingStage: (state, action: PayloadAction<CreatingStage>) => {
      state.stage = action.payload;
    },
  },
});

export default slice.reducer;

export const { setCreatingStage } = slice.actions;

export const selectCurrentCreatingStage = (state: RootState) =>
  state.creatingStage.stage;
