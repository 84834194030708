import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi, User } from '../../app/services/auth';
import type { RootState } from '../../app/store';

type AuthState = {
  user: User | null;
  token: string | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      !action.payload
        ? window.localStorage.removeItem('wallet_signed_token')
        : window.localStorage.setItem('wallet_signed_token', action.payload);
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      authApi.endpoints.getMe.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.me;
      },
    );

    builder.addMatcher(
      authApi.endpoints.signUpWithoutInfo.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.signup;
      },
    );

    builder.addMatcher(
      authApi.endpoints.updateUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.updateUser;
      },
    );

    builder.addMatcher(
      authApi.endpoints.openVerifyEmail.matchFulfilled,
      (state, { payload }) => {
        if (payload.openVerifyEmail.user) {
          state.user = payload.openVerifyEmail.user;
        }
      },
    );
  },
});

export default slice.reducer;

export const { setToken, setUser } = slice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentToken = (state: RootState) => {
  return window.localStorage.getItem('wallet_signed_token') || state.auth.token;
};
