import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const NotchedBox: React.FunctionComponent<BoxProps> = props => {
  const polygon = 'polygon(0% 0%, 100% 0, 100% 90%, 90% 100%, 0% 100%);';
  return (
    <Box
      use="div"
      clipPath={polygon}
      css={{
        WebkitClipPath: polygon,
      }}
      {...props}
    />
  );
};

export default NotchedBox;
