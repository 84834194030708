import bannerLive from '@assets/bannerLive.jpeg';
import bannerLogo from '@assets/livestreamIcon.png';
import radioIcon from '@assets/radio.svg';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

export const LivestreamSection: React.FC = () => {
  const [, setIsStarted] = useState(false);

  const checkLivestreamTime = () => {
    const tZone = 'Asia/Ho_Chi_Minh';
    const timeTillDate = '2022-02-22T13:00:00Z';
    const livestreamTime = moment.tz(timeTillDate, tZone);
    const now = moment().tz(tZone);

    return now > livestreamTime;
  };

  useEffect(() => {
    const id = setInterval(() => {
      setIsStarted(checkLivestreamTime());
    }, 3000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <Box pb="5rem" pt="5rem" bg="yellowBg">
      <Flex
        align="center"
        justify={{ base: 'center', sm: 'space-around', xl: 'space-between' }}
        direction={{ base: 'column-reverse', sm: 'row' }}
        wrap="nowrap"
        flexDirection="column"
        px="0"
        ml="auto"
        mr="auto"
      >
        <Flex
          align="center"
          justify={{ base: 'center', sm: 'space-around', xl: 'space-between' }}
          direction={{ base: 'column-reverse', sm: 'row' }}
          wrap="nowrap"
          flexDirection="column"
        >
          <Image src={bannerLogo} mb="1.5rem" height="52px" width="auto" />
          <Text
            fontSize={{ base: 48, md: 80 }}
            fontFamily="headingFont"
            fontWeight="bold"
            color="darkBg"
            lineHeight="1"
            mb="2.5rem"
            textAlign="center"
            maxW={{ base: '22rem', md: '33rem' }}
          >
            Sự kiện livestream quay số trúng thưởng
          </Text>
        </Flex>

        <Box w={{ base: '95%', md: '60%' }}>
          <ReactPlayer
            width="100%"
            url="https://www.youtube.com/embed/yvopY2O_MXU"
          />
        </Box>

        <Box px={{ base: '2', sm: '20' }} display="none">
          <Flex
            flexDirection="column"
            width="100%"
            maxW="800px"
            alignItems="center"
            pos="relative"
          >
            <Flex
              flexDirection="column"
              p="8px 12px"
              backgroundColor="white"
              alignItems="center"
              pos="absolute"
              bottom={{ base: '1rem', md: '2rem' }}
              borderRadius="8px"
              zIndex="10"
            >
              <Image
                src={radioIcon}
                width="1.5rem"
                height="1.5rem"
                mb=".5rem"
              />
              <Text
                fontSize={{ base: 12, md: 14 }}
                fontFamily="rootFont"
                fontWeight="bold"
                color="darkBg"
                lineHeight="160%"
                textAlign="center"
              >
                Livestream bắt đầu lúc 20:00
              </Text>
              <Text
                fontSize="12px"
                fontFamily="rootFont"
                color="darkBg"
                lineHeight="160%"
                textAlign="center"
                opacity="70%"
              >
                Ngày 22/02/2022
              </Text>
            </Flex>
            <Image src={bannerLive} mixBlendMode="darken" />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
