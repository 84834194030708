import { ticketInfo } from '@web3react';
import { useEffect, useState } from 'react';
import useFetchTokenIds from './useFetchTokenIds';

const useTokenIdOfAccount = (
  groupId: number | undefined,
  account: string | undefined | null,
  tokenIds: number[] = [],
) => {
  const { fetchTokenIds } = useFetchTokenIds(true);
  const [tokenId, setTokenId] = useState(0);

  useEffect(() => {
    const fetchTokenInfos = async () => {
      if (!account || !groupId) {
        return;
      }

      const _tokenIds = tokenIds.length
        ? Array.from(tokenIds)
        : Array.from((await fetchTokenIds()) ?? []);

      if (_tokenIds.length && !tokenId) {
        const tokenInfoArr = await Promise.all(
          _tokenIds.map(async tokenId => {
            return ticketInfo(tokenId);
          }),
        );

        const idx = tokenInfoArr.findIndex(
          tokenInfo => tokenInfo[0].toNumber() == groupId,
        );

        setTokenId(idx < 0 ? idx : _tokenIds[idx]);
      }
    };

    fetchTokenInfos();
  }, [account, groupId]);

  return tokenId;
};

export default useTokenIdOfAccount;
