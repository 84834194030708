import React from 'react';
import { SecondTitle, Title } from './InstructionTemplate';
import { Box, Text } from '@chakra-ui/react';

export const JoinJackpot = () => {
  return (
    <Box
      bg="white"
      width={{ base: '90%', sm: '80%', md: '70%' }}
      marginX="auto"
      marginTop="40px"
      borderRadius="12px"
      padding={{ base: '24px', md: '40px' }}
    >
      <Title title="Tham gia xổ số" />
      <Box marginTop="32px">
        <Box marginBottom="40px">
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Khi tạo hoặc nhận lì xì trên LixiNFT, bạn đều có cơ hội được tham
            gia quay thưởng xổ số trên hệ thống. Sẽ có 2 hình thức quay thưởng
            mà bạn cần biết: <b>Xổ số nội bộ & xổ số tập thể.</b>
          </Text>
        </Box>
        <Box marginBottom="40px">
          <SecondTitle content="Xổ số nội bộ" />
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Xổ số nội bộ là trò chơi quay thưởng nội bộ, những đối tượng tham
            gia trò chơi này sẽ bao gồm chủ bao lì xì (người quay số) và toàn bộ
            những người nhận được bao lì xì từ chủ bao (tham gia với tư cách là
            người chơi)
            <br />
            <br />
            Mỗi người chơi sẽ có xác suất chiến thắng là ngang nhau, hoàn toàn
            công bằng. Giải thưởng của xổ số nội bộ sẽ được tính bằng 20% tổng
            giá trị <b>token tặng</b>. Hãy hiểu đơn giản như sau:
            <br />
            <br />
            Ví dụ: Chủ bao lì xì tạo 10 bao lì xì và bên trong mỗi bao lì xì có
            10 token ={'>'} Vậy tổng giá trị <b>token tặng</b> là 10 token x 10
            bao = 100 token và giải thưởng của xổ số nội bộ sẽ là 20 token (20%
            của 100 token)
            <br />
            <br />
            Vậy mỗi một bao lì xì người nhận sẽ nhận về 8 token. Về cơ bản, mỗi
            người nhận được bao lì xì sẽ đều quyên góp 20% token trong bao của
            mình ra để cùng quyên góp vào quỹ giải thưởng. Ai may mắn chiến
            thắng sẽ sở hữu giải thưởng đó.
          </Text>
        </Box>
        <Box marginBottom="40px">
          <SecondTitle content="Xổ số tập thể (Jackpot)" />
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Khác với xổ số nội bộ, đối tượng tham gia của xổ số tập thể sẽ là
            toàn bộ người chơi trên hệ thống (bao gồm cả chủ bao lì xì và người
            nhận lì xì)
            <br />
            <br />
            Để tham gia được vào trò chơi, bạn cần phải có vé Lixi NFT và xác
            suất chiến thắng của trò chơi này sẽ phụ thuộc vào số vé Lixi NFT
            bạn đang có (Càng nhiều lixi NFT tỉ lệ thắng càng cao)
          </Text>
        </Box>
        <Box>
          <SecondTitle content="Kiếm lixi NFT như thế nào?" />
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Lixi NFT sẽ được tặng trong quá trình tạo bao lì xì, tạo càng nhiều
            bao lì xì bạn sẽ nhận được càng nhiều Lixi NFT
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Chính sách bonus như sau:
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            - Tạo từ 10 bao lì xì tặng 10% tổng số bao
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            - Tạo từ 100 bao lì xì tặng 20% tổng số bao
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            - Tạo từ 300 bao lì xì tặng 30% tổng số bao
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Số bao lì xì tối đa bạn được tạo là 500 bao.
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Người quay số sẽ là ban tổ chức của LixiNFT.me, sự kiện quay số sẽ
            được diễn ra trong buổi livestream trên website vào ngày 15/2. Hãy
            theo dõi LixiNFT.me trên fanpage để cập nhật thời gian diễn ra sự
            kiện.
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            Giải thưởng sẽ được phân bổ như sau: 70% BNB trong quỹ thưởng sẽ
            dành cho người thắng cuộc. 30% còn lại được chia đều như sau:
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            - 10% cho chủ bao lì xì của người thắng cuộc
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            - 10% cho quỹ LixiNFT.me
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            - 10% được đóng góp cho hoạt động từ thiện (tổ chức từ thiện sẽ là
            do cộng đồng bỏ phiếu)
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
