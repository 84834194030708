import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { DefaultColumnFilter, GlobalFilter } from '@containers';
import React from 'react';
import {
  Column,
  FilterTypes,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { Pagination } from '../Pagination';

export interface MembersTableProps {
  columns: Column<object>[];
  data: object[];
  isMinimal?: boolean;
  hasFilter?: boolean;
}

export const MembersTable: React.FC<MembersTableProps> = ({
  columns,
  data,
  isMinimal,
  hasFilter,
}) => {
  const filterTypes = React.useMemo<FilterTypes<object>>(
    () => ({
      text: (rows, ids, filterValue) => {
        const id = ids[0];
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue != undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const filterProps = hasFilter ? { defaultColumn, filterTypes } : {};
  const filterPluginHooks = [useFilters, useGlobalFilter];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      ...filterProps,
    },
    ...filterPluginHooks,
    usePagination,
  );

  return (
    <Flex flexDirection="column" width="100%" overflowY="auto">
      {hasFilter && (
        <GlobalFilter
          globalFilter={globalFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, idx) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, idxHeader) => (
                <Th
                  minW="20"
                  {...column.getHeaderProps()}
                  key={`${idx}${idxHeader}`}
                >
                  <VStack justifyContent="center">
                    <Box>{column.render('Header')}</Box>
                    {hasFilter && (
                      <Box>{column.canFilter && column.render('Filter')}</Box>
                    )}
                  </VStack>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, idx) => {
                  return (
                    <Td {...cell.getCellProps()} key={idx}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        gotoNextPage={nextPage}
        gotoPreviousPage={previousPage}
        pageIndex={pageIndex}
        numberOfPages={pageCount}
        pageSize={pageSize}
        onPageSizeChange={(size: number) => setPageSize(size)}
        isMinimal={isMinimal}
      />
    </Flex>
  );
};

export default MembersTable;
