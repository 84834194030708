import { BigNumber } from '@ethersproject/bignumber';
import { getLixiBagContract } from '../utils';

export type TicketInfo = [
  groupId: BigNumber,
  purchaser: string,
  purchaseDate: BigNumber,
  receiver: string,
];

export const ticketInfo = async (ticketId: number): Promise<TicketInfo> => {
  const contract = getLixiBagContract();

  const ticketInfo = (await contract.ticketInfo(ticketId)) as TicketInfo;

  return ticketInfo;
};
