import React from 'react';
import { Center, Circle, Flex, VStack } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

export const SingleStep = (props: {
  currentStep: number;
  title: string;
  step: number;
}) => {
  const isChoosing = props.step === props.currentStep;

  return (
    <VStack m="1rem">
      <Circle
        width="40px"
        height="40px"
        bg={!isChoosing ? 'rgba(255, 255, 255, 0.5)' : '#F0514E'}
        borderRadius="50%"
        color={!isChoosing ? '#091B2D' : 'white'}
        fontSize={{ base: '14px', sm: '16px', md: '18px' }}
        fontWeight="400"
      >
        {props.currentStep >= props.step ? (
          props.currentStep
        ) : (
          <CheckIcon size="md" />
        )}
      </Circle>
      <Center
        fontSize={{ base: '12px', sm: '14px', md: '16px' }}
        fontWeight="600"
        marginLeft="8px"
        color={!isChoosing ? 'rgba(255, 255, 255, 0.5)' : '#F0514E'}
      >
        {props.title}
      </Center>
    </VStack>
  );
};

export const FormTitle = (props: { step: number }) => {
  return (
    <Flex
      justifyContent={{ base: 'center', md: 'space-between' }}
      wrap="wrap"
      m="-1rem"
    >
      <SingleStep
        currentStep={1}
        step={props.step}
        title="Thông tin chung"
        key={'Thông tin chung'}
      />
      <SingleStep
        currentStep={2}
        step={props.step}
        title="Số lượng"
        key={'Số lượng'}
      />
      <SingleStep
        currentStep={3}
        step={props.step}
        title="Token thêm"
        key={'Token thêm'}
      />
    </Flex>
  );
};
