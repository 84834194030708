import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { BsShieldFillCheck } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

export interface StatusBadgeProps {
  tooltip?: string;
  status: 'trust' | 'warning';
  size?: number;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  tooltip,
  status,
  size = 14,
}) => {
  const result = (
    <Box cursor="pointer">
      {status === 'trust' ? (
        <BsShieldFillCheck size={`${size}px`} color="green" />
      ) : (
        <IoIosAlert size={`${size}px`} color="red" />
      )}
    </Box>
  );

  return tooltip ? (
    <Tooltip label={tooltip} aria-label={tooltip}>
      {result}
    </Tooltip>
  ) : (
    result
  );
};

export default StatusBadge;
