import Resizer from 'react-image-file-resizer';

export function resizeImage(imageFile: File, size = 1400): Promise<File> {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      imageFile,
      size,
      size,
      'PNG',
      100,
      0,
      uri => {
        resolve(uri as File);
      },
      'file',
      size,
      size,
    );
  });
}
