/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { QuestionIcon } from '@chakra-ui/icons';
import {
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  NumberInput,
  NumberInputField,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react';
import { selectEnvelopeFormFields, setFields } from '@features';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '@hooks';
import { calculateBonusNft } from '@utils';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import '../../styles/custom.css';
import { CardInfo } from '../CardInfo';
import { NotchedButton } from '../NotchedButton';
import { PaymentSummary } from '../PaymentSummary';

const Info = (props: { title: string; description: string[] }) => {
  return (
    <Menu isLazy placement="right-end">
      <MenuButton>
        <QuestionIcon color="red" />
      </MenuButton>
      <Portal>
        <MenuList p="3">
          <VStack alignItems="flex-start">
            <Text fontWeight="semibold">{props.title}</Text>
            {props.description.map((d, idx) => (
              <Text key={idx}>{d}</Text>
            ))}
          </VStack>
        </MenuList>
      </Portal>
    </Menu>
  );
};

interface AmountFormState {
  numberItem: number;
  bonusNft: number;
  endTime: Date;
}

const schema = yup.object({
  numberItem: yup
    .number()
    .required('Số người là trường bắt buộc')
    .min(1, 'Số người phải trên 1'),
  endTime: yup
    .date()
    .default(() => new Date())
    .required('Ngày kết thúc là bắt buộc')
    .min(new Date(Date.now() - 100000), 'Ngày kết thúc phải ở tương lai'),
});

export const Amount = (props: { nextStep: () => void; goBack: () => void }) => {
  const {
    control,
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
    watch,
  } = useForm<AmountFormState>({
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const fieldState = useAppSelector(selectEnvelopeFormFields());

  useEffect(() => {
    const resetVal = {} as AmountFormState;

    resetVal['numberItem'] = fieldState['numberItem'];
    if (fieldState['endTime']) {
      resetVal['endTime'] = new Date(fieldState['endTime']);
    }

    reset(resetVal);
  }, []);

  const onSubmit = (data: Omit<AmountFormState, 'bonusNft'>) => {
    const endTime = data['endTime'].toString();
    dispatch(setFields({ ...data, endTime }));
  };

  const handleNextStep = async () => {
    if (await trigger()) {
      await handleSubmit<AmountFormState>(onSubmit)();
      props.nextStep();
    }
  };

  const handleGoBack = async () => {
    await handleSubmit<AmountFormState>(onSubmit)();
    props.goBack();
  };

  return (
    <>
      <CardInfo
        heading="Bên trong lì xì có gì?"
        content={[
          'Trong 1 bao lì xì sẽ có: Số token tặng, LixiNFT (dùng để chơi xổ số tập thể) và 0.01BNB phí gas',
          '20% tổng giá trị token tặng sẽ được chuyển tự động vào quỹ thưởng xổ số nội bộ',
        ]}
        pb="20"
      />
      <FormControl id="numberItem" isInvalid={!!errors.numberItem}>
        <FormLabel
          htmlFor="numberItem"
          mt="32px"
          fontSize="20px"
          fontWeight="600"
        >
          Số người bạn muốn tặng
        </FormLabel>
        <NumberInput>
          <NumberInputField
            min={1}
            type="number"
            bg="white"
            color="darkBg"
            {...register('numberItem')}
          />
          <InputRightElement>
            <Info
              title="Chi tiết"
              description={[
                'Tổng số bao lì xì bạn muốn tạo',
                'Mỗi bao lì xì trị giá 0.01 BNB',
              ]}
            />
          </InputRightElement>
        </NumberInput>
        {errors.numberItem && (
          <FormErrorMessage>{errors.numberItem.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormLabel
        htmlFor="bonusNft"
        fontSize="20px"
        fontWeight="600"
        marginTop="32px"
      >
        Số LixiNFT Bonus
      </FormLabel>
      <InputGroup>
        <Input
          name="bonusNft"
          type="number"
          bg="green.200"
          color="darkBg"
          readOnly={true}
          value={calculateBonusNft(watch('numberItem') || 1).toString()}
        />
        <InputRightElement>
          <Info
            title="Chi tiết về bonus LixiNFT:"
            description={[
              'Tạo từ 10 bao lì xì tặng 10% tổng số bao',
              'Tạo từ 100 bao lì xì tặng 20% tổng số bao',
              'Tạo từ 300 bao lì xì tặng 30% tổng số bao',
            ]}
          />
        </InputRightElement>
      </InputGroup>
      <FormControl id="endTime" isInvalid={!!errors.endTime}>
        <FormLabel
          htmlFor="endTime"
          fontSize="20px"
          fontWeight="600"
          marginTop="32px"
        >
          Thời gian hết hạn
        </FormLabel>
        <Controller
          name="endTime"
          control={control}
          render={props => (
            <DatePicker
              selected={props.field.value}
              onChange={(date: Date) => props.field.onChange(date)}
            />
          )}
        />
        {errors.endTime && (
          <FormErrorMessage>{errors.endTime.message}</FormErrorMessage>
        )}
      </FormControl>
      <PaymentSummary
        heading="Tổng thanh toán"
        lixiAmount={watch('numberItem')}
      />
      <ButtonGroup w="full" flexDirection="column-reverse">
        <NotchedButton
          bgColor="transparent"
          color="primary"
          marginTop="5"
          width="100%"
          onClick={handleGoBack}
        >
          Quay lại
        </NotchedButton>
        <NotchedButton
          bgColor="primary"
          color="white"
          marginTop="5"
          marginLeft="0 !important"
          width="100%"
          onClick={handleNextStep}
        >
          Tiếp theo
        </NotchedButton>
      </ButtonGroup>
    </>
  );
};
