/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCreateCampaignMutation, useUploadMutation } from '@app/services';
import { useToast } from '@chakra-ui/react';
import { LIXI_BAG, TEMPLATE_ID } from '@constants';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import {
  CreatingStage,
  selectEnvelopeFormFields,
  setCreatingStage,
} from '@features';
import { useAppDispatch, useAppSelector } from '@hooks';
import { calculateBonusNft, dataURLtoFile, prepareUploadbody } from '@utils';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import lixiBagAbi from '../../abi/LixiBag.json';

export function useMintLixiListener() {
  const [lixiBagContract, setLixiBagContract] = useState<Contract | null>(null);
  const [isMintLixiFired, setIsMintLixiFired] = useState(false);

  const { library, account } = useWeb3React<Web3Provider>();

  const dispatch = useAppDispatch();
  const fieldState = useAppSelector(selectEnvelopeFormFields());

  const [upload] = useUploadMutation();
  const [createCampaign] = useCreateCampaignMutation();

  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });

  const navigate = useNavigate();

  const mintLixiHandler = useCallback(
    async (
      owner: string,
      groupId: BigNumber,
      numTickets: BigNumber,
      startTicketId: BigNumber,
      purchaseDate: BigNumber,
      globalJackpotAdded: BigNumber,
    ) => {
      console.log("Event 'MintLixi' with payload");
      console.log({
        owner,
        groupId,
        numTickets,
        startTicketId,
        purchaseDate,
        globalJackpotAdded,
      });

      if (isMintLixiFired) return;

      if (owner !== account) return;

      setIsMintLixiFired(true);

      const groupIdNum = groupId.toNumber();
      const startTicketIdNum = startTicketId.toNumber();
      const {
        otherTokenAddress,
        logoUri,
        logoName,
        endTime: endTimeStr,
        ...restFields
      } = fieldState;

      try {
        dispatch(setCreatingStage(CreatingStage.UPLOADING_LOGO));
        const file = dataURLtoFile(logoUri, logoName);
        if (!file) throw new Error('Upload file is null');

        const formData = prepareUploadbody(file);
        const uploadPayload = await upload({
          folder: 'logo',
          body: formData,
        }).unwrap();

        dispatch(setCreatingStage(CreatingStage.CREATING_ON_BE));

        const endTime = new Date(endTimeStr);
        const numberOfItem = restFields['numberItem'];

        const createPayload = {
          ...restFields,
          endTime,
          logo: uploadPayload.Location,
          templateId: TEMPLATE_ID,
          templatePosition: 'CENTER',
          groupId: groupIdNum,
          startTokenId: startTicketIdNum - calculateBonusNft(numberOfItem),
          endTokenId: startTicketIdNum + numberOfItem,
        };

        await createCampaign(createPayload).unwrap();
        dispatch(setCreatingStage(CreatingStage.SUCCESS));
        navigate('/lixi');
      } catch (error) {
        dispatch(setCreatingStage(CreatingStage.IDLE));
        console.log(error);

        toast({
          title: 'Error',
          description: 'Oops, có gì đó hoạt động chưa đúng rồi',
          status: 'error',
        });
      }
    },
    [fieldState],
  );

  useEffect(() => {
    let contract: Contract | null = null;

    (() => {
      if (!library || !account) return;

      contract = new Contract(LIXI_BAG, lixiBagAbi, library.getSigner(0));
      setLixiBagContract(() => contract);

      contract.on('MintLixi', mintLixiHandler);
    })();

    return () => {
      if (contract) contract.removeAllListeners();
    };
  }, [library, account, fieldState]);

  return { lixiBagContract };
}
