import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { blobToBase64, dataURLtoFile, getRoundedCanvas } from '@utils';
import React, { useEffect, useState } from 'react';
import { CropperComponent } from './CropperComponent';

export const CropperModal = (props: {
  header: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  file: File | null;
  setFile: (file: File | null) => void;
}) => {
  const [cropper, setCropper] = useState<Cropper>();
  const [croppable, setCroppable] = useState(false);
  const [image, setImage] = useState('');

  const handleCloseClick = () => {
    props.setFile(null);
    props.onClose();
  };

  const handleCropClick = async () => {
    if (!cropper || !props.file || !croppable) return;

    const croppedCanvas = cropper.getCroppedCanvas();
    const roundedCanvas = getRoundedCanvas(croppedCanvas);

    if (!roundedCanvas) return;

    const dataUrl = roundedCanvas.toDataURL();
    const file = dataURLtoFile(dataUrl, props.file.name);

    if (!file) return;

    props.setFile(file);
    setImage('');
    props.onClose();
  };

  const handleSetCropper = (cropperInstance: Cropper) => {
    setCropper(cropperInstance);
  };

  useEffect(() => {
    (async () => {
      if (!props.file) return;

      setImage((await blobToBase64(props.file)) as string);
    })();
  }, [props.file]);

  return (
    <Modal
      blockScrollOnMount={true}
      closeOnOverlayClick={true}
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CropperComponent
            imageSrc={image}
            setCropper={handleSetCropper}
            handleCropableChange={(croppable: boolean) =>
              setCroppable(croppable)
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleCloseClick}>
            Close
          </Button>
          <Button variant="ghost" onClick={handleCropClick}>
            Crop
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CropperModal;
