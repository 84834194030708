/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumberish } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { parseEther } from '@ethersproject/units';
import ERC20 from '../abi/ERC20.json';
import lixiBagAbi from '../abi/LixiBag.json';
import { LIXI_BAG } from '../constants';
import { fromWei, MAX_UINT128, toWei } from './contractUtils';

export const createCampaignOnSC = (
  numMint: number,
  extraToken: string,
  extraTokenAmount: BigNumberish,
  receivers: string[],
  signer: JsonRpcSigner,
  value: BigNumberish,
): Promise<any> => {
  const lixiBagContract = new Contract(LIXI_BAG, lixiBagAbi, signer);
  const amount = parseEther(value.toString());

  return lixiBagContract.mintLixi(
    Number(numMint),
    extraToken,
    extraTokenAmount,
    receivers,
    {
      value: amount,
    },
  );
};

export const listenEventOnCreateCampaign = (contract: any, callback: any) => {
  contract.listeners('MintLixi', event => {
    console.log('listeners');
    console.log(event);
    callback();
  });

  contract.on('MintLixi', event => {
    console.log('evenEEEEEEEt');
    console.log(event);
    callback();
  });
};

export const checkingAllowanceOnSC = async (
  owner: string,
  tokenAddress: string,
  library: Web3Provider,
): Promise<boolean> => {
  const ERC20Contract = new Contract(tokenAddress, ERC20, library);
  const res = await ERC20Contract.allowance(owner, LIXI_BAG);
  return fromWei(res) !== '0';
};

export const approveOnSC = async (
  tokenAddress: string,
  signer: JsonRpcSigner,
): Promise<boolean> => {
  const ERC20Contract = new Contract(tokenAddress, ERC20, signer);
  await ERC20Contract.approve(LIXI_BAG, toWei(MAX_UINT128));
  return true;
};

export const erc20DecimalsOnSc = async (
  tokenAddress: string,
  library: Web3Provider,
): Promise<number> => {
  const ERC20Contract = new Contract(tokenAddress, ERC20, library);
  return ERC20Contract.decimals();
};

export const addWhitelister = async (
  groupId: number | undefined,
  userAddress: string | undefined,
  signer: JsonRpcSigner | undefined,
  isAdmin = false,
) => {
  const lixiBagContract = new Contract(LIXI_BAG, lixiBagAbi, signer);
  if (isAdmin) {
    await lixiBagContract.addWhitelisterByAdmin(groupId, userAddress);
  } else {
    await lixiBagContract.addWhitelister(groupId, userAddress);
  }
};

export const addManyWhitelister = async (
  groupId: number | undefined,
  userAddresses: (string | undefined)[],
  signer: JsonRpcSigner | undefined,
  isAdmin = false,
) => {
  const lixiBagContract = new Contract(LIXI_BAG, lixiBagAbi, signer);
  if (isAdmin) {
    await lixiBagContract.addManyWhitelistersByAdmin(groupId, userAddresses);
  } else {
    await lixiBagContract.addManyWhitelisters(groupId, userAddresses);
  }
};

export const claimLixiByOwnerOrAdmin = async (
  groupId: number | undefined,
  userAddress: string | undefined,
  signer: JsonRpcSigner | undefined,
  isAdmin = false,
) => {
  const lixiBagContract = new Contract(LIXI_BAG, lixiBagAbi, signer);
  if (isAdmin) {
    await lixiBagContract.claimLixiByAdmin(groupId, userAddress);
  } else {
    await lixiBagContract.claimLixiByOwner(groupId, userAddress);
  }
};

export const claimLixi = async (
  groupId: number | undefined,
  signer: JsonRpcSigner | undefined,
) => {
  const lixiBagContract = new Contract(LIXI_BAG, lixiBagAbi, signer);
  return await lixiBagContract.claimLixi(groupId);
};

export const removeWhitelister = async (
  groupId: number | undefined,
  userAddress: string | undefined,
  signer: JsonRpcSigner | undefined,
) => {
  const lixiBagContract = new Contract(LIXI_BAG, lixiBagAbi, signer);
  await lixiBagContract.removeWhitelister(groupId, userAddress);
};

export const claimGlobalJackpot = async (signer: JsonRpcSigner | undefined) => {
  const lixiBagContract = new Contract(LIXI_BAG, lixiBagAbi, signer);
  return await lixiBagContract.claimGlobalJackpot();
};
