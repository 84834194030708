export const getMemberStatusProps = (status: string | undefined) => {
  if (status === 'APPROVED') {
    return {
      label: 'Đã duyệt',
      bgColor: '#A4FFBD',
      color: 'darkBg',
    };
  } else if (status === 'DECLINED') {
    return {
      label: 'Bị từ chối',
      bgColor: '#DEDEDE',
      color: 'darkBg',
    };
  } else if (status === 'PENDING') {
    return {
      label: 'Chờ duyệt',
      bgColor: '#DEDEDE',
      color: 'darkBg',
    };
  } else if (status === 'CLAIMED') {
    return {
      label: 'Đã nhận',
      bgColor: 'primary',
      color: 'white',
    };
  }

  return {
    label: 'Không xác định',
    bgColor: '#DEDEDE',
    color: 'darkBg',
  };
};

export const getMemberStatusByLabel = (label: string) => {
  switch (label) {
    case 'Đã duyệt':
      return {
        status: 'APPROVED',
        bgColor: '#A4FFBD',
        color: 'darkBg',
      };

    case 'Bị từ chối':
      return {
        status: 'DECLINED',
        bgColor: '#DEDEDE',
        color: 'darkBg',
      };

    case 'Chờ duyệt':
      return {
        status: 'PENDING',
        bgColor: '#DEDEDE',
        color: 'darkBg',
      };

    case 'Đã nhận':
      return {
        status: 'CLAIMED',
        bgColor: 'primary',
        color: 'white',
      };

    default:
      return {
        status: 'UNDEFINED',
        bgColor: '#DEDEDE',
        color: 'darkBg',
      };
  }
};

export const hiddenFullName = (fullName?: string) => {
  if (!fullName) {
    return '';
  }

  const items = fullName.split(' ');
  const notEmptyItems = items.filter(item => item !== '');

  const newItems = notEmptyItems.map(item => {
    return `${item[0]}${`*`.repeat(item.length - 1)}`;
  });

  return newItems.join(' ');
};
