export interface MetamaskError extends Error {
  data: { message: string };
}

export const isMetamaskError = (error: unknown): error is MetamaskError => {
  if (
    error &&
    typeof error === 'object' &&
    Object.prototype.hasOwnProperty.call(error, 'data') &&
    Object.prototype.hasOwnProperty.call(error['data'], 'message')
  )
    return true;

  return false;
};
