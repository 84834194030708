import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

interface NotchedButtonProps extends ButtonProps {
  notchSize?: number;
  transparent?: boolean;
}

export const NotchedButton: React.FC<NotchedButtonProps> = ({
  children,
  notchSize = 1,
  transparent = false,
  ...rest
}) => {
  const clipPathVal = `polygon(
    0% ${notchSize}rem, 
    ${notchSize}rem 0%, 
    100% 0%, 
    100% ${notchSize}rem, 
    100% calc(100% - ${notchSize}rem), 
    calc(100% - ${notchSize}rem) 100%, 
    0 100%, 
    0% calc(100% - ${notchSize}rem)
  )`;

  return (
    <Button
      clipPath={transparent ? '' : clipPathVal}
      {...rest}
      borderRadius="none"
      px={{ base: 0, md: 14 }}
      py={{ base: 0, md: 6 }}
    >
      {children}
    </Button>
  );
};

export default NotchedButton;
