import { Center, Spinner, VStack } from '@chakra-ui/react';
import React from 'react';

export const Loading = () => {
  return (
    <Center width="full" height="70vh">
      <VStack w={{ base: '50%', md: '33.33%' }} spacing="10">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary"
          size="xl"
        />
      </VStack>
    </Center>
  );
};

export default Loading;
