export function calculateBonusNft(val: number) {
  if (!val) val = 0;

  if (val < 100) {
    return Math.floor(val * 0.1);
  } else if (val < 300) {
    return Math.floor(val * 0.2);
  } else {
    return Math.floor(val * 0.3);
  }
}
