import React from 'react';
import { Cropper } from 'react-cropper';
import './cropper.css';

export interface CropperComponentProps {
  imageSrc: string;
  setCropper: (cropper: Cropper) => void;
  handleCropableChange: (croppable: boolean) => void;
}

export const CropperComponent: React.FC<CropperComponentProps> = ({
  imageSrc,
  setCropper,
  handleCropableChange,
}) => {
  return (
    <Cropper
      src={imageSrc}
      style={{ minHeight: 400, width: '100%' }}
      dragMode="move"
      aspectRatio={1}
      autoCropArea={1}
      restore={false}
      guides={false}
      center={false}
      highlight={false}
      cropBoxMovable={false}
      cropBoxResizable={false}
      toggleDragModeOnDblclick={false}
      onInitialized={instance => setCropper(instance)}
      ready={() => {
        handleCropableChange(true);
      }}
    />
  );
};

export default CropperComponent;
