import { Flex } from '@chakra-ui/react';
import { UpdateProfileContainer } from '@containers';
import React from 'react';

export function Profile(): JSX.Element {
  return (
    <Flex
      w="full"
      align={'center'}
      justify={'center'}
      color="white"
      mb={10}
      fontFamily="rootFont"
      paddingTop={{ base: '4.5rem', md: '8rem' }}
    >
      <UpdateProfileContainer />
    </Flex>
  );
}

export default Profile;
