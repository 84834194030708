import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Campaign, campaignApi } from '@services';
import { RootState } from '@store';

const campaignsAdapter = createEntityAdapter<Campaign>({
  selectId: campaign => campaign._id,
});

const slice = createSlice({
  name: 'globalCampaign',
  initialState: campaignsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      campaignApi.endpoints.getGlobalCampaign.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.getCampaign) {
          return;
        }
        campaignsAdapter.setOne(state, payload.getCampaign);
      },
    );
  },
});

export default slice.reducer;

const globalizedSelectors = campaignsAdapter.getSelectors<RootState>(
  state => state.globalCampaigns,
);

export const getGlobalCampaignById = (
  state: RootState,
  campaignId: string | undefined,
) => {
  if (!campaignId) {
    return null;
  }
  return globalizedSelectors.selectById(state, campaignId);
};
