import { useUpdateShareLinkMutation } from '@app/services';
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import { BASE_URL } from '@constants';
import copyToClipboard from 'copy-to-clipboard';
import React, { useState } from 'react';
import autoIcon from '../../assets/autoIcon.svg';
import copyIcon from '../../assets/copyIcon.svg';
import shareIcon from '../../assets/shareButton.svg';

export interface ShareButtonProps {
  campaignId: string;
  isOwner?: boolean;
  currentStatus?: 'auto' | 'manual';
  shareId: string | undefined;
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  campaignId,
  isOwner,
  shareId,
  currentStatus,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });
  const onClose = () => {
    setOpenModal(false);
  };

  const [updateShareLink, { isLoading: isUpdating }] =
    useUpdateShareLinkMutation();

  const handleUpdateShareLink = async (type: 'auto' | 'manual') => {
    if (currentStatus === type) return;

    try {
      await updateShareLink({
        campaignId,
        status: type === 'auto' ? 'AUTO_APPROVE' : 'MANUAL_APPROVE',
      }).unwrap();
    } catch (error) {
      console.log(error);
      toast({
        title: 'Error',
        description: 'Oops, có gì đó hoạt động chưa đúng rồi',
        status: 'error',
      });
    }
  };

  const linkShare = `${BASE_URL}/share/${shareId}`;
  const SectionUpdateLink = () => {
    const styleActive = {
      bgColor: 'primary',
      color: 'white',
    };

    const autoStyle = currentStatus !== 'auto' ? styleActive : {};
    const manualStyle = currentStatus === 'auto' ? styleActive : {};

    return (
      <Box py={5}>
        <Flex gap={2}>
          <Image src={autoIcon} w={8} h={8} />

          <Flex flexDirection="column">
            <Text fontWeight="bold">
              {currentStatus === 'auto' ? 'Duyệt tự động:' : 'Chờ duyệt:'}
            </Text>
            <Text>
              {currentStatus === 'auto'
                ? 'Người nhận sẽ nhận được lì xì mà không cần đợi xét duyệt từ bạn'
                : 'Để nhận được lì xì, người nhận sẽ gửi yêu cầu nhận lì xì cho bạn. Sau khi bạn duyêt yêu cầu, họ có thể vào nhận lì xì đến địa chỉ ví bạn đã duyệt.'}
            </Text>
          </Flex>
          <Flex gap={1}>
            <Button
              size="sm"
              disabled={currentStatus === 'auto'}
              {...autoStyle}
              onClick={() => handleUpdateShareLink('auto')}
            >
              Duyệt tự động
            </Button>
            <Button
              size="sm"
              disabled={currentStatus === 'manual'}
              {...manualStyle}
              onClick={() => handleUpdateShareLink('manual')}
            >
              Chờ duyệt
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      <Image
        css={{ cursor: 'pointer' }}
        onClick={() => {
          setOpenModal(true);
        }}
        src={shareIcon}
        w={34}
        h={34}
      />
      <Modal isOpen={openModal} onClose={onClose} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={`1px solid ${theme.colors.border}`}>
            Chia sẻ link lì xì
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={5}>
            <Box>
              {isOwner && <SectionUpdateLink />}
              <Flex alignItems="center" gap={2} w="full">
                <Input
                  readOnly={true}
                  value={linkShare}
                  isDisabled={isUpdating}
                />
                <Button
                  px={10}
                  borderRadius={50}
                  color="white"
                  bgColor="dark"
                  leftIcon={<Image src={copyIcon} />}
                  isLoading={isUpdating}
                  onClick={() => {
                    copyToClipboard(linkShare);
                    toast({
                      title: 'Sao chép',
                      description: 'Sao chép thành công',
                      status: 'success',
                    });
                  }}
                >
                  Sao chép
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareButton;
