import { sign } from 'web3-token';
import { JsonRpcSigner } from '@ethersproject/providers';

export async function signMessage(
  signer: JsonRpcSigner,
  expireTime = '30d',
): Promise<string> {
  return await sign(async msg => await signer.signMessage(msg), {
    expires_in: expireTime,
  });
}
