/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AttachmentIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { selectEnvelopeFormFields, setFields } from '@features';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '@hooks';
import { blobToBase64, dataURLtoFile, resizeImage } from '@utils';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CropperModal } from '../CropperModal';
import { NotchedButton } from '../NotchedButton';

interface GeneralFormState {
  name: string;
  description: string;
  hashTag: string;
}

const schema = yup.object({
  name: yup
    .string()
    .required('Bạn phải điền tên từ 3 kí tự trở lên')
    .min(3, 'Tên trên 3 kí tự'),
  description: yup.string().required('Mô tả là trường bắt buộc'),
  hashTag: yup
    .string()
    .required('Bạn phải điền hashtag từ 3 đến 15 kí tự')
    .min(3, 'Hashtag trên 3 kí tự')
    .max(15, 'Hashtag tối đa 15 kí tự')
    .matches(/^\S*$/, 'Hashtag không có dấu cách'),
});

export const GeneralForm = (props: { nextStep: () => void }) => {
  const [uploadLogo, setUploadLogo] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileError, setFileError] = useState<Error | null>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const dispatch = useAppDispatch();

  const fieldState = useAppSelector(selectEnvelopeFormFields());

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormState>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      name: fieldState['name'],
      description: fieldState['description'],
      hashTag: fieldState['hashTag'],
    });

    (async () => {
      const logo = dataURLtoFile(fieldState['logoUri'], fieldState['logoName']);

      if (!logo) return;

      setUploadLogo(logo);
      setFileName(fieldState['logoName'] || null);
    })();
  }, []);

  const handleFile = (ev: any) => {
    const file = ev.target.files[0] as File;
    const cloneLogo = new File([file], file.name, {
      type: file.type,
    });

    // Clear file value
    ev['target'].value = '';

    setFileError(null);
    setFileName(cloneLogo.name);
    setUploadLogo(cloneLogo);
    onOpen();
  };

  const handleOnCrop = async (file: File | null) => {
    if (!file) {
      setFileName(null);
      setUploadLogo(file);
      return;
    }

    const resizedImg = await resizeImage(file, 1481);
    setUploadLogo(() => resizedImg);
    setFileName(resizedImg.name);
  };

  const onSubmit = async (data: Partial<GeneralFormState>) => {
    const acceptedFiles = ['image/jpeg', 'image/png'];

    if (!uploadLogo) {
      return setFileError(new Error('Bạn phải upload logo doanh nghiệp'));
    } else if (uploadLogo.size > 4194304) {
      return setFileError(new Error('Bạn phải upload file nhỏ hơn 4MB'));
    } else if (!acceptedFiles.includes(uploadLogo.type)) {
      return setFileError(new Error('Bạn phải upload file dạng JPEG hoặc PNG'));
    }

    if (data['hashTag'] && !data['hashTag'].startsWith('#'))
      data['hashTag'] = '#' + data['hashTag'];

    const logoUri = (await blobToBase64(uploadLogo)) as string;
    if (!logoUri) return;

    dispatch(setFields({ ...data, logoUri, logoName: uploadLogo.name }));
    props.nextStep();
  };

  const format = (val: string) => (val.startsWith('#') ? val : `#` + val);
  const parse = (val: string) => val.replace(/^#/, '');

  return (
    <>
      <form>
        <FormControl id="name" isInvalid={!!errors.name}>
          <FormLabel htmlFor="name" fontSize="20px" fontWeight="600">
            Tên bao lì xì
          </FormLabel>
          <Input type="text" bg="white" color="darkBg" {...register('name')} />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl id="description" isInvalid={!!errors.description}>
          <FormLabel
            htmlFor="description"
            fontSize="20px"
            fontWeight="600"
            marginTop="32px"
          >
            Mô tả ngắn
          </FormLabel>
          <Input
            type="text"
            bg="white"
            color="darkBg"
            {...register('description')}
          />
          {errors.description && (
            <FormErrorMessage>{errors.description.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormLabel
          htmlFor="logo"
          fontSize="20px"
          fontWeight="600"
          marginTop="32px"
        >
          Ảnh logo doanh nghiệp
        </FormLabel>
        <FormControl id="logo" isInvalid={!!fileError}>
          <Box position="relative">
            <Input
              name="logo"
              type="file"
              height="180px"
              opacity="0"
              zIndex="1"
              cursor="pointer"
              onChange={handleFile}
            />
            <Flex
              bg="white"
              width="100%"
              height="180px"
              position="absolute"
              top="0"
              color="#091B2D"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              borderRadius="var(--chakra-radii-md)"
              p="3"
            >
              <AttachmentIcon />
              <Text textAlign="center">
                {fileName
                  ? fileName
                  : 'Tải ảnh JPEG hoặc PNG. Kích thước 1400x1400 và < 4MB'}
              </Text>
            </Flex>
          </Box>
          {fileError && (
            <FormErrorMessage>{fileError.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl id="hashTag" isInvalid={!!errors.hashTag}>
          <FormLabel
            htmlFor="hashTag"
            fontSize="20px"
            fontWeight="600"
            marginTop="32px"
          >
            Hashtag
          </FormLabel>
          <Controller
            name="hashTag"
            control={control}
            defaultValue=""
            render={props => (
              <Input
                type="text"
                bg="white"
                color="darkBg"
                placeholder="Hashtag"
                value={format(props.field.value)}
                onChange={(ev: any) =>
                  props.field.onChange(parse(ev.target.value))
                }
              />
            )}
          />
          {errors.hashTag && (
            <FormErrorMessage>{errors.hashTag.message}</FormErrorMessage>
          )}
        </FormControl>
        <NotchedButton
          bgColor="primary"
          color="white"
          marginTop="32px"
          marginBottom="50px"
          width="100%"
          onClick={() => handleSubmit<GeneralFormState>(onSubmit)()}
        >
          Tiếp theo
        </NotchedButton>
      </form>
      <CropperModal
        header="Cắt logo"
        file={uploadLogo}
        setFile={handleOnCrop}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
    </>
  );
};
