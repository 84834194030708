import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';

export interface PaginationProps {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (pageNumber: number) => void;
  gotoNextPage: () => void;
  gotoPreviousPage: () => void;
  pageIndex: number;
  numberOfPages: number;
  pageSize: number;
  pageSizeValues?: number[];
  onPageSizeChange: (pageSize: number) => void;
  isMinimal?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  gotoNextPage,
  gotoPreviousPage,
  pageIndex,
  numberOfPages,
  pageSize,
  pageSizeValues = [10, 20, 30, 40, 50],
  onPageSizeChange,
  isMinimal,
}) => {
  return (
    <Flex bg="white" justifyContent="space-between" m={4} alignItems="center">
      <Flex>
        <Tooltip label="Trang đầu">
          <IconButton
            aria-label="Trang đầu"
            onClick={() => gotoPage(0)}
            isDisabled={!canPreviousPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
          />
        </Tooltip>
        <Tooltip label="Trang trước">
          <IconButton
            aria-label="Trang trước"
            onClick={gotoPreviousPage}
            isDisabled={!canPreviousPage}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <Box>
          <Text flexShrink="0" mx={8}>
            {!isMinimal ? (
              <>
                Trang{' '}
                <Text fontWeight="bold" as="span">
                  {pageIndex + 1}
                </Text>{' '}
                của{' '}
                <Text fontWeight="bold" as="span">
                  {numberOfPages}
                </Text>
              </>
            ) : (
              <>
                <Text fontWeight="bold" as="span">
                  {pageIndex + 1}
                </Text>
                /
                <Text fontWeight="bold" as="span">
                  {numberOfPages}
                </Text>
              </>
            )}
          </Text>
        </Box>
        {!isMinimal && (
          <>
            <Text flexShrink="0">Tới trang:</Text>{' '}
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={numberOfPages}
              onChange={value => {
                const page = value ? Number(value) - 1 : 0;
                gotoPage(page);
              }}
              defaultValue={pageIndex + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              w={32}
              value={pageSize}
              onChange={e => {
                onPageSizeChange(Number(e.target.value));
              }}
            >
              {pageSizeValues.map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Hiển thị {pageSize}
                </option>
              ))}
            </Select>
          </>
        )}
      </Flex>

      <Flex>
        <Tooltip label="Trang tiếp">
          <IconButton
            aria-label="Trang tiếp"
            onClick={gotoNextPage}
            isDisabled={!canNextPage}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Trang cuối">
          <IconButton
            aria-label="Trang cuối"
            onClick={() => gotoPage(numberOfPages - 1)}
            isDisabled={!canNextPage}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Pagination;
