import star from '@assets/stars.png';
import wheel from '@assets/wheel.png';
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { NotchedButton } from '../NotchedButton';

export const PrizeCard = () => {
  const attrs = {
    id: 'coinmarketcap-widget-coin-price-block',
    coins: '1839,1027,1',
    currency: 'VND',
    theme: 'light',
    transparent: 'false',
    'show-symbol-logo': 'true',
  };

  return (
    <Flex
      margin={{ base: '3rem auto', md: '0 auto 5rem' }}
      py={{ base: '5px', md: '20px' }}
      background="white"
      borderRadius={{ base: 0, md: '"24px"' }}
      alignItems="center"
      justifyContent="space-between"
      flexDirection={{ base: 'column', md: 'row' }}
      position="relative"
      w={{ base: '100%', lg: '80%' }}
    >
      <Stack
        direction={{ base: 'column', xl: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        h="100%"
        px="10"
        py="5"
        overflowX="scroll"
      >
        <Flex
          alignItems="center"
          marginY="36px"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Image width="167px" src={wheel} />
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems={{ base: 'center', md: 'flex-start' }}
          >
            <Box>
              <Text
                fontFamily="rootFont"
                fontSize="1rem"
                fontWeight="500"
                color="darkBg"
                marginBottom="16px"
              >
                Giải Xổ Số Metaverse Trị Giá
              </Text>
            </Box>

            <Flex alignItems="center">
              <Box>
                <Text
                  fontFamily="headingFont"
                  color="darkBg"
                  fontWeight="bold"
                  fontSize={{ base: '3rem', md: '4rem' }}
                >
                  3.72 BNB
                </Text>
              </Box>
              <Box ml="5px">
                <Image
                  boxSize={{ base: '16px', md: '32px' }}
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                />
              </Box>
            </Flex>
            <Flex>
              <div {...attrs}></div>
            </Flex>
          </Flex>
        </Flex>
        <Box>
          <NotchedButton
            color="white"
            background="primary"
            fontFamily="rootFont"
            fontWeight="600"
            fontSize="1rem"
            p="5"
          >
            <Link to="/the-le">Thể lệ tham gia</Link>
          </NotchedButton>
        </Box>
      </Stack>
      <Image
        src={star}
        position="absolute"
        height={{ base: '0', md: 'auto' }}
        right="0"
        bottom="0"
        width="130px"
        borderBottomRightRadius="24px"
      />
    </Flex>
  );
};
