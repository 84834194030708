import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

export interface EnvelopeWarningModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const EnvelopeWarningModal: React.FC<EnvelopeWarningModalProps> = ({
  isOpen,
  onOpen,
  onClose,
}) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Tạo bao lì xì
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack>
              <Box>
                <Text>
                  Quá trình tạo bao lì xì có thể sẽ kéo dài đến 2 phút.
                </Text>
              </Box>
              <Box>
                <Text>
                  Bạn vui lòng đợi và chọn xác nhận với ví Metamask để hoàn tất
                  quá trình tạo bao lì xì.
                </Text>
              </Box>
              <Box>
                <Text color="red">
                  Lưu ý: Đừng tải lại trang trong quá trình tạo, bạn có thể bị
                  mất tiền đấy.
                </Text>
              </Box>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Hủy
            </Button>
            <Button colorScheme="red" onClick={onOpen} ml={3}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default EnvelopeWarningModal;
