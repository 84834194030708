import { store } from '@app/store';
import { ChakraProvider } from '@chakra-ui/react';
import { Fonts, WrapperPattern } from '@components';
import { MainLayoutContainer } from '@containers';
import { Web3Provider } from '@ethersproject/providers';
import '@fontsource/ibm-plex-mono';
import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorFallback, Loading } from './pages';
import { AppRoutes } from './routes';
import theme from './theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const App: React.FC<Record<string, never>> = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ReduxProvider store={store}>
          <ChakraProvider theme={theme}>
            <Fonts />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Router>
                <MainLayoutContainer>
                  <WrapperPattern>
                    <AppRoutes />
                  </WrapperPattern>
                </MainLayoutContainer>
              </Router>
            </ErrorBoundary>
          </ChakraProvider>
        </ReduxProvider>
      </Web3ReactProvider>
    </React.Suspense>
  );
};

export default App;
