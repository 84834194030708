import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router';
import { Ticket } from './Ticket';
import { MAX_NUMBER_OF_TICKETS } from './TicketsCard';

const Tickets: React.FC<{ tickets: number[] }> = ({ tickets }) => {
  const navigate = useNavigate();

  return (
    <Box
      background="primary"
      color="white"
      borderRadius="8px"
      maxWidth="600px"
      margin="0 auto 2rem auto"
      p="1rem 2rem"
    >
      <Text
        fontFamily="rootFont"
        fontSize="16px"
        opacity="0.6"
        paddingTop="16px"
        marginBottom="8px"
      >
        Các vé số bạn đang sở hữu
      </Text>
      <Flex flexWrap="wrap" justifyContent="center" py="3">
        {tickets.length ? (
          (tickets.length > MAX_NUMBER_OF_TICKETS
            ? Array.from(tickets).splice(0, MAX_NUMBER_OF_TICKETS)
            : tickets
          ).map((ticket, index) => <Ticket key={index} ticketValue={ticket} />)
        ) : (
          <VStack>
            <Text
              fontSize="30px"
              fontFamily="rootFont"
              fontWeight="700"
              marginBottom="24px"
              lineHeight="1.5em"
            >
              Bạn chưa có vé nào, hãy tạo bao lì xì để sở hữu vé tham gia
            </Text>
            <Button
              colorScheme="gray"
              color="darkBg"
              onClick={() => navigate('/tao-lixi')}
            >
              Tạo bao lì lì
            </Button>
          </VStack>
        )}
      </Flex>
      {tickets.length > MAX_NUMBER_OF_TICKETS && (
        <Text fontSize="30px" fontFamily="headingFont" fontWeight="700">
          {`...và thêm ${tickets.length - MAX_NUMBER_OF_TICKETS} vé nữa`}
        </Text>
      )}
    </Box>
  );
};

export default Tickets;
