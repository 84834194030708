import { useToast } from '@chakra-ui/react';
import { LIXI_BAG } from '@constants';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import {
  CreatingStage,
  selectEnvelopeFormFields,
  setCreatingStage,
} from '@features';
import { useAppSelector, useAppDispatch } from '@hooks';
import { ADDRESS_ZERO, approveOnSC, checkingAllowanceOnSC } from '@utils';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import ERC20Abi from '../../abi/ERC20.json';

export function useApproveTokenListener() {
  const [isApproved, setIsApproved] = useState(false);
  const [ERC20Contract, setERC20Contract] = useState<Contract | null>(null);

  const { currency } = useAppSelector(selectEnvelopeFormFields());

  const { library, account } = useWeb3React<Web3Provider>();

  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });

  const dispatch = useAppDispatch();

  const handleIsApprovedChange = (val: boolean) => setIsApproved(() => val);

  const checkingAllowance = useCallback(async () => {
    if (!library || !account || !currency) return;

    if (currency === ADDRESS_ZERO) {
      return setIsApproved(true);
    }

    try {
      const hasApproved = await checkingAllowanceOnSC(
        account,
        currency,
        library,
      );

      if (hasApproved) {
        return setIsApproved(true);
      }

      dispatch(setCreatingStage(CreatingStage.WAITING_USER));
      await approveOnSC(currency, library.getSigner(0));

      dispatch(setCreatingStage(CreatingStage.WAITING_APPROVAL));
    } catch (error) {
      dispatch(setCreatingStage(CreatingStage.IDLE));
      console.log(error);
      toast({
        title: 'Error',
        description: 'Úi, lỗi khi kiểm tra allowance trên SC',
        status: 'error',
      });
    }
  }, [library, account, currency]);

  useEffect(() => {
    let contract: Contract | null = null;

    (() => {
      if (!library || !account || !currency || !!ERC20Contract) return;

      contract = new Contract(currency, ERC20Abi, library.getSigner(0));
      setERC20Contract(() => contract);

      contract.on(
        'Approval',
        async (tokenOwner: string, spender: string, amount: BigNumber) => {
          console.log("Event 'Approval' with payload");
          console.log({ tokenOwner, spender, amount });

          // Checking for right event
          const isValidEv = tokenOwner === account && spender === LIXI_BAG;
          if (!isValidEv) return;

          setIsApproved(true);
        },
      );
    })();

    return () => {
      if (contract) contract.removeAllListeners('Approval');
    };
  }, [library, account, currency]);

  return { isApproved, handleIsApprovedChange, checkingAllowance };
}
