import { Box, Flex, Text } from '@chakra-ui/react';
import { Duration, intervalToDuration } from 'date-fns';
import React, { useEffect, useState } from 'react';

export interface CountDownProps {
  endTime: Date | undefined;
}

export const CountDown: React.FC<CountDownProps> = ({ endTime }) => {
  const [duration, setDuration] = useState<Duration | undefined>(undefined);

  if (!endTime) {
    return null;
  }

  const refreshTime = () => {
    const startDuration = intervalToDuration({
      start: new Date(),
      end: endTime,
    });
    setDuration(startDuration);
  };

  useEffect(() => {
    refreshTime();
  }, [endTime]);

  useEffect(() => {
    const intervalTime = setInterval(() => {
      refreshTime();
    }, 1000);
    return () => clearInterval(intervalTime);
  }, [endTime]);
  const CountItem: React.FC<{ label: string; value: number }> = ({
    label,
    value,
  }) => {
    return (
      <Flex flexDirection="column" alignItems="center" w="16" px="12">
        <Box px={5}>
          <Text
            fontFamily="headingFont"
            fontSize={{ base: '48px', md: '80px' }}
            lineHeight={1}
            color="primary"
            textAlign="center"
          >
            {value < 10 ? `0${value}` : value}
          </Text>
        </Box>
        <Text
          fontWeight={700}
          fontSize={16}
          color="white"
          py={2}
          textTransform="uppercase"
        >
          {label}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex
      gap={5}
      flexDirection={'row'}
      wrap={{ base: 'wrap', md: 'nowrap' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      mb={{ base: '1rem', md: '0' }}
    >
      <CountItem
        label="Ngày"
        value={duration && duration.days ? duration.days : 0}
      />
      <CountItem
        label="Giờ"
        value={duration && duration.hours ? duration.hours : 0}
      />
      <CountItem
        label="Phút"
        value={duration && duration.minutes ? duration.minutes : 0}
      />
      <CountItem
        label="Giây"
        value={duration && duration.seconds ? duration.seconds : 0}
      />
    </Flex>
  );
};

export default CountDown;
