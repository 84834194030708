export const GRAPHQL_BASE_URL =
  process.env.REACT_APP_GRAPHQL_BASE_URL ||
  'https://staging.api.lixinft.me/graphql';

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://testnets.lixinft.me';

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://staging.api.lixinft.me';

export const TEMPLATE_ID =
  process.env.REACT_APP_TEMPLATE_ID || '61ee4f50081e4d196ba80130';

export const LIXI_BAG =
  process.env.REACT_APP_LIXI_BAG ||
  '0xa8091b9a42Bc54E0CF433CA901ADb0b9Da1e667E';

export const LIXI_NFT =
  process.env.REACT_APP_GRAPHQL_LIXI_NFT ||
  '0x6dB55DaF091CfC03D14d33d8e0862382f7A2c339';

export const IS_STAGING = process.env.REACT_APP_IS_STAGING || 'true';

export const BSC_SCAN =
  IS_STAGING === 'true'
    ? 'https://testnet.bscscan.com/'
    : 'https://bscscan.com/';

export const GLOBAL_JACKPOT_CAMPAIGN_ID =
  IS_STAGING === 'true'
    ? '620f49bc89d60c001c721213'
    : '62146eb20edc4f2808fabda5';

export const WINNING_HASH =
  process.env.REACT_APP_WINNING_HASH || IS_STAGING === 'true'
    ? '0x83ae0e03f041d22b318722d57fd0df04a3f5bbbfc54bdbe9bddb4d91f94dcbd1'
    : '0xb8dc9719d70cec6ad0d50d87e70dad8aefca674364895dd19ff80049a1894bfd';

export const GAS_PRICE = parseFloat(process.env.REACT_APP_GAS_PRICE || '0.002');

export const BLOG_URL =
  process.env.REACT_APP_BLOG_URL || 'https://blog.lixinft.me/';
