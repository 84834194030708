import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TicketType = number[] | null;

const slice = createSlice({
  name: 'ticket',
  initialState: null as TicketType,
  reducers: {
    setTicket: (_, { payload }: PayloadAction<number[] | null>) => payload,
  },
});

export default slice.reducer;

export const { setTicket } = slice.actions;

export const selectTicketValue = (state: RootState) => state.ticket;
