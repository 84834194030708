import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputProps,
  useToast,
} from '@chakra-ui/react';
import { useAppDispatch } from '@hooks';
import { authApi } from '@services';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { StatusBadge } from '../StatusBadge';

export interface EditableFieldProps {
  id: string;
  label: string;
  registerReturnObj: UseFormRegisterReturn;
  isEmailVerified?: boolean;
  isInvalid: boolean;
  errorMessage?: string;
  inputForwardProps: InputProps;
}

export const EditableField: React.FC<EditableFieldProps> = ({
  id,
  label,
  registerReturnObj,
  isEmailVerified,
  isInvalid = false,
  errorMessage,
  inputForwardProps = {},
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast({
    duration: 2000,
    position: 'bottom',
    isClosable: true,
  });
  const verifyEmail = authApi.endpoints.verifyEmail.initiate;

  const IsEmailVerifiedIcon = () => {
    const message = isEmailVerified
      ? 'Email đã xác thực'
      : 'Email chưa xác thực';

    return isEmailVerified ? (
      <StatusBadge tooltip={message} status="trust" />
    ) : (
      <StatusBadge tooltip={message} status="warning" />
    );
  };

  const VerifyButton = () => (
    <Button
      colorScheme="red"
      px="10"
      ml="3"
      onClick={async () => {
        const { error } = await dispatch(
          verifyEmail(undefined, {
            forceRefetch: true,
          }),
        );

        toast({
          title: error ? 'Error' : 'Success',
          description: error
            ? error.message
            : 'Gửi thành công, hãy kiểm tra hòm thư của bạn',
          status: error ? 'error' : 'success',
        });
      }}
    >
      Xác thực email
    </Button>
  );

  return (
    <FormControl id={id} isInvalid={isInvalid}>
      <HStack alignItems="center" spacing="-1">
        <Box>
          <FormLabel>{label}</FormLabel>
        </Box>
        {isEmailVerified != undefined && (
          <Box __css={{ marginBottom: '0.5rem !important' }}>
            <IsEmailVerifiedIcon />
          </Box>
        )}
      </HStack>
      <Flex>
        <Input {...inputForwardProps} {...registerReturnObj} />
        {isEmailVerified === false && <VerifyButton />}
      </Flex>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default EditableField;
