import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Web3Provider } from '@ethersproject/providers';
import { WalletState } from '@features/wallet/walletSlice';
import { useAuth, useCopyableRef } from '@hooks';
import { useWeb3React } from '@web3-react/core';
import { formatAddress, NETWORKS } from '@web3react';
import React from 'react';

export interface WalletInfoProps {
  wallet: WalletState;
}

export const WalletInfo: React.FC<WalletInfoProps> = ({ wallet }) => {
  const { chainId } = useWeb3React<Web3Provider>();
  const { address, network, balance, symbol } = wallet;
  const { ref, isCopied, copy } = useCopyableRef<HTMLSpanElement>();
  const { signOut } = useAuth();

  const fallbackAddress = `0x${'0'.repeat(40)}`;
  const balanceDisplay = balance != null ? balance : 'NaN';

  const handleCopyBtnClick = () => {
    copy();
  };

  const handleViewBtnClick = () => {
    if (!chainId) {
      return;
    }

    const explorers = NETWORKS[chainId].explorers;
    if (!(explorers && explorers.length)) {
      return;
    }

    const url = `${explorers[0].url}/address/${address}`;
    window.open(url, '_blank');
  };

  return (
    <Popover trigger="hover" isLazy>
      <PopoverTrigger>
        <Button
          onClick={handleCopyBtnClick}
          isDisabled={isCopied}
          height="48px"
          fontSize={{ base: '0.625rem', md: '1rem' }}
        >
          <Tooltip label="Click to copy" aria-label="A tooltip">
            {!isCopied ? formatAddress(address || fallbackAddress) : 'Đã copy'}
          </Tooltip>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>Thông tin ví</PopoverHeader>
        <PopoverBody>
          <Stat>
            <StatLabel>{network || '-'}</StatLabel>
            <StatNumber>
              {balanceDisplay} {symbol || '-'}
            </StatNumber>
          </Stat>
          <VisuallyHidden ref={ref}>
            {address || fallbackAddress}
          </VisuallyHidden>
        </PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button colorScheme="cyan" onClick={handleViewBtnClick}>
              Xem ví trên scan
            </Button>
            <Button colorScheme="red" onClick={() => signOut()}>
              Ngắt kết nối
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default WalletInfo;
