import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const Footer: React.FunctionComponent<BoxProps> = props => {
  return (
    <Box
      use="footer"
      role="contentinfo"
      mx="auto"
      py="9"
      px={{ base: '4', md: '8' }}
      css={{ flexShrink: 0 }}
      {...props}
    />
  );
};

export default Footer;
