import { ADDRESS_ZERO } from './contractUtils';
export interface Token {
  address: string;
  symbol: string;
  logo?: string;
}

export const ZERO_TOKEN: Token = {
  address: ADDRESS_ZERO,
  symbol: '',
  logo: '',
};

export const commonTokenOnMainnet: { [symbol: string]: Token } = {
  ETH: {
    address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    symbol: 'ETH',
    logo: 'https://bscscan.com/token/images/ethereum_32.png',
  },
  WBNB: {
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
    symbol: 'WBNB',
  },
  BTCB: {
    address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png',
    symbol: 'BTCB',
  },
};

export const commonTokenOnTestNet: { [symbol: string]: Token } = {
  WBNB: {
    address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    symbol: 'WBNB',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
  },
  BTCB: {
    address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
    symbol: 'BTCB',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png',
  },
  DAI: {
    address: '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867',
    symbol: 'DAI',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
  },
  BUSD: {
    address: '0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47',
    symbol: 'BUSD',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png',
  },
  USDT: {
    address: '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
    symbol: 'USDT',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
  },
};
