import { CampaignMember, campaignApi } from '@services';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '@store';

const campaignMembersAdapter = createEntityAdapter<CampaignMember>({
  selectId: campaignMember => campaignMember._id || Date.now().toString(),
});

const slice = createSlice({
  name: 'campaignMembers',
  initialState: campaignMembersAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      campaignApi.endpoints.getClaimedMembers.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.getClaimedMembers) {
          return;
        }
        campaignMembersAdapter.setAll(state, payload.getClaimedMembers.members);
      },
    );
  },
});

export default slice.reducer;

const globalizedSelectors = campaignMembersAdapter.getSelectors<RootState>(
  state => state.campaignMembers,
);

// This selector already knows how to find the books entity state
export const getAllCampaignMembers = (state: RootState) =>
  globalizedSelectors.selectAll(state);

export const getCampaignMemberById = (
  state: RootState,
  campaignId: string | undefined,
) => {
  if (!campaignId) {
    return null;
  }
  return globalizedSelectors.selectById(state, campaignId);
};
