import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Event {
  IDLE = 'idle', // no activated event
  SETWHITELISTER_WAITING = 'Setwhitelister waiting',
  SETWHITELISTER_FIRED = 'Setwhitelister fired',
  SETWHITELISTER_BY_ADMIN_WAITING = 'SetwhitelisterByAdmin waiting',
  SETWHITELISTER_BY_ADMIN_FIRED = 'SetwhitelisterByAdmin fired',
}

export interface ISetWhitelisterPayload {
  campaignId: string;
  userId: string;
}

export type IPayload = ISetWhitelisterPayload;

export type EventState = {
  eventName: Event;
  payload: IPayload | null;
};

const initialState = {
  eventName: Event.IDLE,
  payload: null,
} as EventState;

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, { payload }: PayloadAction<EventState>) => ({
      ...state,
      ...payload,
    }),

    setIdle: () => initialState,
  },
});

export default slice.reducer;

export const { setEvent, setIdle } = slice.actions;

export const selectEvent = (state: RootState) => state.event;
export const selectEventName = (state: RootState) => state.event.eventName;
export const selectEventPayload = (state: RootState) => state.event.payload;
