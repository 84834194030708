import React from 'react';
import {
  Box,
  Heading,
  Image,
  Link,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import nft from '@assets/nft.png';
import nftExplain from '@assets/nft-explain.png';
import starryNight from '@assets/starry-night.png';
import axieInfinity from '@assets/axie-infinity.png';
import nftPicOwner from '@assets/nft-pic-owner.png';

export const Blog = () => {
  return (
    <Box
      background="white"
      width={{ base: '90%', sm: '80%', md: '70%' }}
      margin="50px auto"
      padding="50px"
      borderRadius="15px"
      paddingTop={{ base: '4.5rem', md: '8rem' }}
    >
      <Heading fontFamily="headingFont" fontSize={{ base: '40px', md: '64px' }}>
        NFT Là Gì? Tại Sao NFT Có Thể Trở Thành Xu Hướng Đầu Tư Mới Trong Năm
        2022
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont" as="i">
        Gần đây, thị trường NFT đang thu hút được khá nhiều sự chú ý của các tín
        đồ tiền ảo crypto. Không chỉ vậy, NFT đang là cụm từ được tìm kiếm “sôi
        nổi” khi ngay cả những bạn không quá am hiểu về đầu tư tiền ảo nay cũng
        đang thắc mắc về chủ đề này. Vậy hôm nay, hãy cùng Cohousing tìm hiểu{' '}
        <b>
          NFT là gì? Tại sao NFT có thể trở thành xu hướng đầu tư mới trong năm
          2022
        </b>{' '}
        trong bài viết này nhé!
      </Text>
      <br />
      <br />
      <Heading fontFamily="rootFont" fontSize="30px">
        NFT Là Gì
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        NFT là cụm từ viết tắt của Non-Fungible Token được gọi một cách khá học
        thuật là <b>Tài sản không thể thay thế</b> hoặc những{' '}
        <b>tài sản không có giá trị thay thế lẫn nhau. NFT</b> để ám chỉ những
        tài sản số đã được mã hóa trên hệ thống của{' '}
        <Link
          color="blueLixi"
          href="https://vi.wikipedia.org/wiki/Blockchain"
          isExternal
        >
          blockchain
        </Link>
        .
      </Text>
      <Image width="50%" marginX="auto" src={nft} alt="NFT là gì?" />
      <Text fontSize="16px" fontFamily="rootFont">
        Để dễ hình dung hơn, ta hãy cùng so sánh NFT với các đồng tiền số
        (Utility token) như <b>Bitcoin</b> hay <b>Etherum</b>. Nếu như mỗi đồng
        bitcoin đều có giá trị như nhau và có thể tráo đổi được cho nhau, thì
        NFT là những tài sản độc nhất và không thể thay thế được.
      </Text>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        <b>Ví dụ:</b>
      </Text>
      <br />
      <UnorderedList>
        <ListItem fontFamily="rootFont" fontSize="16px">
          A có 1 bitcoin.
        </ListItem>
        <ListItem fontFamily="rootFont" fontSize="16px">
          B có 1 bitcoin.
        </ListItem>
        <ListItem fontFamily="rootFont" fontSize="16px">
          ={'>'} A và B hoàn toàn có thể trao đổi cho nhau và không có ai bị
          thiệt hại vì chúng có giá trị tương đương nhau.
        </ListItem>
      </UnorderedList>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        <b>
          Hoặc một ví dụ khác gần gũi hơn, lần này mình sẽ so sánh với tiền
          thật:
        </b>
      </Text>
      <br />
      <UnorderedList>
        <ListItem fontFamily="rootFont" fontSize="16px">
          A có 1 tờ mệnh giá 50.000VNĐ
        </ListItem>
        <ListItem fontFamily="rootFont" fontSize="16px">
          B có 5 tờ mệnh giá 10.000VNĐ
        </ListItem>
        <ListItem fontFamily="rootFont" fontSize="16px">
          Trong trường hợp này A và B vẫn hoàn toàn có thể trao đổi tiền với
          nhau vì: 1 tờ 50.000VNĐ có giá trị là 50.000VNĐ và 5 tờ mệnh giá
          10.000VNĐ cũng có giá trị y hệt.
        </ListItem>
      </UnorderedList>
      <Image
        width="50%"
        marginX="auto"
        src={nftExplain}
        alt="Các tài sản có giá trị thay thế lẫn nhau như tiền, hay tiền ảo (bitcoin)"
      />
      <Text fontSize="16px" fontFamily="rootFont">
        Nhưng đối với NFT, chúng ta sẽ không thể tráo đổi như vậy, vì mỗi một
        tài sản được mã hóa thành NFT sẽ được gắn một đoạn mã code ở trên hệ
        thống của blockchain. Và mỗi một tài sản chỉ có duy nhất một mã. Vậy nên
        giá trị của mỗi tài sản NFT là khác nhau và không thể thay thế nhau.
      </Text>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Ví dụ:
      </Text>
      <br />
      <UnorderedList>
        <ListItem fontFamily="rootFont" fontSize="16px">
          A có một bức tranh có giá trị là X, thì khi A nhân bản bức tranh đó ra
          thành những bức tranh giống y hệt 100% thì những bức tranh được nhân
          bản sẽ được ứng với những giá trị X2, X3, X4,… Và không bao giờ có
          cùng giá trị X với bức tranh ban đầu. Đó chính là “không có giá trị
          thay thế lẫn nhau” (Non-fungible)
        </ListItem>
      </UnorderedList>
      <Text fontSize="16px" fontFamily="rootFont">
        NFT có thể đại diện cho bất cứ loại tài sản nào như: tranh,ảnh số
        (digital art) bài nhạc, video, hình ảnh được gắn với một tài sản thực,…
      </Text>
      <br />
      <Heading fontFamily="rootFont" fontSize="30px">
        Đặc Điểm Của NFT
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        NFT có rất nhiều điểm thú vị, hãy cùng tìm hiểu xem tại sao NFT lại đang
        trở thành một chủ đề được nhiều người quan tâm đến vậy nhé.
      </Text>
      <br />
      <Heading fontFamily="rootFont" fontSize="28px">
        Tính Độc Nhất
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Như đã nêu từ trước đó, mỗi một tài sản được mã hóa NFT sẽ tương ứng với
        một giá trị không thể thay thế. Và những món đồ “limited edition” thì
        thường rất được mọi người quan tâm săn đón. Nhờ vào tính độc nhất này,
        nhiều người sẵn sàng bỏ ra một số tiền lớn để có thể sở hữu những tài
        sản NFT trên thị trường.
      </Text>
      <br />
      <Heading fontFamily="rootFont" fontSize="28px">
        Tính Định Danh Người Sở Hữu
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Hoạt động dưới nền tảng mã hóa của công nghệ Blockchain, người ta có thể
        dễ dàng truy xuất những dữ liệu về nguồn gốc, giao dịch, người sở hữu
        những tài sản đó. Và những thông tin này hoàn toàn minh bạch, chính xác.
        Và đặc biệt, như mọi người đã biết về Blockchain, một khi thông tin đã
        được đưa vào lưu trữ, thì sẽ không bao giờ có thể chỉnh sửa hoặc hack.
      </Text>
      <br />
      <Heading fontFamily="rootFont" fontSize="30px">
        Ứng Dụng Của NFT
      </Heading>
      <br />
      <Heading fontFamily="rootFont" fontSize="28px">
        Trong Nghệ Thuật
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Ở ngoài đời thật, các tác phẩm nghệ thuật, tranh ảnh vật lý có thể chịu
        một số tác động của thời gian dẫn đến về lâu dài sẽ bị hao mòn chất
        lượng nếu không được bảo tồn một cách khoa học. Vấn đề đó sẽ được giải
        quyết khi bạn mã hóa bằng NFT. Tác phẩm NFT sẽ được bảo tồn mãi mãi theo
        thời gian và sẽ không chịu bất kì một ảnh hưởng vật lí nào như mối mọt,
        thiên tai,…
      </Text>
      <br />
      <Image
        width="50%"
        marginX="auto"
        src={starryNight}
        alt="Starry night - Van gogh"
      />
      <br />
      <Heading fontFamily="rootFont" fontSize="28px">
        Trong Games
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Hiện tại, games đang là lĩnh vực được ứng dụng NFT nhiều nhất. Ví dụ dễ
        thấy nhất là tựa game{' '}
        <Link
          color="blueLixi"
          href="https://axieinfinity.com/battle/"
          isExternal
        >
          Axie infinity
        </Link>{' '}
        vô cùng thành công khi áp dụng NFT vào trò chơi của mình. Thông thường,
        các vật phẩm trong game mà đôi khi bạn phải bỏ tiền, thời gian, công sức
        ra để có thể đạt được.
      </Text>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Nhưng các vật phẩm đó lại thuộc quyền sở hữu của nhà phát hành game. Và
        nếu không may, game ngưng phát hành, thì các vật phẩm đó của bạn cũng sẽ
        “tan biến” một cách chóng vánh. Điều này sẽ không xảy ra với các game
        được mã hóa bởi blockchain, các vật phẩm của bạn một khi được “NFT hóa”
        sẽ trở thành một tài sản độc nhất vĩnh viễn tồn tại dưới sự sở hữu của
        bạn. Bạn hoàn toàn có thể bán lại các vật phẩm đó để đổi lấy giá trị
        ngoài đời thật.
      </Text>
      <br />
      <Image
        width="50%"
        marginX="auto"
        src={axieInfinity}
        alt="Tựa game ứng dụng thành công NFT vang dội Axie Infinity"
      />
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Đó cũng là một trong những lí do tại sao gần đây NFT đang rất được quan
        tâm, bởi hiện tại trên thị trường đang có khá nhiều tựa game mà bạn có
        thể cày được ra “tiền thật”.
      </Text>
      <br />
      <Heading fontFamily="rootFont" fontSize="28px">
        Mã Hóa Tài Sản Thật
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Trong tương lai gần, NFT sẽ được ứng dụng phổ biến hơn ngoài đời thực.
        Cụ thể là bạn hoàn toàn có thể mã hóa một tài sản như đất đai, xe cộ,
        quyền chứng nhận sở hữu,…
      </Text>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Hiện tại, hãng giày nổi tiếng Nike đang là một trong những brand tiên
        phong trong lĩnh vực số hóa NFT vào sản phẩm của mình. Cụ thể, Nike đã
        số hóa một số sản phẩm giày của mình để bảo vệ giá trị cho sản phẩm cũng
        như người sở hữu đôi giày đó. Bởi đôi giày đó hoàn toàn có thể tra cứu
        trên hệ thống của blockchain và có thể chứng minh được đây hoàn toàn là
        một đôi giày “chuẩn authentic” chứ không phải là một đôi giày “pha ke”
        trôi nổi nào đó trên thị trường.
      </Text>
      <br />
      <Heading fontFamily="rootFont" fontSize="30px">
        NFT Liệu Có Thể Trở Thành Xu Hướng Đầu Tư Mới?
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Chắc hẳn mọi người đang cảm thấy hoài nghi về việc NFT sẽ trở thành một
        hiện tượng như Bitcoin? Bởi vì dù sao, NFT chỉ là một tài sản số, ta
        hoàn toàn có thể tải một bức tranh từ giống y hệt từ trên mạng về. Vậy
        tại sao người ta lại bỏ ra hàng triệu đô chỉ để có được cái “quyền sở
        hữu” của bức tranh đó.
      </Text>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Tại sao lại có một người đàn ông bỏ ra gần 70 triệu USD để mua một bức
        tranh “không mấy đẹp đẽ” từ trên mạng chỉ để chứng minh rằng tôi sở hữu
        bức tranh đó? Để có thể lý giải cho trường hợp này, có khá nhiều giả
        thiết được đặt ra.
      </Text>
      <br />
      <Image
        width="50%"
        marginX="auto"
        src={nftPicOwner}
        alt="Chân dung người đàn ông chi trả gần 70 triệu USD cho một bức tranh được mã hóa NFT"
      />
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Lý do đơn giản nhất để giải thích có lẽ là do người đàn ông kia quá giàu
        và ông ta muốn được cảm giác độc nhất khi sở hữu một bức tranh mà không
        ai có. Có những người sẵn sàng bỏ ra hàng tá tiền để mua một bức hội họa
        về. Họ đơn giản là cảm thấy “sướng” khi bức tranh đó được treo trên
        tường nhà mình. Nhưng rất có thể sẽ có những lí do đằng sau, rất có thể
        đây chỉ là một cú “huých” để quảng bá cho thị trường NFT.
      </Text>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Không thể phủ nhận rằng, việc người đàn ông bỏ ra 70 triệu USD để mua
        một bức tranh trên mạng đã tạo ra một làn sóng mạnh mẽ trong cộng đồng
        và thu hút được sự chú ý của rất nhiều người. Trong đó bao gồm cả những
        người chưa hề biết gì về NFT, sau khi biết tin lại “rục rịch” tìm hiểu.
        Và khi càng có nhiều người biết đến NFT và công nhận nó. Giá trị của các
        NFT sẽ được tăng trưởng.
      </Text>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        NFT trong tương lai rất có thể sẽ trở thành một xu hướng đầu tư mới, bởi
        những đặc điểm của NFT thật sự rất thú vị và khác biệt hoàn toàn so với
        các đồng tiền ảo crypto, và những ứng dụng của NFT cũng mang lại rất
        nhiều giá trị khác biệt.
      </Text>
      <br />
      <Heading fontFamily="rootFont" fontSize="30px">
        Kết Luận
      </Heading>
      <br />
      <Text fontSize="16px" fontFamily="rootFont">
        Mong rằng qua bài viết này, các bạn đã hình dung về NFT một cách rõ ràng
        hơn. Hãy thường xuyên ghé qua{' '}
        <Link href="https://www.cohousing.vn/" isExternal color="blueLixi">
          Cohousing
        </Link>{' '}
        để cập nhật cũng như đọc những kiến thức bổ ích! Chúc các bạn thành
        công!
      </Text>
    </Box>
  );
};
