import { Global } from '@emotion/react';
import React from 'react';

export const Fonts: React.FC = () => {
  return (
    <Global
      styles={`
		  /* latin */
		  @font-face {
			font-family: 'LixiNFT Font';
			font-weight: bold;
			src: url('/fonts/danh-da-bold.ttf') format('truetype');
		  }
		  @font-face {
			font-family: 'Hashtag Font';
			src: url('/fonts/joystixmonospace.ttf') format('truetype');
		  }
		  `}
    />
  );
};

export default Fonts;
