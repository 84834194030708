import { GAS_PRICE } from '@constants';
import { JsonRpcSigner } from '@ethersproject/providers';
import { toWei } from '@utils';
import { getLixiBagContract } from '@web3react';

export async function sendGasToMany(
  receivers: string[],
  signer: JsonRpcSigner,
) {
  const contract = getLixiBagContract(signer, true);
  return contract.sendGasToMany(receivers, {
    value: toWei(receivers.length * GAS_PRICE),
  });
}
