/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  useToast,
} from '@chakra-ui/react';
import { Web3Provider } from '@ethersproject/providers';
import {
  selectEnvelopeFormField,
  selectEnvelopeFormFields,
  setField,
  setFields,
} from '@features';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  ADDRESS_ZERO,
  commonTokenOnMainnet,
  commonTokenOnTestNet,
  Token,
  ZERO_TOKEN,
} from '@utils';
import { useWeb3React } from '@web3-react/core';
import { fetchingTokenInfo } from '@web3react';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/custom.css';
import { CardInfo } from '../CardInfo';
import { NotchedButton } from '../NotchedButton';
import { PaymentSummary } from '../PaymentSummary';

export const ExtraTokenForm = (props: {
  goBack: () => void;
  onCreateCampaign: () => Promise<void> | void;
}) => {
  const [token, setToken] = useState<Token>(ZERO_TOKEN);
  const [isFetchingTokenInfo, setIsFetchingTokenInfo] = useState(false);

  const [amountOnItem, setAmountOnItem] = useState<number>(0);
  const [isOtherToken, setIsOtherToken] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const { library, chainId } = useWeb3React<Web3Provider>();

  const dispatch = useAppDispatch();

  const fieldState = useAppSelector(selectEnvelopeFormFields());
  const otherTokenAddress = useAppSelector(
    selectEnvelopeFormField('otherTokenAddress'),
  ) as string;

  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });

  const commonToken =
    chainId === 56 ? commonTokenOnMainnet : commonTokenOnTestNet;

  const findCommonToken = (address: string) =>
    Object.values(commonToken).find(t => t.address === address);

  // Set state if exists when component on mount
  useEffect(() => {
    (async () => {
      const currency = fieldState['currency'];
      const amountOnItem = fieldState['amountOnItem'];

      setAmountOnItem(amountOnItem);

      if (!currency) return;

      const token = findCommonToken(currency);

      if (token) {
        return setToken(token);
      }

      if (!library || currency === ADDRESS_ZERO) return;

      setIsOtherToken(true);
      try {
        setIsFetchingTokenInfo(true);

        const metamaskToken = await fetchingTokenInfo(currency);
        setToken(metamaskToken);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetchingTokenInfo(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isSubmitClicked || !fieldState['currency']) return;

      try {
        await props.onCreateCampaign();
      } catch (error) {
        console.log(error);
      } finally {
        setIsSubmitClicked(false);
      }
    })();
  }, [isSubmitClicked, fieldState['currency']]);

  const handleSelectTokenChange = (ev: any) => {
    setAmountOnItem(0);
    if (ev.target.name == 'currency' && ev.target.value == 'other') {
      setToken(ZERO_TOKEN);
      setIsOtherToken(true);
      return;
    }

    setIsOtherToken(false);

    if (!ev.target.value.length) {
      return setToken(ZERO_TOKEN);
    }

    const tokenAddr = commonToken[ev.target.value]?.address;
    const token = findCommonToken(tokenAddr);
    if (!token) {
      return setToken(ZERO_TOKEN);
    }

    setToken(token);
  };

  const handleFetchingOtherTokenInfo = async () => {
    if (!otherTokenAddress || !library) return;

    try {
      setIsFetchingTokenInfo(true);
      const token = await fetchingTokenInfo(otherTokenAddress);
      setToken(token);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Địa chỉ Token không hợp lệ!',
        status: 'error',
      });
    } finally {
      setIsFetchingTokenInfo(false);
    }
  };

  const handleUpdateFieldState = () => {
    const currency =
      otherTokenAddress || (token ? token.address : ADDRESS_ZERO);
    dispatch(
      setFields({
        currency,
        amountOnItem: currency === ADDRESS_ZERO ? 0 : amountOnItem,
      }),
    );
  };

  const onGoBack = () => {
    handleUpdateFieldState();
    props.goBack();
  };

  const handleSubmit = () => {
    handleUpdateFieldState();
    setIsSubmitClicked(true);
  };

  const currency = fieldState['currency'];
  const commonTkSymbol = findCommonToken(currency);
  const defaultSelectVal = !currency
    ? undefined
    : commonTkSymbol
    ? commonTkSymbol.symbol
    : 'other';

  return (
    <>
      <CardInfo
        heading="Thể lệ tham gia Jackpot"
        content={[
          'Mỗi bao LixiNFT có kèm 1 vé sổ xố kiến thiết miền Mê(taverse), với giá 0.01 BNB (tương đương 10,000 VND tại thời điểm 8/2/2022). Bạn cần giữ NFT trong ví để có thể tham gia sổ xố.',
        ]}
      />
      <FormLabel
        htmlFor="currency"
        fontSize="20px"
        fontWeight="600"
        marginTop="32px"
      >
        Đơn vị tiền
      </FormLabel>
      <Select
        name="currency"
        bg="white"
        placeholder="Chọn ..."
        color="darkBg"
        defaultValue={defaultSelectVal}
        onChange={handleSelectTokenChange}
      >
        {Object.keys(commonToken).map(k => (
          <option key={k} value={k}>
            {k}
          </option>
        ))}
        <option value="other">Loại khác</option>
      </Select>
      {isOtherToken && (
        <FormControl>
          <FormLabel
            htmlFor="otherTokenAddress"
            fontSize="20px"
            fontWeight="600"
            marginTop="32px"
          >
            Nhập địa chỉ Token
          </FormLabel>
          <div style={{ display: 'flex' }}>
            <Input
              name="otherTokenAddress"
              type="text"
              bg="white"
              color="darkBg"
              required
              value={otherTokenAddress || ''}
              onChange={ev =>
                dispatch(
                  setField({
                    name: 'otherTokenAddress',
                    value: ev.target.value,
                  }),
                )
              }
            />
            <NotchedButton
              bgColor="primary"
              color="white"
              width="50%"
              marginLeft="8px"
              isLoading={isFetchingTokenInfo}
              loadingText="Loading token..."
              onClick={handleFetchingOtherTokenInfo}
            >
              Xác nhận
            </NotchedButton>
          </div>
        </FormControl>
      )}
      <FormControl isDisabled={token.address === ADDRESS_ZERO}>
        <FormLabel
          htmlFor="amountOnItem"
          fontSize="20px"
          fontWeight="600"
          marginTop="32px"
        >
          Số {token.address !== ADDRESS_ZERO ? token.symbol : 'tiền'} trong 1
          bao
        </FormLabel>
        <NumberInput
          defaultValue={0}
          value={amountOnItem}
          onChange={val => setAmountOnItem(parseFloat(val || '0'))}
        >
          <NumberInputField
            name="amountOnItem"
            min={0}
            type="number"
            bg="white"
            color="darkBg"
          />
        </NumberInput>
      </FormControl>
      <PaymentSummary
        heading="Tổng thanh toán"
        lixiAmount={fieldState['numberItem']}
        giftToken={amountOnItem}
        giftTokenSymbol={token?.symbol}
      />
      <ButtonGroup w="full" flexDirection="column-reverse">
        <NotchedButton
          bgColor="primary"
          color="white"
          marginTop="5"
          width="100%"
          onClick={onGoBack}
        >
          Quay lại
        </NotchedButton>
        <NotchedButton
          bgColor="primary"
          color="white"
          marginTop="5"
          marginLeft="0 !important"
          width="100%"
          onClick={handleSubmit}
        >
          Thanh toán
        </NotchedButton>
      </ButtonGroup>
    </>
  );
};

export default ExtraTokenForm;
