import React from 'react';
import { Rules } from '../components/RulesAndInstructures/Rules';
import { Box, Heading } from '@chakra-ui/react';
import { CreateInstruction } from '../components/RulesAndInstructures/CreateInstruction';
import { GetInstruction } from '../components/RulesAndInstructures/GetInstruction';
import { JoinJackpot } from '../components/RulesAndInstructures/JoinJackpot';

export const RulesAndInstructions = () => {
  return (
    <Box paddingBottom="40px" paddingTop={{ base: '4.5rem', md: '8rem' }}>
      <Rules />
      <Heading
        color="white"
        textAlign="center"
        marginBottom={{ base: '24px', md: '42px' }}
        fontFamily="headingFont"
        fontSize={{ base: '48px', md: '84px' }}
        fontWeight="700"
        marginTop="101px"
      >
        Hướng dẫn sử dụng LixiNFT.me
      </Heading>
      <CreateInstruction />
      <GetInstruction />
      <JoinJackpot />
    </Box>
  );
};
