import { getGroupInfo, GroupInfo } from '@web3react';
import { useEffect, useState } from 'react';

export default (groupId: number | undefined | null) => {
  const [groupInfo, setGroupInfo] = useState<GroupInfo | undefined>();
  const [error, setError] = useState<Error | boolean>(false);

  useEffect(() => {
    const fetchGroupInfo = async () => {
      if (!groupId || groupInfo) {
        return;
      }

      try {
        const fetchedgroupInfo = await getGroupInfo(groupId);
        setGroupInfo(fetchedgroupInfo);
      } catch (error) {
        console.log(error);
        setError(error as Error);
      }
    };

    fetchGroupInfo();
  }, [groupId]);

  return {
    groupInfo,
    error,
  };
};
