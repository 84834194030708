import { ClientError } from 'graphql-request';

export function getClientErrorMessage(error: ClientError) {
  const { name, message, stack, request, response } = error;
  const meta = { request, response };

  const { errors } = response;
  const isServerThrown = Array.isArray(errors) && errors.length;

  if (isServerThrown) {
    const { message } = errors[0];
    return { error: { name, message, stack: undefined }, meta };
  }

  return { error: { name, message, stack }, meta };
}
