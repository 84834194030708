import { Campaign, campaignApi } from '@services';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '@store';

const campaignsAdapter = createEntityAdapter<Campaign>({
  selectId: campaign => campaign._id,
});

const slice = createSlice({
  name: 'joinedCampaigns',
  initialState: campaignsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      campaignApi.endpoints.getJoinedCampaigns.matchFulfilled,
      (state, { payload }) => {
        if (!payload || !payload.getJoinedCampaigns) {
          return;
        }
        campaignsAdapter.setAll(state, payload.getJoinedCampaigns.campaigns);
      },
    );
  },
});

export default slice.reducer;

const globalizedSelectors = campaignsAdapter.getSelectors<RootState>(
  state => state.joinedCampaigns,
);

// This selector already knows how to find the books entity state
export const getAllJoinedCampaigns = (state: RootState) =>
  globalizedSelectors.selectAll(state);

export const getJoinedCampaignById = (
  state: RootState,
  campaignId: string | undefined,
) => {
  if (!campaignId) {
    return null;
  }
  return globalizedSelectors.selectById(state, campaignId);
};
