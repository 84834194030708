import React from 'react';
import { Box, Circle, Flex, Text } from '@chakra-ui/react';

interface ICardRuleProps {
  index: number;
  title: string;
  description: string;
}

export const CardRule: React.FC<ICardRuleProps> = (props: ICardRuleProps) => {
  return (
    <Box
      marginX={{ base: '24px', md: '40px' }}
      paddingTop="40px"
      _last={{ paddingBottom: '40px' }}
    >
      <Flex alignItems="center" flexDirection={{ base: 'column', sm: 'row' }}>
        <Circle
          size="40px"
          color="white"
          background="primary"
          fontFamily="rootFont"
          fontWeight="700"
          fontSize="18px"
          mb={{ base: '.5rem', sm: '0' }}
        >
          {props.index}
        </Circle>
        <Text
          fontFamily="rootFont"
          fontSize="20px"
          fontWeight="600"
          color={'darkBg'}
          marginLeft={{ base: '0', sm: '16px' }}
          mb={{ base: '.5rem', sm: '0' }}
        >
          {props.title}
        </Text>
      </Flex>
      <Text
        fontFamily="rootFont"
        fontSize="16px"
        color="darkBg"
        marginLeft={{ base: '0', sm: '56px' }}
      >
        {props.description}
      </Text>
    </Box>
  );
};
