import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from '../../constants';

export interface UploadResponse {
  ETag: string;
  Location: string;
  key: string;
  Key: string;
  Bucket: string;
}

export interface UploadArgs {
  folder?: string;
  body: FormData;
}

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: build => ({
    upload: build.mutation<UploadResponse, UploadArgs>({
      query: ({ folder, body }) => ({
        url: `upload${folder ? `?folder=${folder}` : ''}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useUploadMutation } = uploadApi;
