import {
  Box,
  BoxProps,
  Divider,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { round } from 'lodash';

export const PaymentSummaryItem = (props: {
  leftText: string;
  rightText: string;
}) => {
  return (
    <HStack w="100%" justifyContent="space-between">
      <Box maxW="60%">
        <Text opacity="60%" fontSize={{ base: '14px', lg: '16px' }}>
          {props.leftText}
        </Text>
      </Box>
      <Box textAlign="right">
        <Text fontWeight="600" fontSize={{ base: '16px', lg: '18px' }}>
          {props.rightText}
        </Text>
      </Box>
    </HStack>
  );
};

const formatNumToStr = (num: number) =>
  Number.isInteger(num) && num > 0 && num < 10 ? `0${num}` : num;

export const PaymentSummary: React.FC<
  BoxProps & {
    heading: string;
    lixiAmount: number;
    giftToken?: number;
    giftTokenSymbol?: string;
  }
> = ({
  heading,
  lixiAmount,
  giftToken = 'Nhập ở bước 3',
  giftTokenSymbol = '',
}) => {
  const lixiNFT = (num: number): number => {
    return num * 0.01;
  };

  const calculateSum = (amount: number, giftToken?: number) => {
    const sum: number = amount * 0.01 * 2;
    if (giftToken) {
      return (
        formatNumToStr(sum) +
        ' BNB + ' +
        `${formatNumToStr(amount * giftToken)} ${giftTokenSymbol}`
      );
    }
    return formatNumToStr(sum) + ' BNB';
  };

  const getGiftTokenVal = (giftToken: string | number, percent = 1) =>
    typeof giftToken === 'string'
      ? giftToken
      : formatNumToStr(round(giftToken * percent, 5));

  return (
    <Box
      w="full"
      bg="lightBg"
      color="black"
      pr={{ base: '1rem', md: '1.5rem' }}
      pl={{ base: '1rem', md: '1.5rem' }}
      pt={{ base: '1.5rem', md: '2rem' }}
      pb={{ base: '1.5rem', md: '2rem' }}
      mt="20px"
    >
      <VStack alignItems="flex-start" spacing="1rem">
        <Heading fontFamily="rootFont" fontSize="24px" fontWeight="700">
          {heading}
        </Heading>
        <Divider opacity="100%" />
        <PaymentSummaryItem
          leftText="Số bao lì xì:"
          rightText={`${formatNumToStr(lixiAmount)} bao`}
        />
        <Divider opacity="100%" />
        <PaymentSummaryItem
          leftText="Số token tặng mỗi bao:"
          rightText={`${getGiftTokenVal(giftToken, 0.8)} ${giftTokenSymbol}`}
        />
        <PaymentSummaryItem
          leftText="Tiền đóng góp vào Lottery nội bộ:"
          rightText={`${getGiftTokenVal(giftToken, 0.2)} ${giftTokenSymbol}`}
        />
        <Divider opacity="100%" />
        <PaymentSummaryItem
          leftText="Tiền LixiNFT:"
          rightText={`${formatNumToStr(lixiNFT(lixiAmount))} BNB`}
        />
        <PaymentSummaryItem
          leftText="Tiền đóng góp vào Jackpot:"
          rightText={`${formatNumToStr(lixiNFT(lixiAmount))} BNB`}
        />
        <Divider opacity="100%" />
        <PaymentSummaryItem
          leftText="Tổng:"
          rightText={`${
            typeof giftToken === 'string'
              ? calculateSum(lixiAmount)
              : calculateSum(lixiAmount, giftToken)
          }`}
        />
      </VStack>
    </Box>
  );
};

export default PaymentSummary;
