import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import jackpotExplain from '../../assets/JackpotExplain.jpeg';
import { NotchedButton } from '../NotchedButton';

export const JackpotExplain = () => {
  const navigate = useNavigate();
  return (
    <Box pb="10rem" pt="10rem" py="20" bg="white">
      <Flex
        align="center"
        justify={{ base: 'center', sm: 'space-around', xl: 'space-between' }}
        direction={{ base: 'column-reverse', sm: 'row' }}
        wrap="nowrap"
        flexDirection={{
          base: 'column',
          sm: 'row',
        }}
        py={{ base: '2', sm: '20' }}
        px="0"
        ml="auto"
        mr="auto"
        maxWidth="72rem"
      >
        <VStack
          width={{ base: '100%', sm: '50%' }}
          order={{ base: '2', sm: '1' }}
          align={{ base: 'center', sm: 'flex-start' }}
          textAlign={{ base: 'center', sm: 'left' }}
          p="1.5rem"
        >
          <Heading
            fontFamily="headingFont"
            fontSize="64px"
            color="darkBg"
            marginBottom="10px"
          >
            Tham gia xổ số
          </Heading>

          <Text
            color="secondary"
            fontSize="16px"
            fontWeight={600}
            maxW={{ base: 'none', sm: '30rem', lg: '48rem' }}
          >
            Khi tạo hoặc nhận lì xì trên LixiNFT, bạn đều có cơ hội được tham
            gia quay thưởng xổ số trên hệ thống. Sẽ có 2 hình thức quay thưởng
            mà bạn cần biết: Xổ số nội bộ & xổ số tập thể.
          </Text>
          <NotchedButton
            onClick={() => {
              navigate('/the-le');
            }}
            color="white"
            bgColor="primary"
            mt="48px!important"
            p="5"
          >
            Tìm hiểu thêm
          </NotchedButton>
        </VStack>
        <Box
          width={{ base: '100%', sm: '50%' }}
          order={{ base: '1', sm: '2' }}
          p="1.5rem"
        >
          <Box
            height="0px"
            width="100%"
            pb="65%"
            borderRadius="12px"
            position="relative"
            background="linear-gradient(180deg, #FFF8C5 0%, #FFE840 100%)"
          >
            <Image
              src={jackpotExplain}
              borderRadius="12px"
              position="absolute"
              right="10px"
              bottom="10px"
              width="100%"
              height="100%"
              fit="cover"
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
