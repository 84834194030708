import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WalletState {
  address?: string;
  balance?: number;
  network?: string;
  symbol?: string;
}

const setPropertyFactory =
  (property: 'address' | 'network' | 'symbol') =>
  (
    state: WalletState | null,
    { payload }: PayloadAction<string | undefined>,
  ) => {
    state = state || {};
    state[property] = payload;
  };

const slice = createSlice({
  name: 'wallet',
  initialState: null as WalletState | null,
  reducers: {
    setWallet: (state, { payload }: PayloadAction<WalletState | null>) => {
      if (!payload) return payload;

      return {
        ...state,
        ...payload,
      };
    },
    setAddress: setPropertyFactory('address'),
    setNetwork: setPropertyFactory('network'),
    setSymbol: setPropertyFactory('symbol'),
    setBalance: (state, { payload }: PayloadAction<number>) => {
      state = state || {};
      state.balance = payload;
    },
  },
});

export default slice.reducer;

export const { setAddress, setBalance, setNetwork, setSymbol, setWallet } =
  slice.actions;

export const selectWallet = ({ wallet }: RootState) => wallet;
export const selectAddress = ({ wallet }: RootState) => (wallet || {}).address;
export const selectNetwork = ({ wallet }: RootState) => (wallet || {}).network;
export const selectBalance = ({ wallet }: RootState) => (wallet || {}).balance;
export const selectSymbol = ({ wallet }: RootState) => (wallet || {}).symbol;
