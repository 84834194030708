import { Box, Button, Link, useTheme } from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
import { BSC_SCAN, WINNING_HASH } from '@constants';
import React from 'react';

const WinningHashSuccess = () => {
  const theme = useTheme();

  return (
    <Box width={{ base: '90%', md: '473px' }} marginX="auto" py={1}>
      <Button
        bg="primary"
        width={{ base: '100%', md: '50%' }}
        minH="3.75rem"
        lineHeight={{ base: '2em', md: '3em' }}
        color="white"
        _hover={{ bg: darken(theme.colors['primary'], 15) }}
        _active={{ bg: darken(theme.colors['primary'], 20) }}
      >
        <Link href={`${BSC_SCAN}/tx/${WINNING_HASH}`} isExternal>
          Xem hạt giống xổ số
        </Link>
      </Button>
    </Box>
  );
};

export default WinningHashSuccess;
