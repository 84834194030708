export function blobToBase64(
  blob: Blob | null,
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    if (!blob) {
      return reject('Blob is nil');
    }

    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
