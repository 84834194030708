/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import { useAuth } from '@hooks';
import { botSendEvent, botSendMessage, botShow, BotSupportType } from '@utils';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { EnvelopeForm } from '../components/form/EnvelopeForm';

export const CreateEnvelope: React.FC = () => {
  const { hasLogin } = useAuth();

  if (!hasLogin) {
    return <Navigate to="/profile" />;
  }

  useEffect(() => {
    // MARK:- show guidance in Bot chat
    botShow();
    (window as any).addEventListener('message', event => {
      if (event.data.name === 'webchatReady') {
        botSendEvent(BotSupportType.UPDATE_CONTEXT, {
          context: 'tao-lixi-s1',
        });
        botSendMessage('Tạo lì xì như thế nào?');
      }
    });
  }, []);

  return (
    <Box
      fontFamily="rootFont"
      width="100%"
      paddingTop={{ base: '4.5rem', md: '8rem' }}
    >
      <EnvelopeForm />
    </Box>
  );
};
