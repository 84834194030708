import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { balanceOf, tokenOfOwnerByIndex } from '@web3react';
import { useState } from 'react';
import { useAuth } from './useAuth';

export const MAX_NUMBER_OF_TICKETS = 6;

/**
 *
 * @param {boolean} `isFetchAll` whether fetch all the token id or just fetch first 6 token ids
 * @returns {Object} an object contains `error`, `isLoading`, `tokenIds` and the `fetchTokenIds` function
 */
const useFetchTokenIds = (isFetchAll = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean | Error>(false);
  const [tokenIds, setTokenIds] = useState<number[]>([]);

  const { signIn, hasLogin } = useAuth();

  const { library, account } = useWeb3React<Web3Provider>();

  const fetchTokenIds = async () => {
    if (!hasLogin || !account || !library) {
      await signIn();
      return;
    }

    try {
      setIsLoading(true);
      const balance = await balanceOf(account, library);

      const _numberOfTickets = isFetchAll
        ? balance
        : balance > MAX_NUMBER_OF_TICKETS
        ? MAX_NUMBER_OF_TICKETS
        : balance;

      const tokenIds = await Promise.all(
        Array(_numberOfTickets)
          .fill(0)
          .map((_, idx) => {
            return tokenOfOwnerByIndex(account, idx, library);
          }),
      );

      if (balance > MAX_NUMBER_OF_TICKETS) {
        tokenIds.push(...Array(balance - MAX_NUMBER_OF_TICKETS).fill(0));
      }

      setTokenIds(tokenIds);
      return tokenIds;
    } catch (error) {
      console.log(error);
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    fetchTokenIds,
    isLoading,
    tokenIds,
  };
};

export default useFetchTokenIds;
