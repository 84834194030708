import { Flex, Spinner, SpinnerProps } from '@chakra-ui/react';
import React from 'react';

export const Loading: React.FunctionComponent<SpinnerProps> = props => {
  return (
    <Flex w="full" justifyContent="center" py={20}>
      <Spinner size="xl" {...props} color="primary" />
    </Flex>
  );
};

export default Loading;
