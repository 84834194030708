import { useState } from 'react';

export const useFormStep = (maxStep: number) => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (step === maxStep) return;

    setStep(prev => prev + 1);
  };

  const goBack = () => {
    if (step === 1) return;

    setStep(prev => prev - 1);
  };

  return { step, nextStep, goBack, maxStep };
};
