import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { Title, Step, SecondTitle } from './InstructionTemplate';
import createEnv from '@assets/createEnv.png';

export const CreateInstruction = () => {
  return (
    <Box
      bg="white"
      width={{ base: '90%', sm: '80%', md: '70%' }}
      marginX="auto"
      borderRadius="12px"
      padding={{ base: '24px', md: '40px' }}
    >
      <Title title="Tạo bao lì xì" />
      <Box marginTop="32px">
        <Box marginBottom="40px">
          <Step item="1" />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Đầu tiên để tạo được lì xì NFT, bạn cần phải kết nối ví Metamask vào
            hệ thống của Lixi NFT. Sau khi đã kết nối thành công ví metamask hệ
            thống sẽ tự động tạo cho bạn một tài khoản, bạn có thể tiến hành
            thiết lập ảnh đại diện và thông tin tài khoản.
          </Text>
        </Box>
        <Box marginBottom="40px">
          <Step item="2" />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Sau khi đã có tài khoản, bạn có thể tạo bao lì xì NFT bằng cách
            click vào <b>Tạo lì xì</b>. Tại màn tạo lì xì, sẽ có 4 nội dung mà
            bạn cần cung cấp bao gồm:{' '}
            <b>Tên bao lì xì, Mô tả về bao lì xì, ảnh logo bao lì xì</b> và{' '}
            <b>hashtag</b>
          </Text>
          <Image marginX="auto" marginTop="40px" src={createEnv} />
        </Box>
        <Box marginBottom="40px">
          <Step item="3" />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Sau khi đã hoàn thành xong những thông tin chung của bao lì xì, bạn
            sẽ tiếp tục được hỏi rằng muốn tạo bao nhiêu bao lì xì và ngày hết
            hạn bao lì xì đó. Nếu tạo từ 10 bao lì xì trở lên bạn sẽ được tặng
            thêm bonus LixiNFT dùng để tham gia Jackpot tập thể.
          </Text>
        </Box>
        <Box marginBottom="40px">
          <SecondTitle content="Chi tiết về bonus LixiNFT:" />
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            - Tạo từ <b>10</b> bao lì xì tặng <b>10%</b> tổng số bao
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            - Tạo từ <b>100</b> bao lì xì tặng <b>20%</b> tổng số bao
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            - Tạo từ <b>300</b> bao lì xì tặng <b>30%</b> tổng số bao
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Số bao lì xì tối đa bạn được tạo là 500 bao.
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            Ghi chú: Ngày hết hạn của bao lì xì sẽ cho người nhận được lì xì của
            bạn biết rằng hạn cuối cùng mà người đó cần vào nhận lì xì, nếu sau
            hạn mà vẫn chưa nhận thì lì xì đó sẽ vô hiệu hóa và tiền trong bao
            lì xì sẽ được chuyển tự động vào quỹ quay thưởng xổ số.
          </Text>
        </Box>
        <Box marginBottom="40px">
          <Step item="4" />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Sau khi tạo được xong bao lì xì, hệ thống sẽ gửi cho bạn một đường
            dẫn URL để chia sẻ. Bạn có thể dùng đường dẫn này để chia sẻ bao lì
            xì cho bạn bè, người thân hoặc bất kì ai mà bạn muốn lì xì. Tất cả
            những người nhận được đường dẫn chia sẻ đều có thể đăng ký vào nhận
            sách nhận lì xì. Sau đó, chủ bao lì xì có thể vào danh sách đăng ký
            nhận lì xì để xét duyệt gửi lì xì cho những người đang đăng ký.
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            Trong mỗi bao lì xì sẽ gồm có:
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            - Token tặng
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            - LixiNFT (đây sẽ là vé để người chơi tham gia quay thưởng xổ số tập
            thể)
          </Text>
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            - 0.01 BNB (mỗi lì xì sẽ mặc định có 0.01 BNB chính là phí gas phải
            trả khi bạn nhận lì xì)
          </Text>
        </Box>
        <Box>
          <SecondTitle content="Giá của bao lì xì được tính như thế nào?" />
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            color="darkBg"
          >
            Tổng giá trị khi tạo bao lì xì sẽ được tính như sau:{' '}
            <b>Số token tặng x số bao lì xì + phí gas + Lixi NFT</b>
            <br />
            <br />
            Trong đó:
            <br />
            <br />
            Phí gas = <b>0.01BNB x Số bao lì xì</b>
            <br />
            <br />
            Lixi NFT = <b>0.01BNB x Số bao lì xì</b> (Số tiền này sẽ được đóng
            góp vào trong quỹ thưởng xổ số tập thể)
            <br />
            <br />
            Ngoài ra, 20% số token mà bạn bỏ vào bao lì xì sẽ được trích ra để
            chuyển vào quỹ thưởng xổ số nội bộ
            <br />
            <br />
            Ví dụ: Bạn tạo 10 bao lì xì và bỏ vào mỗi bao lì xì 10 Bitcoin. Vậy
            số tiền bạn phải trả là:
            <br />
            <br />
            <b>
              10 Bitcoin x 10 + 0.01BNBx10 + 0.01BNBx10 = 100 Bitcoin + 0.2BNB
            </b>
          </Text>
          <br />
          <Text
            fontFamily="rootFont"
            fontSize="16px"
            fontWeight="400"
            fontStyle="italic"
            color="primary"
          >
            Ngoài ra: Vì mỗi bao lì xì sẽ tự động trích 20% số token tặng để
            chuyển vào trong lottery nội bộ nên mỗi bao lì xì sẽ có 8 Bitcoin để
            tặng. 20 bitcoin được trích ra sẽ là giải thưởng của xổ số nội bộ.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
