import { Text } from '@chakra-ui/react';
import React from 'react';

export const Ticket: React.FC<{ ticketValue: number | string }> = ({
  ticketValue,
}) => {
  return (
    <Text
      fontSize={{ base: '40px', md: '63px' }}
      fontFamily="headingFont"
      fontWeight="700"
      flexBasis="33.3333%"
    >
      {ticketValue}
    </Text>
  );
};

export default Ticket;
