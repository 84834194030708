import { Box, Button, useToast } from '@chakra-ui/react';
import { selectTicketValue, setTicket } from '@features';
import { useAppDispatch, useAppSelector, useFetchTokenIds } from '@hooks';
import React, { useEffect } from 'react';
import Tickets from './Tickets';
import WinningHashSuccess from './WinningHashSuccess';

export const MAX_NUMBER_OF_TICKETS = 6;

export const TicketsCard = () => {
  const dispatch = useAppDispatch();
  const tickets = useAppSelector(selectTicketValue);

  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'bottom',
  });

  const { error, fetchTokenIds, isLoading, tokenIds } = useFetchTokenIds();

  useEffect(() => {
    if (!isLoading && !error && !tickets?.length && tokenIds.length) {
      dispatch(setTicket(tokenIds));
    }

    if (!isLoading && error) {
      let message = 'Oops, có lỗi xảy ra khi kiểm tra vé số';

      if (error instanceof Error) {
        message = error.message;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const connErrorMessage = (error as any)?.data?.message;
      if (connErrorMessage) {
        message = connErrorMessage;
      }

      toast({
        title: 'Lỗi',
        description: message,
        status: 'error',
      });
    }
  }, [isLoading, error, tickets, tokenIds]);

  const onClick = () => {
    fetchTokenIds();
  };

  return (
    <Box py={3}>
      {!tickets ? (
        <Button
          maxW={{ base: '90%', md: '100%' }}
          height="MAX_NUMBER_OF_TICKETS0px"
          lineHeight={{ base: '2em', md: '3em' }}
          bg="primary"
          color="white"
          fontFamily="rootFont"
          fontWeight="600"
          fontSize={{ base: '16px', md: '18px' }}
          isLoading={isLoading}
          onClick={onClick}
          whiteSpace="pre-wrap"
          mb="1rem"
        >
          Kiểm tra vé xổ số trong ví của tôi
        </Button>
      ) : (
        <Tickets tickets={tickets} />
      )}
      <WinningHashSuccess />
    </Box>
  );
};

export default TicketsCard;
