import { Token } from '@app/services';
import { getERC20Contract } from '.';

export interface MetamaskToken extends Omit<Token, '_id'> {
  decimals: number;
}

const fallbackLogoUrl =
  'https://lixinft.s3.ap-southeast-1.amazonaws.com/token-coloured.svg';

export const fetchingTokenInfo = async (
  addressOrToken: string | Token,
): Promise<MetamaskToken> => {
  if (typeof addressOrToken === 'string') {
    const ERC20Contract = getERC20Contract(addressOrToken);

    const [symbol, decimals] = await Promise.all([
      ERC20Contract.symbol(),
      ERC20Contract.decimals(),
    ]);

    return {
      symbol,
      decimals,
      address: addressOrToken,
      logo: fallbackLogoUrl,
    };
  } else {
    const ERC20Contract = getERC20Contract(addressOrToken.address);
    const decimals = await ERC20Contract.decimals();

    return {
      ...addressOrToken,
      decimals,
    };
  }
};

export const addToken = async (token: MetamaskToken) => {
  try {
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.logo,
        },
      },
    });

    if (wasAdded) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
