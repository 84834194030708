import { useGetCampaignQuery } from '@app/services';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Center, Heading, Link, useTheme, VStack } from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
import { NotchedButton } from '@components';
import { BASE_URL } from '@constants';
import { useMobileDetect } from '@hooks';
import { generateDappUrl } from '@utils';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Loading } from './Loading';
import { NotFound } from './NotFound';

export const ShareCampaign: React.FC = () => {
  const [campaignId, setCampaignId] = useState('');
  const { shareId } = useParams();
  if (!shareId) {
    return null;
  }

  const { refetch, isLoading, data } = useGetCampaignQuery(shareId);

  const { isMobile } = useMobileDetect();

  const theme = useTheme();

  useEffect(() => {
    if (shareId) {
      refetch();
    }
  }, [shareId]);

  useEffect(() => {
    if (data) {
      const {
        getCampaign: { _id },
      } = data;
      setCampaignId(_id);
    }
  }, [data]);

  const metamaskDeeplink = generateDappUrl(`${BASE_URL}/bao/${campaignId}`);

  const ShareLink = () => (
    <Center width="full" height="78vh">
      <VStack w={{ base: '50%', md: '33.33%' }} spacing="10">
        <Heading
          fontFamily="rootFont"
          color="white"
          textTransform="capitalize"
          fontSize="3xl"
          textAlign="center"
          p="5"
        >
          Để Truy Cập, Hãy Mở Trang Bằng Ví Metamask
        </Heading>
        <NotchedButton
          bg="primary"
          color={'white'}
          w="full"
          _hover={{
            bg: darken(theme.colors['primary'], 15),
          }}
          _active={{
            bg: darken(theme.colors['primary'], 20),
          }}
        >
          <Link
            href={metamaskDeeplink}
            fontSize={{ base: 'sm', md: 'md' }}
            color="white"
            isExternal
          >
            Mở trang bằng metamask <ExternalLinkIcon mx="2px" />
          </Link>
        </NotchedButton>
      </VStack>
    </Center>
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : !campaignId || !data ? (
        <NotFound />
      ) : !isMobile() ? (
        <Navigate to={`/bao/${campaignId}`} />
      ) : (
        <ShareLink />
      )}
    </>
  );
};

export default ShareCampaign;
