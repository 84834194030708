import { AddIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';
import React from 'react';

export const FAQ = () => {
  return (
    <Box bg="white" paddingBottom="20px">
      <Heading
        fontFamily="headingFont"
        fontSize="64px"
        color="darkBg"
        textAlign="center"
        marginTop="120px"
        marginBottom="48px"
      >
        Những câu hỏi thường gặp
      </Heading>
      <Accordion allowMultiple>
        <AccordionItem width={{ base: '80%', md: '650px' }} mx="auto">
          <Box
            bg="darkBg"
            color="white"
            padding="20px 24px"
            borderRadius="5px"
            marginBottom="15px"
          >
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="600">
                <Text>NFT là gì?</Text>
              </Box>
              <AddIcon
                display={{ base: 'none', md: 'block' }}
                fontSize="16px"
              />
            </AccordionButton>
          </Box>
          <AccordionPanel pb={4}>
            <Text>
              NFT là cụm từ viết tắt của Non-Fungible Token được gọi một cách
              khá học thuật là Tài sản không thể thay thế hoặc những tài sản
              không có giá trị thay thế lẫn nhau. NFT để ám chỉ những tài sản số
              đã được mã hóa trên hệ thống của blockchain. (
              <a href="https://lixinft.me/nft-la-gi">
                https://lixinft.me/nft-la-gi
              </a>
              )
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem width={{ base: '80%', md: '650px' }} mx="auto">
          <Box
            bg="darkBg"
            color="white"
            padding="20px 24px"
            borderRadius="5px"
            marginBottom="15px"
          >
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="600">
                <Text>Kết nối ví Metamask như thế nào?</Text>
              </Box>
              <AddIcon
                display={{ base: 'none', md: 'block' }}
                fontSize="16px"
              />
            </AccordionButton>
          </Box>
          <AccordionPanel pb={4}>
            <Text>
              Metamask là một nền tảng lưu trữ tiền điện tử, nói một khác dễ
              hiểu, nền tảng này là một loại ví điện tử. Ban đầu, ví điện tử
              được thiết lập để tương thích với chuỗi khối Ethereum. Sau này, ví
              Metamask được cải tiến để tích hợp với nhiều blockchain khác nhau.
              (<a href="https://metamask.io/">https://metamask.io/</a>)
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem width={{ base: '80%', md: '650px' }} mx="auto">
          <Box
            bg="darkBg"
            color="white"
            padding="20px 24px"
            borderRadius="5px"
            marginBottom="15px"
          >
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="600">
                <Text>Không có tiền trong ví có thể nhận lì xì hay không?</Text>
              </Box>
              <AddIcon
                display={{ base: 'none', md: 'block' }}
                fontSize="16px"
              />
            </AccordionButton>
          </Box>
          <AccordionPanel pb={4}>
            <Text>
              Có. Bạn có thể làm 1 trong 2 cách sau: 1. xin bạn bè 1 ít BNB
              (0.01 là đủ), rồi vào claim lixi. 2. nhờ chủ bao lì xì GỬI li xì
              cho bạn qua giao diện trang web (tiền gas sẽ trừ vào ví của người
              gửi).
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem width={{ base: '80%', md: '650px' }} mx="auto">
          <Box
            bg="darkBg"
            color="white"
            padding="20px 24px"
            borderRadius="5px"
            marginBottom="15px"
          >
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="600">
                <Text>Làm sao để có BNB/Gas?</Text>
              </Box>
              <AddIcon
                display={{ base: 'none', md: 'block' }}
                fontSize="16px"
              />
            </AccordionButton>
          </Box>
          <AccordionPanel pb={4}>
            <Text>
              Các bạn có thể mua tiền điện tử tại các sàn giao dịch như Binance
              hay Remitano. Bạn cần đăng ký tài khoản và xác thực thông tin cá
              nhân. Nếu dùng Binance, bạn có thể đăng ký{' '}
              <a href="https://accounts.binance.com/en/register?ref=TQUXXPP3">
                ở đây{' '}
              </a>
              để nhận 10% chiết khấu từ phí giao dịch mà sàn sẽ thu của bạn. Bạn
              cũng nên tham khảo luật pháp ở địa phương của bạn để bảo đảm tuân
              thủ các quy định pháp lý.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem width={{ base: '80%', md: '650px' }} mx="auto">
          <Box
            bg="darkBg"
            color="white"
            padding="20px 24px"
            borderRadius="5px"
            marginBottom="15px"
          >
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="600">
                <Text>Kết nối ví Metamask như thế nào?</Text>
              </Box>
              <AddIcon
                display={{ base: 'none', md: 'block' }}
                fontSize="16px"
              />
            </AccordionButton>
          </Box>
          <AccordionPanel pb={4}>
            <Text>
              Metamask là một nền tảng lưu trữ tiền điện tử, nói một khác dễ
              hiểu, nền tảng này là một loại ví điện tử. Ban đầu, ví điện tử
              được thiết lập để tương thích với chuỗi khối Ethereum. Sau này, ví
              Metamask được cải tiến để tích hợp với nhiều blockchain khác nhau.
              (<a href="https://metamask.io/">https://metamask.io/</a>)
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem width={{ base: '80%', md: '650px' }} mx="auto">
          <Box
            bg="darkBg"
            color="white"
            padding="20px 24px"
            borderRadius="5px"
            marginBottom="15px"
          >
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="600">
                <Text>Gas Fee là gì? Tại sao tôi phải trả Gas Fee?</Text>
              </Box>
              <AddIcon
                display={{ base: 'none', md: 'block' }}
                fontSize="16px"
              />
            </AccordionButton>
          </Box>
          <AccordionPanel pb={4}>
            <Text>
              Gas là đơn vị đo tài nguyên tính toán cần thiết để tương tác với
              blockchain. Mỗi tương tác cần một lượng Gas nhất định, tùy thuộc
              vào loại hình tính toán và số lượng cũng như độ phức tạp của các
              phép tính toán, ngoài ra có thể kể đến yêu cầu lưu trữ. Vì các hợp
              đồng thông minh NFT có xu hướng chuyên sâu về mặt tính toán nên
              việc chuyển tiền đơn giản có thể đồng nghĩa với việc tốn nhiều
              nhiên liệu vận hành các bộ máy tính toán hơn. (
              <a href="https://www.binance.com/ph/blog/nft/understanding-the-transaction-fees-involved-in-buying-and-selling-nfts-421499824684902595">
                https://www.binance.com/ph/blog/nft/understanding-the-transaction-fees-involved-in-buying-and-selling-nfts-421499824684902595
              </a>
              )
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
