import { useOpenVerifyEmailMutation } from '@app/services';
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Heading, Spinner, VStack } from '@chakra-ui/react';
import { NotchedButton } from '@components';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EmailVerifyLoading = () => {
  return (
    <>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primary"
        size="xl"
      />
      <Heading textAlign="center" fontFamily="headingFont">
        Đang xác thực email...
      </Heading>
    </>
  );
};

const EmailVerifySuccess = () => {
  return (
    <>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading mt={6} mb={2} fontFamily="headingFont">
        Xác thực email thành công
      </Heading>
    </>
  );
};

const EmailVerifyFailure = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center"
        >
          <CloseIcon boxSize={'20px'} color={'white'} />
        </Flex>
      </Box>
      <Heading mx={6} fontFamily="headingFont">
        Xác thực email thất bại
      </Heading>

      <Box>
        <NotchedButton
          bg="primary"
          color="white"
          variant="solid"
          onClick={() => navigate('/')}
        >
          Về trang chủ
        </NotchedButton>
      </Box>
    </>
  );
};

export const EmailVerify = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [openVerifyEmail, { data, isLoading }] = useOpenVerifyEmailMutation();

  useEffect(() => {
    (async () => {
      if (!token) return;

      await openVerifyEmail(token).unwrap();
    })();
  }, [token]);

  return (
    <Center width="full" height="70vh">
      <VStack
        py={7}
        w={{ base: '50%', md: '33.33%' }}
        spacing="10"
        mt="-10"
        bg="whiteAlpha.900"
        borderRadius="3xl"
      >
        {isLoading ? (
          <EmailVerifyLoading />
        ) : data ? (
          data.openVerifyEmail.error ? (
            <EmailVerifyFailure />
          ) : (
            <EmailVerifySuccess />
          )
        ) : null}
      </VStack>
    </Center>
  );
};

export default EmailVerify;
