import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import {
  CreatingStage,
  getCreatingStageText,
  selectCurrentCreatingStage,
} from '@features';
import { useAppSelector } from '@hooks';
import React from 'react';
import { MotionLoading } from '../MotionLoading';

export const EnvelopeCreatingModal = () => {
  const stage = useAppSelector(selectCurrentCreatingStage);
  const isOpen =
    stage !== CreatingStage.SUCCESS &&
    stage !== CreatingStage.ERROR &&
    stage !== CreatingStage.IDLE;

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => console.log('Close')}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size="lg"
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent>
        <ModalBody>
          <VStack
            spacing="16"
            minH="35vh"
            justifyContent="center"
            alignItems="center"
          >
            <MotionLoading />
            <Heading fontFamily="rootFont" size="md">
              {getCreatingStageText(stage)}
            </Heading>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EnvelopeCreatingModal;
