/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Center,
  Heading,
  Stack,
  useTheme,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
import { EditableField, NotchedButton, WalletAddressField } from '@components';
import { selectCurrentUser } from '@features';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '@hooks';
import { authApi, useUploadMutation } from '@services';
import { botSendEvent, BotSupportType } from '@utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ProfileAvatarField } from '.';

export interface UpdateFormData {
  fullName: string;
  email: string;
}

const schema = yup
  .object({
    fullName: yup.lazy(value =>
      typeof value === 'string' && value.length > 0
        ? yup.string().min(3).max(60)
        : yup.string().nullable(),
    ),
    email: yup.lazy(value =>
      typeof value === 'string' && value.length > 0
        ? yup.string().email()
        : yup.string().nullable(),
    ),
  })
  .notRequired();

export const UpdateProfileContainer = () => {
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [walletAddress, setWalletAddress] = useState(
    '0x000000000000000000000000000000000000',
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [fileAvatar, setFileAvatar] = useState<File | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [unsubscribe, setUnsubscribe] = useState<() => void>(() => {});

  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const [upload] = useUploadMutation();

  const {
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    register,
    reset,
  } = useForm<UpdateFormData>({
    resolver: yupResolver(schema),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as { from: string };

  useEffect(() => {
    if (!user) return;

    reset({
      fullName: user.fullName,
      email: user.email,
    });

    botSendEvent(BotSupportType.UPDATE_USER_INFO, {
      fullName: user.fullName,
      email: user.email,
      walletAddress: user.walletAddress,
    });

    setWalletAddress(user.walletAddress as string);
    setAvatarUrl(user.avatar);
    setIsUpdating(false);
  }, [user]);

  // Unsubscribe update api when component unmount
  useEffect(() => {
    return unsubscribe;
  }, []);

  if (!user) {
    return (
      <Center p="28" textAlign="center">
        <Heading>Hãy đăng nhập bằng Metamask để tiếp tục nhé</Heading>
      </Center>
    );
  }

  const commonProps = {
    bg: 'white.100',
    color: 'darkBg',
    size: 'md',
    _placeholder: { color: 'gray.500' },
    type: 'text',
  };

  const updateUser = authApi.endpoints.updateUser.initiate;
  const isAvatarUpdated = avatarUrl && user.avatar !== avatarUrl;

  const uploadImage = async (imageFile: File) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);

      const payload = await upload({ body: formData }).unwrap();
      return payload.Location;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleFileAvatarChange = (file: File | null) => {
    setFileAvatar(file);
  };

  const handleAvatarUrlChange = (url: string) => {
    setAvatarUrl(url);
  };

  const onSubmit = async (data: UpdateFormData) => {
    const updatedFields = Object.keys(dirtyFields).reduce((prev, current) => {
      prev[current] = data[current];
      return prev;
    }, {});

    setIsUpdating(true);
    if (isAvatarUpdated && fileAvatar) {
      const url = await uploadImage(fileAvatar);
      updatedFields['avatar'] = url;
    }

    const result = dispatch(updateUser({ ...updatedFields }));
    setUnsubscribe(result.unsubscribe);

    result.then(response => {
      const { error } = response;

      if (!error) {
        toast({
          title: 'Success',
          description: 'Cập nhật thông tin thành công',
          status: 'success',
        });
        if (state?.from) {
          navigate(state.from, { replace: true });
        }
        return;
      }

      const message = error.message || 'Lỗi trong quá trình cập nhật';

      setIsUpdating(false);
      toast({
        title: 'Error',
        description: message,
        status: 'error',
      });
    });
  };

  return (
    <Stack w={{ base: '70%', md: '50%', xl: '33.33%' }} h="100%">
      <Heading
        fontSize={{ base: '5xl', lg: '6xl' }}
        fontFamily="headingFont"
        textAlign="center"
        mb={{ base: '2', md: '5' }}
      >
        Profile Của Bạn
      </Heading>
      <VStack spacing={8}>
        <ProfileAvatarField
          avatarUrl={avatarUrl}
          setAvatarUrl={handleAvatarUrlChange}
          setFile={handleFileAvatarChange}
        />
        <EditableField
          id="fullName"
          isInvalid={!!errors.fullName}
          label="Tên hiển thị"
          errorMessage={errors.fullName && errors.fullName.message}
          inputForwardProps={{ ...commonProps, placeholder: 'Tên của bạn' }}
          registerReturnObj={register('fullName')}
        />
        <EditableField
          id="email"
          isEmailVerified={user.email ? !!user.isEmailVerified : undefined}
          isInvalid={!!errors.email}
          label="Địa chỉ Email"
          errorMessage={errors.email && errors.email.message}
          inputForwardProps={{
            ...commonProps,
            placeholder: 'Email của bạn',
          }}
          registerReturnObj={register('email')}
        />
        <WalletAddressField
          value={walletAddress}
          inputForwardProps={{ ...commonProps }}
        />
        <NotchedButton
          bg="primary"
          color={'white'}
          w="full"
          _hover={{
            bg: darken(theme.colors['primary'], 15),
          }}
          _active={{
            bg: darken(theme.colors['primary'], 20),
          }}
          isDisabled={!isDirty && !isAvatarUpdated}
          isLoading={isUpdating}
          loadingText="Đang lưu"
          onClick={() => handleSubmit<UpdateFormData>(onSubmit)()}
        >
          Lưu
        </NotchedButton>
      </VStack>
    </Stack>
  );
};

export default UpdateProfileContainer;
