/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import previewLixi from '@assets/previewLixi.png';
import {
  Box,
  Flex,
  FormControl,
  Heading,
  Image,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';
import {
  CreatingStage,
  resetEnvelopeForm,
  selectCurrentCreatingStage,
  selectEnvelopeFormFields,
  selectEnvelopeFormLogoUri,
  setCreatingStage,
} from '@features';
import { useAppDispatch, useAppSelector, useFormStep } from '@hooks';
import { ADDRESS_ZERO, createCampaignOnSC, erc20DecimalsOnSc } from '@utils';
import { useWeb3React } from '@web3-react/core';
import { useApproveTokenListener, useMintLixiListener } from '@web3react';
import React, { useEffect, useState } from 'react';
import { EnvelopeCreatingModal } from '../EnvelopeCreatingModal';
import { EnvelopeWarningModal } from '../EnvelopeWarningModal';
import { Amount } from './Amount';
import { ExtraTokenForm } from './ExtraToken';
import { FormTitle } from './FormTitle';
import { GeneralForm } from './GeneralForm';

export const EnvelopeForm = () => {
  const dispatch = useAppDispatch();

  const fileUri = useAppSelector(selectEnvelopeFormLogoUri());
  const creatingStage = useAppSelector(selectCurrentCreatingStage);
  const fieldState = useAppSelector(selectEnvelopeFormFields());

  const { library, account } = useWeb3React<Web3Provider>();

  const { step, nextStep, goBack } = useFormStep(3);

  const [isCreateConfirmed, setIsCreateConfirmed] = useState(false);
  const [isCreateOnSCFired, setIsCreateOnSCFired] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const stage = useAppSelector(selectCurrentCreatingStage);

  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });

  // Listen mint lixi event
  useMintLixiListener();

  // Listen for token approval event
  const { isApproved, handleIsApprovedChange, checkingAllowance } =
    useApproveTokenListener();

  // // Clear redux when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetEnvelopeForm());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!isApproved || !library || isCreateOnSCFired) return;

      handleIsApprovedChange(false);
      setIsCreateOnSCFired(() => true);

      const { amountOnItem, numberItem, currency: extraToken } = fieldState;
      try {
        let extractDecimals: number | undefined;
        if (extraToken !== ADDRESS_ZERO) {
          extractDecimals = await erc20DecimalsOnSc(extraToken, library);
        }

        const amountOnItemWei = parseUnits(
          amountOnItem.toString(),
          extractDecimals,
        );

        dispatch(setCreatingStage(CreatingStage.WAITING_USER));
        await createCampaignOnSC(
          numberItem,
          extraToken,
          amountOnItemWei,
          [],
          library.getSigner(0),
          (numberItem * 0.01 * 2) as number,
        );
        dispatch(setCreatingStage(CreatingStage.CREATING_ON_SC));
      } catch (error) {
        dispatch(setCreatingStage(CreatingStage.IDLE));
        console.log(error);
        toast({
          title: 'Error',
          description: 'Oops, có gì đó hoạt động chưa đúng rồi',
          status: 'error',
        });
      }
    })();
  }, [isApproved, library, fieldState]);

  useEffect(() => {
    (async () => {
      if (!isCreateConfirmed) return;

      setIsCreateConfirmed(false);
      dispatch(setCreatingStage(CreatingStage.CHECKING));

      await checkingAllowance();
    })();
  }, [isCreateConfirmed]);

  return (
    <Box color="white" paddingBottom="160px">
      <Heading
        fontSize="84px"
        fontFamily="headingFont"
        textAlign="center"
        marginBottom="48px"
      >
        Tạo Lì Xì
      </Heading>
      <Flex
        justifyContent="space-around"
        flexDirection={{ base: 'column', md: 'row' }}
        maxWidth="64rem"
        mx="auto"
      >
        <Box
          width={{ base: '100%', md: '50%' }}
          pl={{ base: '1rem', md: '1.5rem' }}
          pr={{ base: '1rem', md: '1.5rem' }}
        >
          <FormTitle step={step} />
          <Box marginTop="69px">
            <FormControl>
              {step === 1 ? (
                <GeneralForm nextStep={nextStep} />
              ) : step === 2 ? (
                <Amount nextStep={nextStep} goBack={goBack} />
              ) : (
                <ExtraTokenForm goBack={goBack} onCreateCampaign={onOpen} />
              )}
            </FormControl>
          </Box>
        </Box>
        <Box
          width={{ base: '100%', md: '50%' }}
          pl={{ base: '1rem', md: '1.5rem' }}
          pr={{ base: '1rem', md: '1.5rem' }}
        >
          <Box
            width={{ base: '320px', lg: '480px' }}
            marginX="auto"
            position="sticky"
            top="4.5rem"
          >
            <Image
              marginX={{ base: 'auto', lg: '0' }}
              src={previewLixi}
              marginTop="4.5rem"
            />
            {fileUri ? (
              <Image
                position="absolute"
                transform="translateY(-4.5rem)"
                width={{ base: '70px', lg: '110px' }}
                borderRadius="50%"
                top={{ base: '301px', lg: '414px' }}
                left={{ base: '61px', lg: '88px' }}
                src={fileUri}
              />
            ) : null}
            <Box
              position="absolute"
              width="270px"
              top={{ base: '290px', lg: '455px' }}
              right={{ base: '-44px', lg: '5px' }}
              fontFamily="hashTagFont"
              fontSize={{
                base: fieldState.hashTag?.length < 11 ? '20px' : '15px',
                lg: fieldState.hashTag?.length < 11 ? '25px' : '20px',
              }}
              transform="rotate(-90deg) translateX(4.5rem)"
              color="#DAA74B"
            >
              {fieldState.hashTag ? fieldState.hashTag : ''}
            </Box>
          </Box>
        </Box>
      </Flex>

      {/** Warning Modal */}
      <EnvelopeWarningModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={() => {
          setIsCreateConfirmed(true);
          onClose();
        }}
      />

      {/** Creating modal */}
      <EnvelopeCreatingModal />
    </Box>
  );
};
