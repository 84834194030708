import { Web3Provider } from '@ethersproject/providers';
import { getLixiBagContract } from '@web3react';

export const setWinningHash = async (
  winningHash: string,
  library: Web3Provider,
) => {
  const contract = getLixiBagContract(library.getSigner(0), true);

  return contract.setWinningHash(winningHash);
};
