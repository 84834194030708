import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { GRAPHQL_BASE_URL } from '../../constants';
import { graphqlRequestBaseQuery } from '../../utils';
import { RootState } from '../store';

export enum SystemRole {
  USER = 'user',
  ADMIN = 'admin',
}

export interface User {
  _id?: string;
  fullName?: string;
  email?: string;
  bio?: string;
  avatar?: string;
  isEmailVerified?: boolean;
  emailVerifiedAt?: Date;
  role?: SystemRole;
  walletAddress?: string;
  createdAt?: Date;
  updatedAt?: Date;
  balanceInWei?: string;
}

export interface UpdateUserDto {
  fullName?: string;
  email?: string;
  bio?: string;
  avatar?: string;
}

export interface GetMeResponse {
  me: User;
}

export interface SignupWithoutInfoResponse {
  signup: User;
}

export interface UpdateUserResponse {
  updateUser: User;
}

export interface OpenVerifyEmailResponseDto {
  openVerifyEmail: {
    user?: User;
    error: boolean;
  };
}

const userType = `
  _id
  fullName
  email
  bio
  avatar
  isEmailVerified
  emailVerifiedAt
  role
  walletAddress
  balanceInWei
  createdAt
  updatedAt
`;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: graphqlRequestBaseQuery({
    url: GRAPHQL_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.token ||
        window.localStorage.getItem('wallet_signed_token');

      if (token) {
        headers.set('authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getMe: builder.query<GetMeResponse, void>({
      query: () => ({
        document: gql`
          query GetMe {
            me {
              ${userType}
            }
          }
        `,
      }),
    }),

    signUpWithoutInfo: builder.query<SignupWithoutInfoResponse, void>({
      query: () => ({
        document: gql`
          mutation SignUp {
            signup {
              ${userType}
            }
          }
        `,
      }),
    }),

    updateUser: builder.query<UpdateUserResponse, UpdateUserDto>({
      query: input => ({
        document: gql`
          mutation UpdateUser($input: UpdateUserDto = {}) {
            updateUser(input: $input) {
              ${userType}
            }
          }
        `,
        variables: { input },
      }),
    }),

    verifyEmail: builder.query<boolean, void>({
      query: () => ({
        document: gql`
          query VerifyEmail {
            verifyEmail
          }
        `,
      }),
    }),

    openVerifyEmail: builder.mutation<OpenVerifyEmailResponseDto, string>({
      query: token => ({
        document: gql`
          mutation OpenVerifyEmail($token: String!) {
            openVerifyEmail(token: $token) {
              user {
                ${userType}
              }
              error
            }
          }
        `,
        variables: {
          token,
        },
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useSignUpWithoutInfoQuery,
  useUpdateUserQuery,
  useVerifyEmailQuery,
  useOpenVerifyEmailMutation,
} = authApi;
