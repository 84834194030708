/* eslint-disable @typescript-eslint/no-explicit-any */
export enum GtagSupportLabel {
  ANA_CHATBOT = 'Ana Chatbot',
}

export enum GtagSupportCategory {
  ANA = 'Ana Interaction',
}

export enum GtagSupportAction {
  ANA_BTN_CLICK = 'ana_button_click',
  ANA_CHAT = 'chat_with_ana',
}

export function gtagSendEvent({ action, category }) {
  (window as any).gtag('event', action, {
    event_category: category,
  });
}

export function gtagSendEventWithLabel({ action, category, label }) {
  (window as any).gtag('event', action, {
    event_category: category,
    event_label: label,
  });
}

export function gtagSendEventWithValue({ action, category, label, value }) {
  (window as any).gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
}
