import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import patternImage from '../assets/pattern.png';

export const WrapperPattern: React.FunctionComponent<FlexProps> = props => {
  return (
    <Flex
      bgColor="darkBg"
      width="100%"
      css={{
        backgroundImage: `url("${patternImage}")`,
        backgroundRepeat: 'repeat',
      }}
      {...props}
    >
      {props.children}
    </Flex>
  );
};

export default WrapperPattern;
