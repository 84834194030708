/* eslint-disable react/react-in-jsx-scope */
import { MyCampaigns, MyLixi } from '@pages';
import { RouteObject } from 'react-router-dom';
import { RulesAndInstructions } from '../pages/RulesAndInstructions';

export const protectedRoutes: RouteObject[] = [
  { path: '/lixi', element: <MyCampaigns /> },
  { path: '/my-lixi', element: <MyLixi /> },
  { path: '/the-le', element: <RulesAndInstructions /> },
];
