import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@utils';
import { gql } from 'graphql-request';
import { GRAPHQL_BASE_URL } from '../../constants';
import { RootState } from '../store';
import { User } from './auth';

export enum TemplatePosition {
  TOP_LEFT,
  TOP_RIGHT,
  CENTER,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
}

export enum CampaignStatus {
  LISTED,
  UNLISTED,
}

export enum PaymentStatus {
  SUCCESS,
  FAILED,
}

export enum ShareType {
  AUTO_APPROVE = 'AUTO_APPROVE',
  MANUAL_APPROVE = 'MANUAL_APPROVE',
}

export enum TemplateStatus {
  LISTED,
  UNLISTED,
}

export interface Template {
  _id?: string;
  name: string;
  url: string;
  description?: string;
  status: TemplateStatus;
  createdAt: Date;
  updatedAt: Date;
}

export enum CampaignMemberStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CLAIMED = 'CLAIMED',
}

export interface CampaignMember {
  _id?: string;
  userId: string;
  campaignId: string;
  status: CampaignMemberStatus;
  isClaimed: boolean;
  createdAt: Date;
  updatedAt: Date;
  user: User;
}

export interface Token {
  _id: string;
  address: string;
  logo: string;
  symbol: string;
}

export interface Campaign {
  _id: string;
  name: string;
  description: string;
  logo?: string;
  templatePosition: TemplatePosition;
  templateId: string;
  status: CampaignStatus;
  numberItem: number;
  amountOnItem: number;
  currency: string;
  paymentStatus: PaymentStatus;
  paymentWallet?: string;
  shareId?: string;
  expiredTimeShare?: Date;
  shareType: ShareType;
  creatorId: string;
  endTime?: Date;
  mergeImageUrl?: string;
  createdAt: Date;
  updatedAt: Date;
  template?: Template;
  members?: CampaignMember[];
  creator?: User;
  remainItems: number;
  groupId?: number;
  startTokenId?: number;
  endTokenId?: number;
  extraToken?: Token;
  winnerAddress?: string;
  isRequireUserInfo?: boolean;
}

export type JoinedCampaign = Campaign;

export interface GetCampaignsResponse {
  getCampaigns: {
    campaigns: Campaign[];
    total: number;
    offset: number;
  };
}

export interface GetClaimedMembersResponse {
  getClaimedMembers: {
    members: CampaignMember[];
    total: number;
    offset: number;
  };
}
export interface GetCampaignResponse {
  getCampaign: Campaign;
}

export interface CreateCampaignResponse {
  createCampaign: Campaign;
}

export interface GetCampaignsDto {
  limit?: number;
  offset?: number;
}
export interface GetClaimedMembersDto {
  campaignId: string;
  limit?: number;
  offset?: number;
}
export interface ApproveMemberDto {
  userId: string;
  campaignId: string;
}

export interface SetApprovedMemberDto {
  groupId: number;
  userAddress: string;
  isWhitelisted: boolean;
}

export interface ApproveManyMembersDto {
  userIds: string[];
  campaignId: string;
}

export interface RequestJoinCampaignResponse {
  joinCampaign: CampaignMember;
}

export interface ApproveMemberResponse {
  approvedMember: CampaignMember;
}

export interface SetAppovedMemberResponse {
  setApprovedMember: CampaignMember;
}

export interface GetJoinedCampaignsResponse {
  getJoinedCampaigns: {
    campaigns: JoinedCampaign[];
    total: number;
    offset: number;
  };
}

export interface ApproveManyMembersResponse {
  approvedManyMembers: CampaignMember[];
}

export type UpdateStatusMemberDto = ApproveMemberDto;

export interface UpdateStatusMemberResponse {
  updateMemberClaimed: CampaignMember;
}

export interface CreateCampaignDto {
  name: string;
  description: string;
  logo: string;
  templatePosition: string;
  templateId: string;
  numberItem: number;
  amountOnItem: number;
  currency: string;
  hashTag: string;
  endTime: Date;
  groupId: number;
  startTokenId: number;
  endTokenId: number;
}

export interface UpdateShareLinkDto {
  campaignId: string;
  status: string;
}

export interface UpdateRequireUserInfoDto {
  campaignId: string;
  isRequire: boolean;
}

export interface UpdateShareLinkResponse {
  updateShareLink: Campaign;
}

export interface UpdateRequireUserInfoResponse {
  updateRequireUserInfo: Campaign;
}

export interface AddMemberToCampaignDto {
  member: {
    fulName?: string;
    email?: string;
    walletAddress: string;
  };
  campaignId: string;
}

export interface AddMemberToCampaignResponse {
  addMemberToCampaign: {
    addedMember: CampaignMember;
    campaignId: string;
  };
}

const campaignType = `
  _id
  name
  logo
  description
  templatePosition
  templateId
  status
  numberItem
  amountOnItem
  currency
  paymentStatus
  paymentWallet
  shareId
  expiredTimeShare
  shareType
  endTime
  mergeImageUrl
  remainItems
  startTokenId
  endTokenId
  groupId
  members {
    _id
    status
    createdAt
    userId
    user {
      _id
      fullName
      walletAddress
      email
      avatar
      isEmailVerified
      balanceInWei
    }
  }
  numberItem
  creatorId
  template {
    _id
    name
    url
  }
  creator {
    _id
    fullName
    avatar
  }
  extraToken {
    _id
    address
    symbol
    logo
  }
  winnerAddress
  isRequireUserInfo
`;

const memberType = `
  _id
  userId
  campaignId
  status
  tokenId
  createdAt
  updatedAt
  user {
    _id
    fullName
    email
    avatar
    bio
    isEmailVerified
    emailVerifiedAt
    role
    walletAddress
    balanceInWei
  }
`;

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: graphqlRequestBaseQuery({
    url: GRAPHQL_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.token ||
        window.localStorage.getItem('wallet_signed_token');
      if (token) {
        headers.set('authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getCampaigns: builder.query<GetCampaignsResponse, GetCampaignsDto>({
      query: input => ({
        document: gql`
          query GetCampaigns($input: GetCampaignsDto!) {
            getCampaigns(input: $input) {
              campaigns {
                ${campaignType}
              }
              total
              offset
            }
          }
        `,
        variables: {
          input: input,
        },
      }),
    }),

    getCampaign: builder.query<GetCampaignResponse, string>({
      query: campaignId => ({
        document: gql`
          query GetCampaign($campaignId: String!) {
            getCampaign(campaignId: $campaignId) {
              ${campaignType}
            }
          }
        `,
        variables: {
          campaignId,
        },
      }),
    }),

    getGlobalCampaign: builder.query<GetCampaignResponse, string>({
      query: campaignId => ({
        document: gql`
          query GetCampaign($campaignId: String!) {
            getCampaign(campaignId: $campaignId) {
              ${campaignType}
            }
          }
        `,
        variables: {
          campaignId,
        },
      }),
    }),

    getClaimedMembers: builder.query<
      GetClaimedMembersResponse,
      GetClaimedMembersDto
    >({
      query: input => ({
        document: gql`
          query GetClaimedMembers($input: GetClaimedMembersDto!) {
            getClaimedMembers(input: $input) {
              members {
                ${memberType}
              }
              total
              offset
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    requestJoinCampaign: builder.query<RequestJoinCampaignResponse, string>({
      query: campaignId => ({
        document: gql`
          mutation JoinCampaign($campaignId: String!) {
            joinCampaign(campaignId: $campaignId) {
              ${memberType}
            }
          }
        `,
        variables: {
          campaignId,
        },
      }),
    }),

    approveMember: builder.query<ApproveMemberResponse, ApproveMemberDto>({
      query: input => ({
        document: gql`
          mutation JoinCampaign($input: ApproveMemberDto!) {
            approvedMember(input: $input) {
              ${memberType}
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    setApprovedMember: builder.mutation<
      SetAppovedMemberResponse,
      SetApprovedMemberDto
    >({
      query: input => ({
        document: gql`
          mutation SetAppovedMember($input: SetApprovedMemberDto!) {
            setApprovedMember(input: $input) {
              ${memberType}
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    approveManyMembers: builder.mutation<
      ApproveManyMembersResponse,
      ApproveManyMembersDto
    >({
      query: input => ({
        document: gql`
          mutation ApproveManyMembers($input: ApproveManyMembersDto!) {
            approvedManyMembers(input: $input) {
              ${memberType}
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    createCampaign: builder.mutation<CreateCampaignResponse, CreateCampaignDto>(
      {
        query: input => ({
          document: gql`
            mutation CreateCampaign($input: CreateCampaignDto!) {
              createCampaign(input: $input) {
                ${campaignType}
              }
            }
        `,
          variables: {
            input,
          },
        }),
      },
    ),

    getJoinedCampaigns: builder.query<
      GetJoinedCampaignsResponse,
      GetCampaignsDto
    >({
      query: input => ({
        document: gql`
          query GetJoinedCampaigns($input: GetCampaignsDto!) {
            getJoinedCampaigns(input: $input) {
              campaigns {
                ${campaignType}
              }
              total
              offset
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    updateMemberClaimed: builder.mutation<
      UpdateStatusMemberResponse,
      UpdateStatusMemberDto
    >({
      query: input => ({
        document: gql`
          mutation UpdateMemberClaimed($input: UpdateStatusMemberDto!) {
            updateMemberClaimed (input: $input) {
              ${memberType}
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    updateShareLink: builder.mutation<
      UpdateShareLinkResponse,
      UpdateShareLinkDto
    >({
      query: input => ({
        document: gql`
          mutation updateShareLink($input: UpdateShareLinkDto!) {
            updateShareLink(input: $input) {
              ${campaignType}
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    updateRequireUserInfo: builder.mutation<
      UpdateRequireUserInfoResponse,
      UpdateRequireUserInfoDto
    >({
      query: input => ({
        document: gql`
          mutation updateRequireUserInfo($input: UpdateRequireUserInfoDto!) {
            updateRequireUserInfo(input: $input) {
              ${campaignType}
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),

    addMemberToCampaign: builder.mutation<
      AddMemberToCampaignResponse,
      AddMemberToCampaignDto
    >({
      query: input => ({
        document: gql`
          mutation AddMemberToCampaign($input: AddMemberToCampaignDto!) {
            addMemberToCampaign(input: $input) {
              addedMember {
                ${memberType}
              }
              campaignId
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),
  }),
});

export const {
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useRequestJoinCampaignQuery,
  useApproveMemberQuery,
  useGetClaimedMembersQuery,
  useCreateCampaignMutation,
  useUpdateMemberClaimedMutation,
  useUpdateShareLinkMutation,
  useUpdateRequireUserInfoMutation,
  useApproveManyMembersMutation,
  useGetJoinedCampaignsQuery,
  useSetApprovedMemberMutation,
  useGetGlobalCampaignQuery,
  useAddMemberToCampaignMutation,
} = campaignApi;
