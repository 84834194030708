import { NETWORKS } from '@web3react';

type paramsType = {
  chainId: number;
  setError: (error: Error) => void;
};

export const changeNetwork = async (
  { chainId, setError }: paramsType,
  account?: string | null,
) => {
  const chain = NETWORKS[chainId];

  if (!chain) return setError(new Error('Not support chain'));

  const toHex = (num: number) => {
    return '0x' + num.toString(16);
  };

  const params = {
    chainId: toHex(chain.chainId),
    chainName: chain.name,
    nativeCurrency: {
      name: chain.nativeCurrency.name,
      symbol: chain.nativeCurrency.symbol,
      decimals: chain.nativeCurrency.decimals,
    },
    rpcUrls: chain.rpc,
    blockExplorerUrls: [
      chain.explorers && chain.explorers.length > 0 && chain.explorers[0].url
        ? chain.explorers[0].url
        : chain.infoURL,
    ],
  };

  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [params, account],
    });
  } catch (error) {
    console.log(error);
    setError(error as Error);
  }
};
