import {
  Box,
  BoxProps,
  Heading,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

export const CardInfo: React.FC<
  BoxProps & { heading: string; content: string[] }
> = ({ heading, content, ...rest }) => {
  const size = useBreakpointValue({ base: 'lg', lg: 'md' });

  return (
    <Box w="full" bg="#FFF8C8" color="black" p="7" {...rest}>
      <VStack alignItems="flex-start" spacing="5">
        <Heading fontFamily="rootFont" size={size}>
          {heading}
        </Heading>
        {content.map((p, idx) => (
          <Text key={idx}>{p}</Text>
        ))}
      </VStack>
    </Box>
  );
};

export default CardInfo;
