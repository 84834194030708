import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '../app/store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from './useAuth';
export * from './useCopyableRef';
export * from './useFetchTokenIds';
export { default as useFetchTokenIds } from './useFetchTokenIds';
export * from './useFormStep';
export { default as useGroupInfo } from './useGroupInfo';
export * from './useMobileDetect';
export { default as useTokenIdOfAccount } from './useTokenIdOfAccount';
export * from './useWindowDimensions';
