import { Box, Flex, Image, Text, Tooltip, Wrap } from '@chakra-ui/react';
import { Campaign, CampaignMemberStatus } from '@services';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormatToken, WrapTag } from '..';

export interface LixiItemProps {
  currentUserId: string | undefined;
  lixi: Campaign;
}

export const LixiItem: React.FC<LixiItemProps> = ({ lixi, currentUserId }) => {
  const getNftImage = () => {
    if (lixi.mergeImageUrl) {
      return lixi.mergeImageUrl;
    }

    return lixi.template ? lixi.template.url : '';
  };

  const getUserStatus = () => {
    if (!lixi.members) {
      return '';
    }
    const members = lixi.members.filter(item => item.userId === currentUserId);

    if (members.length === 0) {
      return '';
    }

    return members[0].status;
  };

  const statusBadge = () => {
    const status = getUserStatus();

    if (!status) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let configStatus: any;

    if (status === CampaignMemberStatus.PENDING) {
      configStatus = {
        label: 'Chờ duyệt',
        tooltip: 'Đang chờ chủ bao lì xì duyệt.',
        bgColor: 'yellowBg',
        color: 'darkBg',
      };
    } else if (status === CampaignMemberStatus.DECLINED) {
      configStatus = {
        label: 'Từ chối',
        tooltip: 'Chủ bao lì xì đã từ chối yêu cầu của bạn',
        bgColor: 'primary',
        color: 'white',
      };
    } else if (status === CampaignMemberStatus.CLAIMED) {
      configStatus = {
        label: 'Đã nhận',
        tooltip: 'Bạn đã nhận được lì xì này',
        bgColor: 'greenLixi',
        color: 'darkBg',
      };
    } else {
      configStatus = {
        label: 'Đã duyệt',
        tooltip:
          'Bạn đã được duyệt lì xì. Bạn có thể vào trang chi tiếp để nhận lì xì',
        bgColor: 'blueLixi',
        color: 'white',
      };
    }

    return (
      <Tooltip label={configStatus.tooltip}>
        <Box
          cursor="pointer"
          bgColor={configStatus.bgColor}
          color={configStatus.color}
          py={1}
          px={2}
          borderRadius={5}
        >
          <Text>{configStatus.label}</Text>
        </Box>
      </Tooltip>
    );
  };

  return (
    <Box
      width="100%"
      maxW="50rem"
      mr="auto"
      ml="auto"
      pr="1rem"
      pl="1rem"
      mb="1.5rem"
    >
      <Flex
        bgColor="white"
        borderRadius={4}
        flexDirection={{ base: 'column', md: 'row' }}
        height={{ base: 'auto', md: '100%' }}
        overflow="hidden"
      >
        <Flex
          bgColor="yellowBg"
          w={{ base: '100%', md: '30%' }}
          h={{ base: '50%', md: '100%' }}
          p="2rem"
          justifyContent="center"
        >
          <Image w="100%" h="100%" objectFit="contain" src={getNftImage()} />
        </Flex>
        <Flex
          p={'1rem'}
          w={{ base: '100%', md: '70%' }}
          flexDirection="column"
          h={{ base: 'auto', md: '100%' }}
          justifyContent="space-between"
        >
          <Flex flexDirection="column">
            <Box pb={'1rem'}>
              <Wrap>
                <WrapTag>
                  <FormatToken
                    iconH={4}
                    iconW={4}
                    color="darkBg"
                    fontSize={14}
                    fontWeight={400}
                    token={lixi.extraToken}
                    amount={lixi.amountOnItem}
                  />
                </WrapTag>

                <WrapTag>
                  <Text
                    fontSize={14}
                    fontWeight={400}
                  >{`Còn ${lixi.remainItems} lì xì`}</Text>
                </WrapTag>
                <Wrap>{statusBadge()}</Wrap>
              </Wrap>
            </Box>
            <Box pb={'.5rem'}>
              <Link to={`/bao/${lixi._id}`}>
                <Text
                  fontSize={24}
                  fontWeight={700}
                  lineHeight="1.21"
                  letterSpacing="-0.07em"
                >
                  {lixi.name}
                </Text>
              </Link>
            </Box>
            <Box pb={'2rem'}>
              <Text color="#777E90" lineHeight="1.625" letterSpacing="-0.07em">
                {lixi.description}
              </Text>
            </Box>
          </Flex>
          <Flex>
            <Text
              fontSize={14}
              lineHeight="1.21"
              letterSpacing="-0.07em"
              color="#777E90"
              mr="4px"
            >
              Người lì xì:
            </Text>
            <Text
              fontSize={14}
              fontWeight={500}
              lineHeight="1.21"
              letterSpacing="-0.07em"
              color="darkBg"
            >
              {lixi.creator?.fullName}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LixiItem;
