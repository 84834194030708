import smallEnvelope from '@assets/logo.svg';
import { Box, Circle, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { NotchedButton } from '@components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Steps = () => {
  const navigate = useNavigate();
  return (
    <Box paddingTop="110px" background="yellowBg">
      <Box ml={'auto'} mr={'auto'} width={'100%'} maxWidth={'128rem'}>
        <Flex justifyContent="center" marginBottom="110px" alignItems="center">
          <Heading
            fontFamily="headingFont"
            marginRight="25px"
            fontSize="48px"
            lineHeight={1}
          >
            Tham gia Lì xì NFT
          </Heading>
          <Image src={smallEnvelope} width="40px" />
        </Flex>
        <Flex
          justifyContent={{ base: 'space-between', lg: 'space-around' }}
          flexDirection={{ base: 'column', md: 'row' }}
          flexWrap="wrap"
          maxWidth="80rem"
          mr="auto"
          ml="auto"
        >
          <Box pr="1.5rem" pl="1.5rem" mb="1.5rem">
            <Circle
              size="64px"
              color="primary"
              borderWidth="1px"
              borderColor="darkBg"
              fontWeight="medium"
              fontSize="28px"
            >
              1
            </Circle>
            <Text
              marginTop="24px"
              marginBottom="15px"
              fontSize="20px"
              fontWeight="600"
              color="darkBg"
            >
              Tạo bao lì xì
            </Text>
            <Text
              maxWidth={{ base: 'none', md: '15rem' }}
              fontSize="16px"
              fontWeight="regular"
              color="secondary"
            >
              Thiết lập bao lì xì tương ứng với thương hiệu của bạn, giá trị của
              bao, số lượng và giá trị mà bạn muốn lì xì.
            </Text>
          </Box>
          <Box pr="1.5rem" pl="1.5rem" mb="1.5rem">
            <Circle
              size="64px"
              color="primary"
              borderWidth="1px"
              borderColor="darkBg"
              fontWeight="medium"
              fontSize="28px"
            >
              2
            </Circle>
            <Text
              marginTop="24px"
              marginBottom="15px"
              fontSize="20px"
              fontWeight="600"
              color="darkBg"
            >
              Chia sẻ bao lì xì
            </Text>
            <Text
              maxWidth={{ base: 'none', md: '15rem' }}
              fontSize="16px"
              fontWeight="regular"
              color="secondary"
            >
              Chia sẻ bao lì xì của bạn cho người thân, bạn bè. Những người được
              chia sẻ có thể vào nhận lì xì đến ví Metamask.
            </Text>
          </Box>
          <Box pr="1.5rem" pl="1.5rem" mb="1.5rem">
            <Circle
              size="64px"
              color="primary"
              borderWidth="1px"
              borderColor="darkBg"
              fontWeight="medium"
              fontSize="28px"
            >
              3
            </Circle>
            <Text
              marginTop="24px"
              marginBottom="15px"
              fontSize="20px"
              fontWeight="600"
              color="darkBg"
            >
              Tham gia xổ số
            </Text>
            <Text
              maxWidth={{ base: 'none', md: '15rem' }}
              fontSize="16px"
              fontWeight="regular"
              color="secondary"
            >
              Chủ lì xì và người nhận lì xì đều có vé để tham gia quay thưởng xổ
              số nội bộ & xổ số tập thể.
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="center">
          <NotchedButton
            bg="transparent"
            marginTop="32px"
            marginBottom="156px"
            marginX="auto"
            color="darkBg"
            onClick={() => {
              navigate('/the-le');
            }}
          >
            Chi tiết các bước lì xì
          </NotchedButton>
        </Flex>
      </Box>
    </Box>
  );
};
