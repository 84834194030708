import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: '#F0514E',
    secondary: '#A1A2A3',
    dark: '#091B2D',
    border: '#DBDEE7',
    darkBg: '#102536',
    yellowBg: '#FAF6D8',
    blueLixi: '#218EEE',
    greenLixi: '#A4FFBD',
    lightBg: '#F6F3F0',
    white: '#FFFFFF',
    yellow: '#FFE400',
  },
  fonts: {
    rootFont: 'IBM Plex Mono',
    headingFont: 'LixiNFT Font, sans-serif',
    body: 'IBM Plex Mono',
    hashTagFont: 'Hashtag Font, sans-serif',
  },
});

export default theme;
