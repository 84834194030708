import { useGetCampaignsQuery } from '@app/services/campaign';
import { Box, Flex, Text, useTheme, VStack } from '@chakra-ui/react';
import {
  CampaignItem,
  Loading,
  NotchedButton,
  Pagination,
  Wrapper,
} from '@components';
import { getAllCampaigns } from '@features';
import { selectCurrentUser } from '@features/auth/authSlice';
import { useAppSelector } from '@hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePagination } from 'react-use-pagination';

const INITIAL_PAGE_SIZE = 6;
const PAGE_SIZE_VALUES_LENGTH = 3;

export const MyCampaigns: React.FC = () => {
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);

  const initialPageSize = INITIAL_PAGE_SIZE;
  const pageSizeValues = Array(PAGE_SIZE_VALUES_LENGTH)
    .fill(0)
    .map((_, idx) => initialPageSize * (idx + 1));

  const {
    currentPage,
    nextEnabled,
    previousEnabled,
    pageSize,
    setPage,
    setNextPage,
    setPreviousPage,
    setPageSize,
  } = usePagination({
    totalItems: totalNumberOfItems,
    initialPageSize,
  });

  const { refetch, isLoading, isError, data } = useGetCampaignsQuery(
    {
      limit: pageSize,
      offset: currentPage * pageSize,
    },
    { refetchOnMountOrArgChange: true },
  );

  const user = useAppSelector(selectCurrentUser);
  const theme = useTheme();
  const navigate = useNavigate();

  const campaigns = useAppSelector(state => getAllCampaigns(state));

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user]);

  useEffect(() => {
    setTotalNumberOfItems(data?.getCampaigns.total ?? 0);
  }, [data]);

  const UserNotLogin = () => {
    return (
      <Box
        bgColor="yellowBg"
        textAlign="center"
        py={10}
        border={`1px solid ${theme.colors.border}`}
        borderRadius={10}
        minH="100vh"
        paddingTop={{ base: '4.5rem', md: '8rem' }}
      >
        <Text fontWeight="bold">
          Bạn chưa kết nối ví. Hãy kết nối ví trước khi sử dụng.
        </Text>
      </Box>
    );
  };

  return (
    <Flex width="100%" paddingTop={{ base: '4.5rem', md: '8rem' }} minH="80vh">
      <Wrapper
        w="100%"
        py="2rem"
        pt="3rem"
        pb="4rem"
        mr="auto"
        ml="auto"
        flexDirection="column"
        maxW="80rem"
      >
        <Flex width="100%">
          <Box w="100%" flexDirection="column">
            <Flex justifyContent="center" w="100%" mb="1.5rem">
              <Text
                color="white"
                fontSize={84}
                fontFamily="headingFont"
                lineHeight={1}
                textAlign="center"
              >
                Lì xì của tôi
              </Text>
            </Flex>
            {!user && <UserNotLogin />}

            {isLoading ? (
              <Loading />
            ) : isError ? (
              <Flex flexDirection="column" alignItems="center">
                <Text color="white" fontSize={20} mb="1rem">
                  Có lỗi xảy ra khi tải bao lì xì
                </Text>
                <NotchedButton
                  bgColor="primary"
                  color="white"
                  onClick={() => window.location.reload()}
                >
                  Tải lại
                </NotchedButton>
              </Flex>
            ) : (campaigns && campaigns.length === 0) || !data ? (
              <Flex flexDirection="column" alignItems="center">
                <Text color="white" fontSize={20} mb="1rem">
                  Bạn chưa tạo lì xì nào
                </Text>
                <NotchedButton
                  bgColor="primary"
                  color="white"
                  onClick={() => {
                    navigate('/tao-lixi');
                  }}
                >
                  Tạo lì xì
                </NotchedButton>
              </Flex>
            ) : (
              <VStack>
                <Flex w="100%" mr="auto" ml="auto" wrap="wrap">
                  {campaigns.map(campaign => (
                    <CampaignItem key={campaign._id} campaign={campaign} />
                  ))}
                </Flex>
                <Pagination
                  canNextPage={nextEnabled}
                  canPreviousPage={previousEnabled}
                  gotoPage={setPage}
                  gotoNextPage={setNextPage}
                  gotoPreviousPage={setPreviousPage}
                  pageIndex={currentPage}
                  numberOfPages={Math.ceil(totalNumberOfItems / pageSize)}
                  pageSize={pageSize}
                  pageSizeValues={pageSizeValues}
                  onPageSizeChange={(sz: number) => setPageSize(sz)}
                />
              </VStack>
            )}
          </Box>
        </Flex>
      </Wrapper>
    </Flex>
  );
};

export default MyCampaigns;
