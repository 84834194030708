import { BigNumber } from '@ethersproject/bignumber';
import { getLixiBagContract } from '@web3react';

export async function getGroupLocalWinningTicket(groupId: number) {
  const contract = getLixiBagContract();
  const ticket = (await contract.getGroupLocalWinningTicket(
    groupId,
  )) as BigNumber;
  return ticket.toNumber();
}
