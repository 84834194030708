import { CopyIcon } from '@chakra-ui/icons';
import {
  Flex,
  FlexProps,
  Image,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { LIXI_NFT } from '@constants';
import copyToClipboard from 'copy-to-clipboard';
import React from 'react';
import metaMaskIcon from '../../assets/metamask.svg';

export interface ContractActionsProps {
  extraTokenAddress?: string;
  handleAddToken: () => void;
  tokenId?: undefined | number;
}

export const ContractActions: React.FC<ContractActionsProps> = ({
  extraTokenAddress,
  handleAddToken,
  tokenId,
}) => {
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'bottom',
  });

  const BoxWrap: React.FC<FlexProps> = ({ children, ...props }) => {
    return (
      <Flex
        bgColor="yellowBg"
        alignItems="center"
        gap={2}
        p={2}
        borderRadius={3}
        cursor="pointer"
        {...props}
      >
        {children}
      </Flex>
    );
  };

  const copyExtraToken = (address?: string) => {
    copyToClipboard(address as string);
    toast({
      title: 'Đã sao chép',
      position: 'top-right',
    });
  };

  const copyLixiNft = () => {
    copyToClipboard(LIXI_NFT);
    toast({
      title: 'Đã sao chép',
      position: 'top-right',
    });
  };

  const copyTokenId = () => {
    copyToClipboard(tokenId?.toString() ?? '');
    toast({
      title: 'Đã sao chép',
      position: 'top-right',
    });
  };

  return (
    <Flex flexDirection="column">
      <Text color="white">Contracts:</Text>
      <Flex gap={2}>
        <BoxWrap onClick={() => copyLixiNft()}>
          <Tooltip label="Sao chép LixiNFT Contract">
            <Flex alignItems="center" gap={1}>
              <Text fontWeight="bold">LixiNFT</Text>
              <CopyIcon />
            </Flex>
          </Tooltip>
        </BoxWrap>
        <BoxWrap onClick={() => copyExtraToken(extraTokenAddress)}>
          <Tooltip label="Sao chép token tặng contract">
            <Flex alignItems="center" gap={1}>
              <Text fontWeight="bold">Token Tặng</Text>
              <CopyIcon />
            </Flex>
          </Tooltip>
        </BoxWrap>
      </Flex>
      {tokenId && tokenId != -1 ? (
        <BoxWrap mt={2} onClick={copyTokenId}>
          <Tooltip label="Sao chép NFT token id">
            <Flex
              alignItems="center"
              gap={1}
              justifyContent="space-between"
              w="full"
            >
              <Text fontWeight="bold">NFT Token Id: {tokenId}</Text>
              <CopyIcon />
            </Flex>
          </Tooltip>
        </BoxWrap>
      ) : null}
      <BoxWrap
        bgColor="primary"
        mt={2}
        justifyContent="center"
        onClick={() => handleAddToken()}
      >
        <Tooltip label="Thêm vào MetaMask">
          <Flex alignItems="center" gap={1} justifyContent="center">
            <Text fontWeight="bold" color="white">
              Thêm token vào ví
            </Text>
            <Image src={metaMaskIcon} w={5} h={5} cursor="pointer" />
          </Flex>
        </Tooltip>
      </BoxWrap>
    </Flex>
  );
};

export default ContractActions;
