import { Campaign, CampaignMemberStatus, Token } from '@app/services';
import prizeBg from '@assets/prize-bg.png';
import TOKEN_LOGO from '@assets/token-coloured.svg';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { MembersTable } from '@components';
import { useGroupInfo } from '@hooks';
import { commaNumber, fromWei, hiddenFullName } from '@utils';
import {
  fetchingTokenInfo,
  formatAddress,
  getGlobalWinningTicket,
  getGroupLocalWinningTicket,
} from '@web3react';
import { round } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { NotchedButton } from '../NotchedButton';
import { Wheel } from '../Wheel/Wheel';

interface LotteryProps {
  campaign: Campaign | undefined | null;
  setWheelWinner?: (winner: string) => void;
  winnerAddress?: string;
  isGlobalCampaign?: boolean;
  claimJackpot?: (ticketId?: number) => void;
  displayWinningTicket?: boolean;
}

export const Lottery: React.FC<LotteryProps> = ({
  campaign,
  setWheelWinner,
  winnerAddress,
  isGlobalCampaign,
  claimJackpot,
  displayWinningTicket = true,
}) => {
  if (!campaign) {
    return null;
  }
  const currentCampaign = campaign;

  if (!currentCampaign || !currentCampaign.members) return null;

  const campaignAllMembers = currentCampaign.members.filter(
    member => member.status === CampaignMemberStatus.CLAIMED,
  );

  const [token, setToken] = useState<Omit<Token, '_id'> | undefined>(
    campaign.extraToken,
  );
  const [winningTicket, setWinningTicket] = useState<number | undefined>();

  const { groupInfo } = useGroupInfo(campaign.groupId);
  const totalLocalJackpotPrize = useMemo(() => {
    if (!groupInfo) {
      return 0;
    }

    const etherVal = parseFloat(
      fromWei(groupInfo.groupLocalJackpotPrize.toString()),
    );

    return round(etherVal, 7);
  }, [groupInfo]);

  useEffect(() => {
    (async () => {
      try {
        if (token) return;

        const metamaskTk = await fetchingTokenInfo(campaign.currency);
        setToken(metamaskTk);
      } catch (error) {
        console.log(error);
      }
    })();

    (async () => {
      if (typeof winningTicket === 'number') return;

      try {
        const ticket = isGlobalCampaign
          ? await getGlobalWinningTicket()
          : await getGroupLocalWinningTicket(
              typeof campaign.groupId === 'number' ? campaign.groupId : 0,
            );

        setWinningTicket(ticket);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // Limit 30 people
  const campaignMembers = campaignAllMembers.slice(0, 30);

  const claimedMemberNames = campaignMembers.map(item => {
    return formatAddress(item.user.walletAddress);
  });

  const segColors = claimedMemberNames.map((item, index) => {
    return index % 2 === 0 ? '#F0514E' : '#102536';
  });

  const onFinished = wallet => {
    const winner = campaignMembers.find(
      item => formatAddress(item.user.walletAddress) === wallet,
    );

    if (setWheelWinner && winner && winner.user.fullName)
      setWheelWinner(hiddenFullName(winner.user.fullName));
  };

  const getMemberColumns = () => [
    {
      Header: 'Người tham gia',
      accessor: 'member',
    },
    {
      Header: 'Địa chỉ ví',
      accessor: 'walletAddress',
    },
  ];

  const getMembersData = () =>
    campaignAllMembers.map(mb => ({
      member: <Text color="darkBg">{hiddenFullName(mb.user.fullName)}</Text>,
      walletAddress: (
        <Text fontSize={16} color="darkBg">
          {formatAddress(mb.user.walletAddress)}
        </Text>
      ),
    }));

  return (
    <Box marginY="100px">
      <Heading
        fontFamily="headingFont"
        fontSize="64px"
        color="white"
        marginBottom="10px"
        textAlign="center"
      >
        {isGlobalCampaign ? 'Quay Xổ Số Metaverse' : 'Quay Xổ Số Yêu Thương'}
      </Heading>
      <Box
        w={{
          base: '80%',
          md: '768px',
        }}
        borderRadius="4px"
        backgroundImage={prizeBg}
        mx="auto"
        marginBottom={{ base: '30px', md: '80px' }}
        position="relative"
        display={isGlobalCampaign ? 'none' : 'block'}
      >
        <Text
          color="white"
          fontWeight="500"
          fontSize="16px"
          marginBottom="10px"
          paddingTop="26px"
          textAlign="center"
        >
          Tổng Giải Thưởng Jackpot
        </Text>
        <Flex justifyContent="center" alignItems="center">
          <Box
            fontFamily="headingFont"
            fontSize="64px"
            color="white"
            marginTop="15px"
          >
            {commaNumber(totalLocalJackpotPrize)} {token && token.symbol}
          </Box>
          <Box>
            <Image
              marginLeft="10px"
              width="52px"
              src={token ? token.logo : TOKEN_LOGO}
            />
          </Box>
        </Flex>
      </Box>
      {displayWinningTicket &&
      typeof winningTicket === 'number' &&
      winningTicket ? (
        <Flex flexDirection="column" alignItems="center" marginBottom="5">
          <Heading
            fontFamily="headingFont"
            fontSize="32px"
            color="white"
            marginBottom="10px"
            textAlign="center"
          >
            Vé chiến thắng là vé số
            <Text colorScheme="red">{winningTicket}</Text>
          </Heading>
          <NotchedButton
            bgColor="primary"
            color="white"
            onClick={() => {
              if (typeof claimJackpot !== 'function') return;

              isGlobalCampaign ? claimJackpot() : claimJackpot(winningTicket);
            }}
            p="5"
          >
            Nhận giải thưởng
          </NotchedButton>
        </Flex>
      ) : undefined}
      <Flex
        width="80%"
        marginX="auto"
        justifyContent={{ base: 'center', md: 'space-between' }}
        flexDirection={{ base: 'column', xl: 'row' }}
        alignItems="center"
      >
        <Box display={{ base: 'none', md: 'block' }}>
          {claimedMemberNames && segColors && (
            <Wheel
              segments={claimedMemberNames}
              segColors={segColors}
              winningSegment={formatAddress(winnerAddress)}
              onFinished={winner => onFinished(winner)}
              primaryColor="#F0514E"
              contrastColor="white"
              buttonText="Spin"
              isOnlyOnce={true}
              size={290}
              upDuration={100}
              downDuration={1000}
              fontFamily="IBM Plex Mono"
            />
          )}
        </Box>
        <Text
          display={{ base: 'block', md: 'none' }}
          color="white"
          fontFamily="rootFont"
          marginBottom="30px"
        >
          Vui lòng sử dụng trình duyệt trên PC/Laptop để trải nghiệm vòng quay
          xổ số
        </Text>
        <Box maxW="sm">
          <Text
            color="white"
            fontFamily="rootFont"
            fontSize="36px"
            fontWeight="700"
            marginBottom="24px"
            textAlign={{ base: 'center', md: 'left' }}
          >
            Danh sách xổ số
          </Text>
          <Box background="white" paddingX="10px">
            <MembersTable
              columns={getMemberColumns()}
              data={getMembersData()}
              isMinimal={true}
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
